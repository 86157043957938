import React, { Component } from 'react';
import { Link } from "react-router-dom";

class VendorStripeCallback extends Component {

    state = {
        auth_code: window.location.search.split('?')[1].split('&')[0].split('code=')[1],
        loading: true,
        saved: false
    };

    componentWillMount() {
        this.submitCode();
    }

    submitCode() {
        let self = this;

        window.ss.post( '/users/confirm_stripe', { code: this.state.auth_code } ).then( json => {
            if ( json.error ) {
                alert( json.error );
            } else {
                window.ss.applyMe( json );
                self.setState({ loading: false, saved: true });
            }
        });
    }

    render() {
        return (
            <div className="page form">

                { this.state.loading && (
                    <h3 className="title">Saving...</h3>
                )}

                { this.state.saved && (
                    <div className="order-status">
                        Your Payout Account is Connected!
                    </div>
                )}

                <Link className="lightbutton" to="/home/settings">Return to Settings</Link>
            </div>

        );
    }
}

export default VendorStripeCallback;
