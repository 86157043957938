import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderPayment from '../orders/OrderPayment';

class OpenOrderPage extends Component {

    state = {
        order: {},
        loading: true,
        orderUpdated: false,
        paymentUpdated: false,
        publicKey: null,
        stripe: null,
        card: null,
        sending: false,
        showingPromo: false,
        showingGiftPopup: false,
        giftCode: '',
        promoCode: ''
    };

    componentWillMount() {
        if ( window.ss.me.id && window.ss.me.order.id ) {
            // Mark it in the object.
            window.ss.is_open_order = true;
        } else {
            // Fetch cart object.
            window.ss.is_open_order = false;
            window.ss.cartLoad();
        }

        this.loadOrderSummary( false );
        document.title = 'Current Order | SnackShare';
    }

    loadOrderSummary( isUpdated ) {
        let self = this;
        window.ss.get( '/users/me' ).then( json => {
            window.ss.applyMe( json );
            if ( !window.ss.is_open_order ) {
                return self.props.history.push('/cart');
            } else {
                let stateObj = {
                    order: window.ss.me.order,
                    loading: false
                };
                if ( isUpdated ) {
                    stateObj.orderUpdated = true;
                }
                if ( window.ss.me.order.promo_code ) {
                    stateObj.showingPromo = true;
                    stateObj.promoCode = window.ss.me.order.promo_code;
                } else {
                    stateObj.showingPromo = false;
                    stateObj.promoCode = '';
                }
                self.setState( stateObj );
                if ( isUpdated ) {
                    setTimeout( function() {
                        self.setState({ orderUpdated: false });
                    }, 5000 );
                }
            }
        });
    }

    disableSubmitButton() {
        let button = document.getElementById('pagesubmit');
        this.setState({ sending: true });
        button.className = 'disabled';
        button.innerHTML = 'Sending...';
    }

    enableSubmitButton() {
        let button = document.getElementById('pagesubmit');
        this.setState({ sending: false });
        button.className = '';
        button.innerHTML = 'Complete Purchase';
    }

    showPromoField(e) {
        e.preventDefault();
        this.setState({ showingPromo: true });
    }

    closePromoField(e) {
        e.preventDefault();
        this.setState({ showingPromo: false });
    }

    removePromo(e) {
        e.preventDefault();
        let self = this;
        window.ss.put( '/orders/' + self.state.order.id, {
            promo_code: ''
        }).then( json => {
            if ( !json ) {
                alert( 'Error removing promo code.' );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.id ) {
                self.loadOrderSummary( true );
            }
        });
    }

    showGiftPopup(e) {
        e.preventDefault();
        this.setState({ showingGiftPopup: true });
    }

    removeGiftPopup(e) {
        e.preventDefault();
        this.setState({ showingGiftPopup: false });
    }

    submitGiftCard(e) {
        e.preventDefault();
        e.stopPropagation();
        let self = this;
        window.ss.post( '/gift_cards/claim', {
            code: self.state.giftCode
        }).then( json => {
            if ( !json ) {
                alert( 'Error validating code.' );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.id ) {
                window.ss.applyMe( json );
                this.loadOrderSummary( true );
                this.setState({ showingGiftPopup: false });
            }
        });
    }

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handlePromoSubmit(e) {
        e.preventDefault();
        let self = this;
        window.ss.post( '/promos/verify', {
            promo_code: self.state.promoCode
        }).then( json => {
            if ( !json ) {
                alert( 'Error validating promo code.' );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.success ) {
                window.ss.put( '/orders/' + self.state.order.id, {
                    promo_code: self.state.promoCode
                }).then( json => {
                    if ( !json ) {
                        alert( 'Error validating promo code.' );
                    } else if ( json.error ) {
                        alert( json.error );
                    } else if ( json.id ) {
                        self.loadOrderSummary( true );
                    }
                });
            }
        });
    }

    handlePaymentSelect( paymentCard ) {
        let self = this;
        if ( !paymentCard || self.state.order.payment_card.id === paymentCard ) return;
        window.ss.put( '/orders/' + self.state.order.id, {
            payment_card: paymentCard
        }).then( json2 => {
            if ( json2 && json2.err && json2.err.message ) {
                alert( json2.err.message );
            } else if ( json2 && json2.error ) {
                alert( json2.error );
            } else {
                self.loadOrderSummary();
                self.setState({ paymentUpdated: true });
                setTimeout( function() {
                    self.setState({ paymentUpdated: false });
                }, 5000 );
            }
        });
    }

    cancelOrder(e) {
        e.preventDefault();
        let self = this;
        if ( window.confirm('Are you sure you wish to cancel this?') ) {
            window.ss.put( '/orders/' + self.state.order.id, {
                cancelled: '1'
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                } else if ( json.error ) {
                    alert( json.error );
                } else {
                    window.ss.get( '/users/me' ).then( json2 => {
                        window.ss.applyMe( json2 );
                        return self.props.history.push( '/order/' + json.id );
                    });
                }
            });
        }
    }

    render() {

        let self = this;
        let order = self.state.order;

        function handleQuantityChange(e) {
            let itemId = e.target.getAttribute( 'data-item_id' );
            let quantity = e.target.value;
            window.ss.put( '/items/' + itemId, {
                quantity: quantity
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                } else if ( json.error ) {
                    alert( json.error );
                }
                self.loadOrderSummary( true );
            });
        }

        function getQuantityOptions( item ) {
            let str = '';
            let amount = ( item.product.is_unlimited ? 100 : item.product.inventory + item.quantity );
            for (var i=0;i<=amount;i++) {
                str += '<option value="' + i + '"' + ( item.quantity === i ? ' selected' : '' ) + '>' + i +'</option>';
            }
            return (
                <select data-item_id={ item.id } onChange={ handleQuantityChange } dangerouslySetInnerHTML={{ __html: str }}></select>
            );
        }

        function showCartError() {
            if ( order.is_subscription || order.subscription || order.subtotal >= 25 ) return '';
            return (
                <div className="cart-error">
                    You must have at least $25 worth of goods (before delivery fee) for this order to be processed.
                </div>
            );
        }

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return(
                <div className="page cart">

                    { self.state.orderUpdated && (
                        <div className="order-updated">
                            Your order has been updated.<br />
                            The total is now <b>${ window.ss.formatCost( order.total ) }</b><br />
                            We'll email you when you're done making changes.
                        </div>
                    )}

                    { self.state.paymentUpdated && (
                        <div className="order-updated">
                            Your payment method has been updated!
                        </div>
                    )}

                    { showCartError() }

                    <h3 className="title">Current Order</h3>

                    <div className="cart-section-items">
                        { order.items.map( ( item, j ) =>
                            <div key={j} className="cart-item">
                                <div className="cart-item-info">
                                    <div className="cart-item-name"><Link to={ item.product.path }>{ item.product.name }</Link></div>
                                    <div className="cart-item-unit">{ item.product.unit_name }</div>
                                    <div className="cart-item-vendor">{ item.product.vendor.name }</div>
                                </div>
                                { !!item.product.cost && (
                                    <div className="cart-item-costs">
                                        { item.product.is_weight_based && (
                                            <div className="cart-item-unitcost">x <b>${ window.ss.formatCost( item.product.pound_cost * item.product.pound_avg ) }</b></div>
                                        )}
                                        { !item.product.is_weight_based && (
                                            <div className="cart-item-unitcost">x <b>${ window.ss.formatCost( item.product.cost ) }</b></div>
                                        )}
                                        { item.product.is_weight_based && (
                                            <div className="cart-item-subtotal">= <b>${ window.ss.formatCost( item.quantity * item.product.pound_cost * item.product.pound_avg ) }</b></div>
                                        )}
                                        { !item.product.is_weight_based && (
                                            <div className="cart-item-subtotal">= <b>${ window.ss.formatCost( item.quantity * item.product.cost ) }</b></div>
                                        )}
                                    </div>
                                )}
                                { !!item.product.cost && (
                                    <div className="cart-item-quantity">
                                        { getQuantityOptions( item ) }
                                    </div>
                                )}
                                { item.product.is_weight_based && (
                                    <div className="cart-item-weightnote">(price based on weight)</div>
                                )}
                            </div>
                        )}
                        { order.subscription && order.subscription.product && (
                            <div className="cart-item">
                                <div className="cart-item-info">
                                    <div className="cart-item-name">{ order.subscription.product.name }</div>
                                    <div className="cart-item-unit">{ order.subscription.product.unit_name }</div>
                                    <div className="cart-item-vendor">{ order.subscription.product.vendor.name }</div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Link className="bluebutton" to="/market">Add More Items</Link>

                    <h3 className="title">Address</h3>

                    <div className="cart-address">
                        <Link className="cart-address-edit" to="/cart/address"><img src="/img/edit.svg" alt="Remove" /></Link>
                        <span className="address-item-info">
                        <span className="address-item-line">{ order.address.address1 }</span>
                        { order.address.address2 && (
                            <span className="address-item-line">{ order.address.address2 }</span>
                        )}
                        <span className="address-item-line">
                            { order.address.city }, { order.address.state } { order.address.zipcode }
                        </span>
                        <span className="address-item-line address-item-type">{ order.address.location_type }</span>
                        { order.address.notes && (
                            <span className="address-item-line address-item-notes">{ order.address.notes }</span>
                        )}
                        </span>
                    </div>

                    <h3 className="title">Delivery Date</h3>

                    <div className="cart-summary cart-delivery_date">
                        { order.available_delivery_dates.length > 1 && (
                            <Link className="cart-address-edit" to="/cart/delivery_date"><img src="/img/edit.svg" alt="Remove" /></Link>
                        )}
                        { window.ss.fullDate( order.delivery_date ) } 3-8pm
                    </div>

                    <h3 className="title">Summary</h3>

                    <div className="cart-summary">
                        <table>
                            <tbody>
                                { !!order.subtotal ? (
                                    <tr className="cart-subtotal">
                                        <td className="cart-summary-label">Subtotal</td>
                                        <td className="cart-summary-amount">${ window.ss.formatCost( order.subtotal ) }</td>
                                    </tr>
                                ) : null }
                                <tr className="cart-delivery">
                                    <td className="cart-summary-label">Delivery Fee</td>
                                    <td className="cart-summary-amount">${ window.ss.formatCost( order.delivery_fee ) }</td>
                                </tr>
                                { self.state.showingPromo && !order.promo_code ? (
                                    <tr className="cart-promo">
                                        <td colSpan="2" className="cart-summary-label">
                                            <form onSubmit={ self.handlePromoSubmit.bind(self) }>
                                                <input className="text" type="text" placeholder="Enter code" name="promoCode" value={ self.state.promoCode } onChange={ self.handleChange.bind(self) } />
                                                <input className="bluebutton" type="submit" value="Apply" />
                                                <a href="/" onClick={ self.closePromoField.bind(self) }>Cancel</a>
                                            </form>
                                        </td>
                                    </tr>
                                ) : null }
                                { self.state.showingPromo && order.promo_code ? (
                                    <tr className="cart-promo">
                                        <td className="cart-summary-label">Promo: <b>{ order.promo_code.toUpperCase() }</b> <a href="/" className="remove" onClick={ self.removePromo.bind(self) }>X</a></td>
                                        <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( order.promo_discount ) }</td>
                                    </tr>
                                ) : null }
                                { !self.state.showingPromo ? (
                                    <tr className="cart-promo">
                                        <td colSpan="2" className="cart-summary-label">
                                            <a href="/" onClick={ self.showPromoField.bind(self) }>Promo Code</a>
                                        </td>
                                    </tr>
                                ) : null }
                                <tr className="cart-promo">
                                    <td colSpan="2" className="cart-summary-label">
                                        <a href="/" onClick={ self.showGiftPopup.bind(self) }>Gift Card</a>
                                    </td>
                                </tr>
                                { ( window.ss.me.gift_balance || order.gift_applied ) ? (
                                    <tr className="cart-promo">
                                        <td className="cart-summary-label">Gift Card <b>(${ window.ss.formatCost( window.ss.me.gift_balance ) } available)</b>:</td>
                                        <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( order.gift_applied ) }</td>
                                    </tr>
                                ) : null}
                                <tr className="cart-total">
                                    <td className="cart-summary-label">Total</td>
                                    <td className="cart-summary-amount">${ window.ss.formatCost( order.total ) }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h3 className="title">Payment</h3>

                    { !!( order.total === 0 && order.gift_applied ) && (
                        <div className="giftcardccexplain">
                            <p>Even though your gift card covers the order, we still need to record your credit card info.</p>
                            <p>You will be able to add more items to your order until <b>{ window.ss.fullDate( order.close_date ) }</b> at midnight, but your card will not be charged if you have enough balance to cover the full order.</p>
                        </div>
                    )}

                    { !order.is_paid && (
                        <OrderPayment onSelect={ self.handlePaymentSelect.bind( self ) } payment_card={ order.payment_card ? order.payment_card.id : null } />
                    )}

                    <div className="cart-message">
                        You can change your order through
                        <b>{ window.ss.fullDate( order.close_date ) }</b>
                        So come back to add more later!
                    </div>

                    { !order.is_subscription && ( !order.subscription || !order.subscription.id ) && order.close_int > new Date().getTime() && order.status !== 'delivered' && order.status !== 'cancelled' && (
                        <div className="cancellink"><a href="/home" onClick={ self.cancelOrder.bind(self) }>Cancel Order</a></div>
                    )}

                    { self.state.showingGiftPopup && (
                        <div className="blanket">
                            <div className="popup">
                                <div className="form">
                                    <div className="field">
                                        <label>Enter gift code here:</label>
                                        <input type="text" className="text" placeholder="eg: A1B2C3E4" onChange={ e => this.setState({ giftCode: e.target.value }) } />
                                        <a href="/" className="bluebutton giftsubmitbutton" onClick={ this.submitGiftCard.bind(this) }>Submit</a>
                                        <a href="/" className="giftcancelbutton" onClick={ this.removeGiftPopup.bind(this) }>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            );
        }
    }
}

export default OpenOrderPage;
