import React, { Component } from 'react';

class CategoryAdd extends Component {

    render() {
        return (
            <div className="welcome-hero">
                <h1 className="account-head">CategoryAdd</h1>
            </div>
        );
    }
}

export default CategoryAdd;
