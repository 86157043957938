import React, { Component } from 'react';

class OrderPrintListings extends Component {

    state = {
        loading: true,
        orders: []
    };

    componentWillMount() {
        this.loadOrders();
    }

    loadOrders() {
        let self = this;
        let date = self.props.match.params.date;
        let url = '/orders?per_page=1000&status=pending&user=all&date=' + date;
        window.ss.get( url ).then( response => {
            response.results.sort((a, b) => (a.user.name > b.user.name) ? 1 : -1);
            response.results.forEach( order => {
                window.ss.warehouseSections.forEach( ws => {
                    order[ ws ] = [];
                });
                order.unsorted = [];
                order.items.forEach( item => {
                    if ( item.product.warehouse_section && order[ item.product.warehouse_section ] ) {
                        order[ item.product.warehouse_section ].push( item );
                    } else {
                        order.unsorted.push( item );
                    }
                });
                if ( order.subscription && order.subscription.product ) {
                    if ( order.user.email === 'lynnhuntgeorgia@gmail.com' ) {
                        order.subscription.quantity = 2;
                    } else {
                        order.subscription.quantity = 1;
                    }
                    if ( order.subscription.product.warehouse_section && order[ order.subscription.product.warehouse_section ] ) {
                        order[ order.subscription.product.warehouse_section ].push( order.subscription );
                    } else {
                        order.unsorted.push( order.subscription );
                    }
                }
            });
            self.setState({ loading: false, orders: response.results });
        });
    }

    render() {
        let self = this;


        function itemInfo( item ) {
            return (
                <div className="print-row-info">
                    <div className="print-row-quantity">{ item.quantity }</div>
                    <div className="print-row-description">
                        <div className="print-row-name">{ item.product && item.product.name }</div>
                        <div className="print-row-unit">{ item.product && item.product.unit_name }</div>
                    </div>
                    <div className="print-row-vendor">{ item.product && item.product.vendor && item.product.vendor.name }</div>
                </div>
            );
        }

        if ( self.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="print-orders">
                    { self.state.orders.map( ( order, i ) =>
                        <div key={ i } className="print-order">

                            <div className="print-order-top">

                                <div className="print-order-barcode"><img alt="QR Code" src={ window.ss.orderQR( order.id ) } /></div>

                                <div className="print-order-delivery">
                                    <span>Delivery</span>
                                    <b>{ window.ss.smallDate( order.delivery_int ) }</b>
                                </div>

                                <div className="print-order-info">

                                    <span className="print-order-user-name">{ order.user && order.user.name }</span>
                                    <span className="address-item-info">
                                        <span className="address-item-line">{ order.address.address1 }</span>
                                        { order.address.address2 && (
                                            <span className="address-item-line">{ order.address.address2 }</span>
                                        )}
                                        <span className="address-item-line">
                                            { order.address.city }, { order.address.state } { order.address.zipcode }
                                        </span>
                                        <span className="address-item-line address-item-type">{ order.address.location_type }</span>
                                        { order.address.notes && (
                                            <span className="address-item-line address-item-notes">{ order.address.notes }</span>
                                        )}
                                        <span>
                                            <span className="address-item-line">{ order.user.email }</span>
                                            <span className="address-item-line">{ window.ss.formatPhone( order.user.phone ) }</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="print-order-list">
                                <div className="print-section">
                                    <div className="print-row">
                                        <i></i>
                                        <div className="print-row-qc">QC</div>
                                        <div className="print-row-info">
                                            <div className="print-row-quantity">&nbsp;</div>
                                            <div className="print-row-description">
                                                <div className="print-row-name">Label(s)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="print-row">
                                        <i></i>
                                        <div className="print-row-qc">QC</div>
                                        <div className="print-row-info">
                                            <div className="print-row-quantity">&nbsp;</div>
                                            <div className="print-row-description">
                                                <div className="print-row-name">Ice</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                { window.ss.warehouseSections.map( ( ws, j ) =>
                                    <div key={ j } className="print-section">
                                        <h4>{ ws }</h4>

                                        { order[ ws ].map( ( item, k ) =>

                                            <div key={ k } className="print-row">
                                                <i></i>
                                                <div className="print-row-qc">QC</div>
                                                { item.product.is_weight_based && (
                                                    <div className="print-row-weight">WEIGHT</div>
                                                )}
                                                { itemInfo( item ) }
                                            </div>
                                        )}
                                    </div>
                                )}

                                { order.unsorted.length > 0 && (
                                    <div className="print-section">
                                        <h4>Unsorted</h4>

                                        { order.unsorted.map( ( item, k ) =>

                                            <div key={ k } className="print-row">
                                                <i></i>
                                                <div className="print-row-qc">QC</div>
                                                { item.product.is_weight_based && (
                                                    <div className="print-row-weight">WEIGHT</div>
                                                )}
                                                { itemInfo( item ) }
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                        </div>
                    )}
                </div>
            );
        }
    }
}

export default OrderPrintListings;
