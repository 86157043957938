import React, { Component } from 'react';
import Products from '../market/Products';
import VendorBubble from './VendorBubble';

class VendorPage extends Component {

    state = {
        vendor: {},
        products: { results: [] },
        loading: true
    };

    async componentWillMount() {
        // The Vendor page URLs will have this format:
        // http://snackshare.com/vendors/:vendor_ident
        // Example:
        // http://snackshare.com/vendors/pepper-farmer
        // vendor_ident == pepper-farmer
        const { vendor_ident } = this.props.match.params;
        let vendor = await window.ss.get( '/vendors/' + vendor_ident );
        let products = await window.ss.get( '/products?per_page=500&vendor=' + vendor_ident );
        document.title = vendor.name + ' | SnackShare';
        this.setState({
            vendor,
            products,
            loading: false
        });
    }

    render() {
        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="page vendor">
                    <VendorBubble vendor={ this.state.vendor } link="hide" />
                    <h3 className="title">Products</h3>
                    <Products products={ this.state.products } />
                </div>
            );
        }
    }
}

export default VendorPage;
