import React from "react";
import { Link } from "react-router-dom";

class Products extends React.Component {

  render() {
    return (
      <div className="market-products">
        { this.props.products.results.map( ( product, i ) =>
          <Link key={i} className="market-product" to={ product.path }>
            <img src={ product.assets[0].wide_url } alt={ product.name } />
            { product.is_local && (
                <span class="ribbon"><span>LOCAL</span></span>
            )}
            <span className="fade">
              <strong>
                <span className="market-product-price">
                    { !!product.cost && !product.is_weight_based && (
                      <span>${ window.ss.formatCost( product.cost ) }</span>
                    )}
                    { !!product.pound_cost && product.is_weight_based && (
                      <span>${ window.ss.formatCost( product.pound_cost ) } per pound</span>
                    )}
                  <span className="market-product-unit">{ product.unit_name }</span>
                </span>
                { product.name }
                <span className="market-product-vendor">{ product.vendor.name }</span>
              </strong>
            </span>
          </Link>
        )}
      </div>
    );
  }
}

export default Products;
