import React, { Component } from 'react';
import VendorImages from './VendorImages';

class VendorEdit extends Component {

    state = {
        name:               '',
        ident:              '',
        description:        '',
        location:           '',
        vendor:             {},
        loading:            true
    };

    async componentWillMount() {
        const { ident } = this.props.match.params;
        let vendor = await window.ss.get( '/vendors/' + ident );
        this.setState({
            loading: false,
            name:               vendor.name,
            ident:              vendor.ident,
            description:        vendor.description,
            location:           vendor.location,
            vendor:             vendor
        });
    }

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleSubmit(e) {
        e.preventDefault();
        let self = this;
        window.ss.put( '/vendors/' + self.state.ident, {
            name: self.state.name,
            description: self.state.description,
            location: self.state.location
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.ident ) {
                self.props.history.push('/home/vendors');
            }
        });
    }

    render() {
        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="page form">

                    <h3 className="title">{ this.state.name }</h3>

                    <form onSubmit={ this.handleSubmit.bind(this) }>

                        <VendorImages vendor={ this.state.vendor } />
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <input className="text" type="text" name="name" id="name" required value={ this.state.name } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field field-large">
                            <label htmlFor="description">Description</label>
                            <textarea name="description" id="description" onChange={ this.handleChange.bind(this) } value={ this.state.description } />
                        </div>
                        <div className="field">
                            <label htmlFor="location">Location</label>
                            <input className="text" type="text" name="location" id="location" required value={ this.state.location } onChange={ this.handleChange.bind(this) } />
                        </div>

                        <div className="field">
                            <input id="pagesubmit" type="submit" value="Update" />
                        </div>
                    </form>

                </div>
            );
        }
    }
}

export default VendorEdit;
