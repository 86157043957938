import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AccountRegister extends Component {

    state = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: ''
    };

    render() {
        let self = this;

        function handleSubmit(e) {
            e.preventDefault();

            window.ss.post( '/users/register', {
                first_name: self.state.first_name,
                last_name: self.state.last_name,
                email: self.state.email,
                phone: self.state.phone,
                password: self.state.password
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                } else if ( json.id ) {
                    window.ss.applyMe( json );
                    if ( window.ss.returnTo ) {
                        self.props.history.push( window.ss.returnTo );
                        window.ss.clearReturn();
                    } else {
                        self.props.history.push( '/home' );
                    }
                }
            });
        }

        return (
            <div className="page form">

                <h3 className="title">Register</h3>

                <form onSubmit={ handleSubmit }>

                    <div className="field">
                        <label htmlFor="first_name">First Name</label>
                        <input className="text" type="text" name="first_name" id="first_name" placeholder="Enter Your First Name" required onChange={ e => this.setState({ first_name: e.target.value }) } />
                    </div>
                    <div className="field">
                        <label htmlFor="last_name">Last Name</label>
                        <input className="text" type="text" name="last_name" id="last_name" placeholder="Enter Your Last Name" required onChange={ e => this.setState({ last_name: e.target.value }) } />
                    </div>
                    <div className="field">
                        <label htmlFor="phone">Phone</label>
                        <input className="text" type="phone" name="phone" id="phone" placeholder="123-456-7890" required onChange={ e => this.setState({ phone: e.target.value }) } />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input className="text" type="email" name="email" id="email" placeholder="example@domain.com" required onChange={ e => this.setState({ email: e.target.value }) } />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <input className="text" type="password" name="password" id="password" required onChange={ e => this.setState({ password: e.target.value }) } />
                    </div>
                    <div className="field">
                        <input className="bluebutton" type="submit" value="Register" />
                        <div className="disclaimer">
                            By submitting this form, you agree to our <Link to="/info/terms">Terms of Use</Link>.
                        </div>
                    </div>
                </form>

                <Link className="lightbutton" to="/account/login">I already have an account.</Link>

            </div>
        );
    }
}

export default AccountRegister;
