import React, { Component } from 'react';
import ProductImages from './ProductImages';

class ProductEdit extends Component {

    state = {
        product: {
            class_name:         'Product',
            id:                 null,
            name:               '',
            ident:              '',
            url:                '',
            path:               '',
            description:        '',
            unit_name:          '',
            cost:               null,
            pound_cost:         null,
            pound_avg:          null,
            position:           null,
            inventory:          null,
            warehouse_section:  '',
            shelf:              '',
            is_published:       false,
            is_unlimited:       false,
            is_weight_based:    false,
            is_instant_buy:     false,
            is_vendor_payable:  false,
            is_local:           false,
            vendor:             {},
            product_types:      [],
            assets:             []
        },
        productTypeIdent: 'produce',
        tree: {},
        loading: true
    };

    async componentWillMount() {
        const { ident } = this.props.match.params;
        let product = await window.ss.get( '/products/' + ident + '?include=full' );
        let tree = await window.ss.get( '/product_types/tree' );
        this.setState({
            loading: false,
            tree: tree,
            product: product,
            productTypeIdent: ( product.product_type && product.product_type.ident ? product.product_type.ident : 'produce' )
        });
    }

    handleChange(e) {
        let product = JSON.parse( JSON.stringify( this.state.product ) );
        product[ e.target.name ] = e.target.value;
        this.setState({ product: product });
    }

    handleProductTypeChange(e) {
        this.setState({ productTypeIdent: e.target.value });
    }

    handleCheckboxChange(e) {
        let product = JSON.parse( JSON.stringify( this.state.product ) );
        product[ e.target.name ] = ( e.target.value === '1' ? false : true );
        this.setState({ product: product });
    }

    handleSubmit(e) {
        e.preventDefault();
        let self = this;
        window.ss.put( '/products/' + this.state.product.ident, {
            name: self.state.product.name,
            ident: self.state.product.ident,
            description: self.state.product.description,
            unit_name: self.state.product.unit_name,
            cost: self.state.product.cost,
            wholesale_cost: self.state.product.wholesale_cost,
            pound_cost: self.state.product.pound_cost,
            wholesale_pound_cost: self.state.product.wholesale_pound_cost,
            pound_avg: self.state.product.pound_avg,
            position: self.state.product.position,
            inventory: self.state.product.inventory,
            warehouse_section: self.state.product.warehouse_section,
            shelf: self.state.product.shelf,
            is_published: self.state.product.is_published,
            is_unlimited: self.state.product.is_unlimited,
            is_vendor_payable: self.state.product.is_vendor_payable,
            is_weight_based: self.state.product.is_weight_based,
            is_local: self.state.product.is_local,
            product_type: self.state.productTypeIdent
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.id ) {
                self.props.history.push('/home/products');
            }
        });
    }

    render() {
        let self = this;

        function getWarehouseSections() {
            let str = '<option value=""' + ( !self.state.product.warehouse_section ? ' selected' : '' ) + '>Select Warehouse Section</option>';
            window.ss.warehouseSections.forEach( ws => {
                str += '<option value="' + ws + '"' + ( self.state.product.warehouse_section === ws ? ' selected' : '' ) + '>' + window.ss.toTitleCase( ws ) +'</option>';
            });
            return (
                <select id="warehouse_section" name="warehouse_section" onChange={ self.handleChange.bind(self) } dangerouslySetInnerHTML={{ __html: str }}></select>
            );
        }

        function getProductTypes() {
            if ( !self.state.tree || !self.state.tree.product_types || self.state.tree.product_types.length === 0 ) {
                return '';
            }
            let str = '';
            self.state.tree.product_types.forEach( pt => {
                str += '<option value="' + pt.ident + '"' + ( self.state.productTypeIdent === pt.ident ? ' selected' : '' ) + '>' + pt.name +'</option>';
                if ( pt.children ) {
                    pt.children.forEach( child => {
                        str += '<option value="' + child.ident + '"' + ( self.state.productTypeIdent === child.ident ? ' selected' : '' ) + '>&nbsp;&nbsp;&nbsp;&nbsp;' + child.name +'</option>';
                    });
                }
            });
            return (
                <select id="product_type" name="product_type" onChange={ self.handleProductTypeChange.bind(self) } dangerouslySetInnerHTML={{ __html: str }}></select>
            );
        }

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="page form">

                    <form onSubmit={ this.handleSubmit.bind(this) }>

                        <ProductImages product={ this.state.product } />
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <input className="text" type="text" name="name" id="name" required value={ this.state.product.name } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field field-large">
                            <label htmlFor="description">Description</label>
                            <textarea name="description" id="description" onChange={ this.handleChange.bind(this) } value={ this.state.product.description } />
                        </div>
                        <div className="field">
                            <label htmlFor="ident">Ident</label>
                            <input className="text" type="text" name="ident" id="ident" required value={ this.state.product.ident } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field">
                            <label htmlFor="unit_name">Unit Name</label>
                            <input className="text" type="text" name="unit_name" id="unit_name" required value={ this.state.product.unit_name } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field">
                          <label htmlFor="product_type">Category</label>
                          { getProductTypes() }
                        </div>
                        <div className="field">
                            <label htmlFor="position">Position</label>
                            <input className="text" type="text" name="position" id="position" required value={ this.state.product.position } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field">
                            <label htmlFor="warehouse_section">Warehouse Section</label>
                            { getWarehouseSections() }
                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_weight_based"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.product.is_weight_based ? 'checked' : '' }
                                    value={ this.state.product.is_weight_based ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Weight Based?
                            </label>

                            { !this.state.product.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="cost">Cost</label>
                                    <input className="text" name="cost" id="cost" value={ this.state.product.cost } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { !this.state.product.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="wholesale_cost">Wholesale Cost</label>
                                    <input className="text" name="wholesale_cost" id="wholesale_cost" value={ this.state.product.wholesale_cost } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { this.state.product.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="pound_cost">Cost per Pound</label>
                                    <input className="text" name="pound_cost" id="pound_cost" value={ this.state.product.pound_cost ? this.state.product.pound_cost : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { this.state.product.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="wholesale_pound_cost">Wholesale Cost per Pound</label>
                                    <input className="text" name="wholesale_pound_cost" id="wholesale_pound_cost" value={ this.state.product.wholesale_pound_cost ? this.state.product.wholesale_pound_cost : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { this.state.product.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="pound_avg">Average Pounds</label>
                                    <input className="text" name="pound_avg" id="pound_avg" value={ this.state.product.pound_avg ? this.state.product.pound_avg : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}

                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_unlimited"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.product.is_unlimited ? 'checked' : '' }
                                    value={ this.state.product.is_unlimited ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Unlimited?
                            </label>
                            { !this.state.product.is_unlimited && (
                                <div className="subfield">
                                    <label htmlFor="inventory">Inventory</label>
                                    <input className="text" type="number" name="inventory" id="inventory" value={ this.state.product.inventory ? this.state.product.inventory : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_published"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.product.is_published ? 'checked' : '' }
                                    value={ this.state.product.is_published ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Published?
                            </label>
                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_vendor_payable"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.product.is_vendor_payable ? 'checked' : '' }
                                    value={ this.state.product.is_vendor_payable ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Payable to Vendor?
                                <i className="field-reminder">(Make sure to update Wholesale Cost)</i>
                            </label>
                        </div>

                        <div className="field">
                            <input id="pagesubmit" type="submit" value="Update" />
                        </div>
                    </form>

                </div>
            );
        }
    }
}

export default ProductEdit;
