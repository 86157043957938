import React, { Component } from 'react';

class AccountResetPassword extends Component {

    state = {
        password: '',
        sending: false
    };

    render() {
        let self = this;

        const { token } = this.props.match.params;

        function handleSubmit(e) {
            e.preventDefault();
            if ( self.state.sending ) return;
            self.setState({ sending: true });

            window.ss.post( '/reset_password', {
                uuid: token,
                password: self.state.password
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                    self.setState({ sending: false });
                } else if ( json.id ) {
                    window.ss.applyMe( json );
                    if ( window.ss.returnTo ) {
                        self.props.history.push( window.ss.returnTo );
                        window.ss.clearReturn();
                    } else {
                        self.props.history.push( '/home' );
                    }
                }
            });
        }

        return (
            <div className="page form">

                <h3 className="title">Set New Password</h3>

                <form onSubmit={ handleSubmit }>

                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <input className="text" type="password" name="password" id="password" required onChange={ e => this.setState({ password: e.target.value }) } />
                    </div>
                    <div className="field">
                        <input className="bluebutton" type="submit" value="Save Password" />
                    </div>
                </form>

            </div>
        );
    }
}

export default AccountResetPassword;
