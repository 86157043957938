import React, { Component } from 'react';

class VendorSettings extends Component {

    state = {
        name: window.ss.me.vendor.name,
        description: window.ss.me.vendor.description,
        location: window.ss.me.vendor.location,
        email: window.ss.me.email,
        phone: window.ss.me.phone,
        password: '',
        saved: false,
        connectUrl: ''
    };

    componentWillMount() {
        this.loadClientId();
    }

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleSubmit(e) {
        e.preventDefault();
        let self = this;

        window.ss.put( '/users/' + window.ss.me.id, {
            name: self.state.name,
            description: self.state.description,
            email: self.state.email,
            phone: self.state.phone,
            password: self.state.password
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.id ) {
                window.ss.applyMe( json );
                self.setState({ saved: true });
            }
        });
    }

    loadClientId() {
        let self = this;
        window.ss.get( '/payment_cards/client_id' ).then( json => {
            if ( json && json.clientId ) {
                let redirect_uri = window.location.protocol + '//' + window.location.hostname + ( window.location.port ? ':' + window.location.port : '' );
                    redirect_uri += '/home/stripe_callback';
                let url = 'https://connect.stripe.com/express/oauth/authorize?redirect_uri=';
                    url += encodeURIComponent( redirect_uri );
                    url += '&client_id=';
                    url += json.clientId;
                    url += '&state=';
                    url += new Date().getTime();
                self.setState({ connectUrl: url });
            }
        });
    }

    render() {
        return (
            <div className="page form">

                { this.state.saved && (
                    <div className="order-status">
                        Profile Saved!
                    </div>
                )}

                <form onSubmit={ this.handleSubmit.bind(this) }>

                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <input className="text" type="text" name="name" id="name" placeholder="Vendor Name" required value={ this.state.name } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="description">Description</label>
                        <textarea name="description" id="description" placeholder="Vendor Description" required value={ this.state.description } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="name">Location</label>
                        <input className="text" type="text" name="location" id="location" placeholder="Vendor Location" required value={ this.state.location } onChange={ this.handleChange.bind(this) } />
                    </div>

                    <div className="field">
                        <label htmlFor="phone">Phone</label>
                        <input className="text" type="phone" name="phone" id="phone" placeholder="123-456-7890" required value={ this.state.phone } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input className="text" type="email" name="email" id="email" placeholder="example@domain.com" required value={ this.state.email } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password (Leave blank for no change)</label>
                        <input className="text" type="password" name="password" id="password" onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <input className="bluebutton" type="submit" value="Update" />
                    </div>
                </form>

                { !window.ss.me.stripe_user_id && (
                    <a className="lightbutton" href={ this.state.connectUrl }>Manage Payout Settings</a>
                )}

            </div>

        );
    }
}

export default VendorSettings;
