import React from 'react';
import AdminItemTable from './AdminItemTable';

class AdminItemGrid extends React.Component {

    state = {
        response: {},
        loading: true
    };

    async componentWillMount() {
        let response = await window.ss.get( '/orders?per_page=500&status=pending&user=all' );
        let products = {
            currentObjs: {},
            currentArray: [],
            futureObjs: {},
            futureArray: []
        };
        let nextSunday = window.ss.getNextDay('0');
        let nextMonday = window.ss.getNextDay('1');
        let sundayStr = window.ss.inputDate( nextSunday.getTime() );
        let mondayStr = window.ss.inputDate( nextMonday.getTime() );
        if ( nextSunday > nextMonday ) {
            nextMonday.setDate( nextMonday.getDate() - 1 );
            sundayStr = window.ss.inputDate( nextMonday.getTime() );
        }
        response.results.forEach( order => {
            let isCurrent = [ sundayStr, mondayStr ].indexOf( order.delivery_date ) > -1;
            let objs = products[ isCurrent ? 'currentObjs' : 'futureObjs' ];
            let odor = JSON.parse( JSON.stringify( order ) );
            delete odor.items;
            delete odor.subscription;
            order.items.forEach( item => {
                item.order = odor;
                if ( !objs[ item.product.ident ] ) {
                    objs[ item.product.ident ] = item.product;
                    objs[ item.product.ident ].quantity = item.quantity;
                    objs[ item.product.ident ].items = [ item ];
                } else {
                    objs[ item.product.ident ].quantity += item.quantity;
                    objs[ item.product.ident ].items.push( item );
                }
            });
            let subscription = order.subscription;
            if ( subscription && subscription.product ) {
                subscription.order = odor;
                subscription.quantity = 1;
                // Hack for Lynn Hunt
                if ( subscription.id === '5e7a50fcc9a6e13e06c7a968' ) {
                    subscription.quantity = 2;
                }
                if ( !objs[ subscription.product.ident ] ) {
                    objs[ subscription.product.ident ] = subscription.product;
                    objs[ subscription.product.ident ].quantity = subscription.quantity;
                    objs[ subscription.product.ident ].items = [ subscription ];
                } else {
                    objs[ subscription.product.ident ].quantity += subscription.quantity;
                    objs[ subscription.product.ident ].items.push( subscription );
                }
            }
        });
        let currentKeys = Object.keys( products.currentObjs ).sort();
        let futureKeys = Object.keys( products.futureObjs ).sort();
        currentKeys.forEach( ident => {
            products.currentArray.push( products.currentObjs[ ident ] );
        });
        futureKeys.forEach( ident => {
            products.futureArray.push( products.futureObjs[ ident ] );
        });
        this.setState({
            loading: false,
            response: response,
            products: products
        });
        document.title = 'Pending Items | SnackShare';
    }

    render() {
        return (
            <div className="admin-list">
                <h3 className="title">Pending Items</h3>

                { this.state.loading && (
                    <div className="loading">Loading...</div>
                )}

                { !this.state.loading && (
                    <AdminItemTable title="Current Week" productArray={ this.state.products.currentArray } />
                )}

                { !this.state.loading && (
                    <AdminItemTable title="Future Orders" productArray={ this.state.products.futureArray } />
                )}
            </div>
        );
    }
}

export default AdminItemGrid;
