import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Market from './components/market/Market';
import Analytics from './components/Analytics/Analytics';

import Home from './components/home/Home';
import OrderDetails from './components/orders/OrderDetails';
import OrderListings from './components/orders/OrderListings';
import OrderPrintListings from './components/orders/OrderPrintListings';
import ItemListings from './components/items/ItemListings';
import AccountRegister from './components/account/AccountRegister';
import AccountLogin from './components/account/AccountLogin';
import AccountLogout from './components/account/AccountLogout';
import AccountReset from './components/account/AccountReset';
import AccountResetPassword from './components/account/AccountResetPassword';
import ProductPage from './components/product/ProductPage';
import VendorPage from './components/vendor/VendorPage';
import CartPage from './components/cart/CartPage';
import AddressPage from './components/cart/AddressPage';
import DeliveryDatePage from './components/cart/DeliveryDatePage';
import ConfirmPage from './components/cart/ConfirmPage';
import SuccessPage from './components/cart/SuccessPage';
import OpenOrderPage from './components/cart/OpenOrderPage';
import Terms from './components/info/Terms';
import CsaAgreement from './components/info/CsaAgreement';
import CsaAgreementNewLeaf from './components/info/CsaAgreementNewLeaf';

import ProductAdd from './components/home/admin/ProductAdd';
import ProductEdit from './components/home/admin/ProductEdit';
import ProductUpload from './components/home/admin/ProductUpload';

import CategoryShow from './components/home/admin/CategoryShow';
import CategoryAdd from './components/home/admin/CategoryAdd';
import CategoryEdit from './components/home/admin/CategoryEdit';

import VendorAdd from './components/home/admin/VendorAdd';
import VendorEdit from './components/home/admin/VendorEdit';
import VendorUpload from './components/home/admin/VendorUpload';

import GreenGate from './components/welcome/GreenGate';
import GreenGateWinter from './components/welcome/GreenGateWinter';
import GreenGateSpring from './components/welcome/GreenGateSpring';
import GreenGateSummer from './components/welcome/GreenGateSummer';
import NewLeafSpring from './components/welcome/NewLeafSpring';
import NewLeafSummer from './components/welcome/NewLeafSummer';
import NewLeafSummerExtension from './components/welcome/NewLeafSummerExtension';

//import ProductInfo from './components/productDetail/ProductInfo';
//import ProductListMain from './components/productList/ProductListMain'
//import ProductDetailMain from './components/productDetail/ProductDetailMain'
import Welcome from './components/welcome/WelcomeMain'
import Blog from './components/welcome/Blog'
import Signup from './components/welcome/Signup'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import './App.scss';

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Router>
        <nav>
          <IconButton edge="start" style={{marginLeft: '0'}} color="inherit" aria-label="Menu"
              aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
              <MenuIcon style={{color: 'white'}}/>
          </IconButton>
          {/* <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            Open with fade transition
          </Button> */}
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleClose}>
              <Link className="linkStyling" to="/">Home</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link className="linkStyling" to="/market">Market</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link className="linkStyling" to="/blog">Blog</Link>
            </MenuItem>

            { window.ss.me.id && (
              <MenuItem onClick={handleClose}>
                <Link className="linkStyling" to="/home">Dashboard</Link>
              </MenuItem>
            )}

            { ( window.ss.me.is_admin ) && (
              <MenuItem onClick={handleClose}>
                <Link className="linkStyling" to="/home/products">Products</Link>
              </MenuItem>
            )}

            { window.ss.me.is_admin && (
              <MenuItem onClick={handleClose}>
                <Link className="linkStyling" to="/home/vendors">Manage Vendors</Link>
              </MenuItem>
            )}

            { window.ss.me.id && (
              <MenuItem onClick={handleClose}>
                <Link className="linkStyling" to="/home/settings">Settings</Link>
              </MenuItem>
            )}

            <MenuItem onClick={handleClose}>
              <Link className="linkStyling" to="/info/terms">Terms</Link>
            </MenuItem>

            { window.ss.me.id && (
              <MenuItem onClick={handleClose}>
                <Link className="linkStyling" to="/account/logout">Log out</Link>
              </MenuItem>
            )}

            { !window.ss.me.id && (
              <MenuItem onClick={handleClose}>
                <Link className="linkStyling" to="/account/register">Register</Link>
              </MenuItem>
            )}
            { !window.ss.me.id && (
              <MenuItem onClick={handleClose}>
                <Link className="linkStyling" to="/account/login">Log in</Link>
              </MenuItem>
            )}

          </Menu>

          <Link className="nav-home" to="/">SnackShare</Link>

          <div id="carticon"></div>
        </nav>
        <div id="flash" className="flash"></div>

        <Route path="/" exact component={Welcome} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/market/:msa?/:product_type_ident?" component={Market} />
        <Route path="/vendors/:vendor_ident/products/:product_ident" exact component={ProductPage} />
        <Route path="/vendors/:vendor_ident" exact component={VendorPage} />

        <Route path="/greengate" exact component={GreenGate} />
        <Route path="/greengatewinter" exact component={GreenGateWinter} />
        <Route path="/greengatespring" exact component={GreenGateSpring} />
        <Route path="/greengatesummer" exact component={GreenGateSummer} />
        <Route path="/newleafspring" exact component={NewLeafSpring} />
        <Route path="/newleafsummer" exact component={NewLeafSummer} />
        <Route path="/newleafsummerextension" exact component={NewLeafSummerExtension} />
        <Route path="/info/terms" exact component={Terms} />
        <Route path="/info/csa_agreement" exact component={CsaAgreement} />
        <Route path="/info/csa_agreement_newleaf" exact component={CsaAgreementNewLeaf} />

        <Route path="/account/register" exact component={AccountRegister} />
        <Route path="/account/login" exact component={AccountLogin} />
        <Route path="/account/logout" exact component={AccountLogout} />
        <Route path="/account/reset" exact component={AccountReset} />
        <Route path="/account/reset_password/:token" exact component={AccountResetPassword} />

        <Route path="/items/:item_type" exact component={ItemListings} />
        <Route path="/orders/print/:date" exact component={OrderPrintListings} />
        <Route path="/orders/:order_type" exact component={OrderListings} />
        <Route path="/order/:order_id" exact component={OrderDetails} />
        <Route path="/home/products/new" exact component={ProductAdd} />
        <Route path="/home/products/:ident/edit" exact component={ProductEdit} />
        <Route path="/home/products/:ident/upload" exact component={ProductUpload} />
        <Route path="/home/categories/new" exact component={CategoryAdd} />
        <Route path="/home/categories/:ident" exact component={CategoryShow} />
        <Route path="/home/categories/:ident/edit" exact component={CategoryEdit} />
        <Route path="/home/vendors/new" exact component={VendorAdd} />
        <Route path="/home/vendors/:ident/edit" exact component={VendorEdit} />
        <Route path="/home/vendors/:ident/upload" exact component={VendorUpload} />
        <Route path="/home/:home_tab?" exact component={Home} />

        <Route path="/cart" exact component={CartPage} />
        <Route path="/cart/address" exact component={AddressPage} />
        <Route path="/cart/delivery_date" exact component={DeliveryDatePage} />
        <Route path="/cart/confirm" exact component={ConfirmPage} />
        <Route path="/cart/success" exact component={SuccessPage} />
        <Route path="/cart/order" exact component={OpenOrderPage} />
        <Analytics />

      </Router>
    </div>

  );
}
