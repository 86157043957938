import React, { Component } from 'react';

class NewLeafSpring extends Component {

    componentWillMount() {
        this.props.history.push( '/vendors/newleaf/products/newleaf-spring-csa-delivery' );
    }

    render() {
        return (
            <div className="page newleaf"></div>
        );
    }
}

export default NewLeafSpring;
