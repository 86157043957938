import React, { Component } from 'react';
import Carousel from './Carousel';

class Market extends Component {

    state = {
        productTypes: { results: [] },
        loading: true
    };

    async componentWillMount() {
        let productTypes = await window.ss.get( '/product_types?per_page=500' );
        this.setState({
            productTypes: productTypes,
            loading: false
        });
        document.title = 'Market | SnackShare';
    }

    render() {
        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else if ( this.state.productTypes.results.length > 0 ) {
            return (
                <div className="page market">
                    { this.state.productTypes.results.map( ( productType, i ) =>
                        <Carousel key={ i } productType={ productType } />
                    )}
                </div>
            );
        }
    }
}

export default Market;
