import React, { Component } from 'react';
import ItemList from '../../items/ItemList';
import OrderList from '../../orders/OrderList';

class VendorDashboard extends Component {
    _isMounted = false;

    state = {
        ordersCurrent: {},
        itemsPendingConfirmation: {},
        itemsDueToWarehouse: {},
        itemsDelivered: {},
        itemsCancelled: {},
        subscriptions: {},
        loading: true,
        noOrders: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadOrders();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadOrders() {
        await this.setState({
            ordersCurrent: {},
            itemsPendingConfirmation: {},
            itemsDueToWarehouse: {},
            itemsDelivered: {},
            itemsCancelled: {},
            subscriptions: {}
        });
        let now = new Date();
        let today = new Date( now.getFullYear(), now.getMonth(), now.getDate() );
        await this.fetch( 'ordersCurrent', '/orders?per_page=3&delivery_after=' + today.getTime() );
        await this.fetch( 'itemsPendingConfirmation', '/items?status=unconfirmed&per_page=3&include_order=1&delivery_after=' + today.getTime() );
        await this.fetch( 'itemsDueToWarehouse', '/items?status=confirmed&per_page=100&include_order=1&delivery_after=' + today.getTime() );
        await this.fetch( 'itemsDelivered', '/items?status=staged|delivered&per_page=3&include_order=1&delivery_after=' + today.getTime() );
        await this.fetch( 'itemsCancelled', '/items?status=cancelled&per_page=3&include_order=1&delivery_after=' + today.getTime() );
        await this.fetch( 'subscriptions', '/subscriptions?per_page=500' );
        this.setState({ loading: false });
        if (
            this.state.ordersCurrent.total === 0 &&
            this.state.itemsPendingConfirmation.total === 0 &&
            this.state.itemsDueToWarehouse.total === 0 &&
            this.state.itemsDelivered.total === 0 &&
            this.state.itemsCancelled.total === 0 &&
            this.state.subscriptions.total === 0
        ) {
            this.setState({ noOrders: true });
        }
        this.render();
    }

    fetch( key, url ) {
        let self = this;
        return window.ss.get( url ).then( response => {
            let obj = {};
            obj[ key ] = response;
            if ( self._isMounted ) {
                return self.setState( obj );
            } else {
                return Promise.resolve();
            }
        });
    }

    render() {

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="page orders">

                    { this.state.noOrders && (
                        <h3 className="title">No new orders.</h3>
                    )}

                    { this.state.subscriptions && this.state.subscriptions.total > 0 && (
                        <div className="home-subs">
                            <h3 className="title">Subscription{ this.state.subscriptions.total === 1 ? '' : 's' }</h3>

                            <div className="item-list">
                                { this.state.subscriptions.results.map( ( sub, i ) =>
                                    <div className="item-row">
                                        <div className="item-info">
                                            <div className="item-name">{ sub.product && sub.product.name }</div>
                                            <div className="item-unit">{ sub.product && sub.product.unit_name }</div>
                                            <div className="order-user-name">{ sub.user.name }</div>
                                            <span className="address-item-info">
                                                <span className="address-item-line">{ sub.address.address1 }</span>
                                                { sub.address.address2 && (
                                                    <span className="address-item-line">{ sub.address.address2 }</span>
                                                )}
                                                <span className="address-item-line">
                                                    { sub.address.city }, { sub.address.state } { sub.address.zipcode }
                                                </span>
                                                <span className="address-item-line address-item-type">{ sub.address.location_type }</span>
                                                { sub.address.notes && (
                                                    <span className="address-item-line address-item-notes">{ sub.address.notes }</span>
                                                )}
                                                <span>
                                                    <span className="address-item-line"><a href={ 'mailto:' + sub.user.email }>{ sub.user.email }</a></span>
                                                    <span className="address-item-line"><a href={ 'tel:' + window.ss.formatTel( sub.user.phone ) }>{ window.ss.formatPhone( sub.user.phone ) }</a></span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    { this.state.ordersCurrent.total > 0 && (
                        <div className="currentorder">
                            <OrderList list_type="current" groupDate="true" limit="3" orders={ this.state.ordersCurrent } />
                        </div>
                    )}

                    { this.state.itemsPendingConfirmation.total > 0 && (
                        <ItemList list_type="unconfirmed" limit="3" items={ this.state.itemsPendingConfirmation } onChange={ this.loadOrders.bind( this ) } />
                    )}

                    { this.state.itemsDueToWarehouse.total > 0 && (
                        <ItemList list_type="confirmed" groupDate="true" limit="100" items={ this.state.itemsDueToWarehouse } onChange={ this.loadOrders.bind( this ) } />
                    )}

                    { this.state.itemsDelivered.total > 0 && (
                        <ItemList list_type="delivered" limit="3" items={ this.state.itemsDelivered } onChange={ this.loadOrders.bind( this ) } />
                    )}

                    { this.state.itemsCancelled.total > 0 && (
                        <ItemList list_type="cancelled" limit="3" items={ this.state.itemsCancelled } onChange={ this.loadOrders.bind( this ) } />
                    )}

                </div>
            );
        }
    }
}

export default VendorDashboard;
