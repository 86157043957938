import { Component } from 'react';

class AccountLogout extends Component {

    componentWillMount() {
        let self = this;
        window.ss.post( '/users/logout', {}).then( json => {
            if ( json && json.error ) {
                alert( json.error );
            } else {
                window.ss.me = {};
                window.ss.is_open_order = false;
                self.props.history.push( '/' );
            }
        });
    }

    render() {
        return '';
    }
}

export default AccountLogout;
