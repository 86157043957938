import React, { Component } from 'react';
import { Link } from "react-router-dom";

class SuccessPage extends Component {

    componentWillMount() {
        document.title = 'Order Complete | SnackShare';
    }

  render() {
    return (
      <div className="page cart">
        <div className="cart-empty">
          <p>Thank You!</p>
          <p className="cart-success-message">Your order has been completed. We&rsquo;ll send you more information about your delivery soon.</p>
          <Link className="bluebutton" to="/home">View Your Profile</Link>
        </div>
      </div>
    );
  }
}

export default SuccessPage;
