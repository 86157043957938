import React, { Component } from 'react';
import ItemList from '../../items/ItemList';
import OrderList from '../../orders/OrderList';

class VendorOrders extends Component {

    state = {
        itemsPendingConfirmation: {},
        itemsDueToWarehouse: {},
        itemsDelivered: {},
        itemsCancelled: {},
        ordersPrior: {},
        loading: true,
        noOrders: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadOrders();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadOrders() {
        await this.setState({
            itemsPendingConfirmation: {},
            itemsDueToWarehouse: {},
            itemsDelivered: {},
            itemsCancelled: {},
            ordersPrior: {}
        });
        let now = new Date();
        let today = new Date( now.getFullYear(), now.getMonth(), now.getDate() );
        await this.fetch( 'ordersPrior', '/orders?per_page=3&sort=recent' );
        await this.fetch( 'itemsPendingConfirmation', '/items?status=unconfirmed&per_page=3&include_order=1&delivery_before=' + today.getTime() );
        await this.fetch( 'itemsDueToWarehouse', '/items?status=confirmed&per_page=100&include_order=1&delivery_before=' + today.getTime() );
        await this.fetch( 'itemsDelivered', '/items?status=staged|delivered&per_page=3&include_order=1&delivery_before=' + today.getTime() );
        await this.fetch( 'itemsCancelled', '/items?status=cancelled&per_page=3&include_order=1&delivery_before=' + today.getTime() );
        this.setState({ loading: false });
        if (
            this.state.itemsPendingConfirmation.total === 0 &&
            this.state.itemsDueToWarehouse.total === 0 &&
            this.state.itemsDelivered.total === 0 &&
            this.state.itemsCancelled.total === 0 &&
            this.state.ordersPrior.total === 0
        ) {
            this.setState({ noOrders: true });
        }
        this.render();
    }

    fetch( key, url ) {
        let self = this;
        return window.ss.get( url ).then( response => {
            let obj = {};
            obj[ key ] = response;
            if ( self._isMounted ) {
                return self.setState( obj );
            } else {
                return Promise.resolve();
            }
        });
    }

    render() {

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="page orders">

                    { this.state.noOrders && (
                        <h3 className="title">No order history.</h3>
                    )}

                    { this.state.ordersPrior.total > 0 && (
                        <OrderList list_type="prior" groupDate="true" limit="3" orders={ this.state.ordersPrior } />
                    )}

                    { this.state.itemsPendingConfirmation.total > 0 && (
                        <ItemList list_type="unconfirmed" limit="3" items={ this.state.itemsPendingConfirmation } />
                    )}

                    { this.state.itemsDueToWarehouse.total > 0 && (
                        <ItemList list_type="confirmed" groupDate="true" limit="100" items={ this.state.itemsDueToWarehouse } />
                    )}

                    { this.state.itemsDelivered.total > 0 && (
                        <ItemList list_type="delivered" limit="3" items={ this.state.itemsDelivered } />
                    )}

                    { this.state.itemsCancelled.total > 0 && (
                        <ItemList list_type="cancelled" limit="3" items={ this.state.itemsCancelled } />
                    )}

                </div>
            );
        }
    }
}

export default VendorOrders;
