import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AdminVendors extends Component {

    state = {
        vendors: {},
        loading: true
    };

    async componentWillMount() {
        let vendors = await window.ss.get( '/vendors?per_page=500' );
        this.setState({
            vendors: vendors,
            loading: false
        });
        document.title = 'Vendors | SnackShare';
    }

    render() {
        return (
            <div className="admin-list">
                <Link className="admin-add bluebutton" to="/home/vendors/new">+ Add</Link>
                <h3 className="title">Vendors</h3>

                { this.state.loading && (
                    <div className="loading">Loading...</div>
                )}

                { !this.state.loading && (
                    <div className="admin-producttype">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.vendors.results.map( ( vendor, i ) =>
                                    <tr key={i} className={ ( i % 2 === 1 ? 'odd' : 'even' ) }>
                                        <td>
                                            <Link key={i} className="admin-thumb" to={ '/home/vendors/' + vendor.ident + '/edit' }>
                                                <img src={ vendor.assets[0].square_url } alt={ vendor.name } />
                                            </Link>
                                        </td>
                                        <td>
                                            <Link key={i} to={ '/home/vendors/' + vendor.ident + '/edit' }>
                                                <b className="admin-product-name">{ vendor.name }</b>
                                                <i className="admin-product-unit">{ vendor.ident }</i>
                                            </Link>
                                        </td>
                                        <td>{ vendor.location }</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}

export default AdminVendors;
