import React, { Component } from 'react';

class DeliveryDatePage extends Component {

    state = {
        order: {},
        delivery_date: null,
        loading: true
    };

  componentWillMount() {
    if ( !window.ss.me.id ) {
        window.ss.saveReturn( '/cart/delivery_date' );
        return this.props.history.push( '/account/register' );
    } else {
        this.loadOrderSummary();
    }
    document.title = 'Delivery Date | SnackShare';
  }

  loadOrderSummary() {
    let self = this;
    if ( window.ss.is_open_order ) {
        self.setState({ order: window.ss.me.order });
        self.setState({ delivery_date: window.ss.me.order.delivery_date });
        self.setState({ loading: false });
    } else {
        let cart = JSON.parse( JSON.stringify( window.ss.cart ) );
        window.ss.post( '/orders/summary', cart ).then( order => {
          if ( order.items.length === 0 ) {
            return self.props.history.push('/cart');
          } else {
            if ( window.ss.cart.delivery_date && order.available_delivery_dates.indexOf( window.ss.cart.delivery_date ) > -1 ) {
              self.setState({ delivery_date: window.ss.cart.delivery_date });
            } else {
              self.setState({ delivery_date: order.available_delivery_dates[0] });
            }
            self.setState({ order });
            self.setState({ loading: false });
          }
        });
    }
  }

  render() {

    let self = this;

    function handleInputChange(e) {
      self.setState({ delivery_date: e.target.value });
    }

    function getDateOptions() {
      let str = '';
      self.state.order.available_delivery_dates.forEach( dd => {
        str += '<option value="' + dd + '"' + ( self.state.delivery_date === dd ? ' selected' : '' ) + '>' + window.ss.fullDate( dd ) +' 3-8pm</option>';
      });
      return (
        <select id="delivery_date-select" name="delivery_date" onChange={ handleInputChange } dangerouslySetInnerHTML={{ __html: str }}></select>
      );
    }

    function handleFormSubmit(e) {
      e.preventDefault();
      if ( window.ss.is_open_order ) {
          window.ss.put( '/orders/' + window.ss.me.order.id, {
              delivery_date: self.state.delivery_date
          }).then( json2 => {
              if ( json2 && json2.err && json2.err.message ) {
                  alert( json2.err.message );
              } else if ( json2.error ) {
                  alert( json2.error );
              } else {
                  self.props.history.push( '/cart/order' );
              }
          });
      } else {
          window.ss.cartSet( 'delivery_date', self.state.delivery_date );
          self.props.history.push( '/cart/confirm' );
      }
    }

    if ( this.state.loading ) {
      return (
        <div className="loading">Loading...</div>
      );
    } else {
      return (
        <div className="page delivery_date">

          <h3 className="title">Select Delivery Date</h3>
          <p className="subtitle">Orders before Friday of each week will be delivered on the next Sunday or Monday.</p>

          <form className="delivery_date-form">
            <div className="form-row">
              { getDateOptions() }
            </div>
          </form>

          <a href="/cart/confirm" id="pagesubmit" onClick={ handleFormSubmit }>Select Delivery Date and Continue</a>
        </div>
      );
    }
  }
}

export default DeliveryDatePage;
