import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import cookie from 'react-cookies'

// TODO: Move this stuff to a better location.
window.ss = {
    api: 'https://api.snackshare.com',
    me: {},
    is_no_orders: true,
    returnTo: null,
    productAdded: false,
    is_open_order: false,
    warehouseSections: [ 'dry', 'walk-in', 'meat' ],
    cart: {},
    cartDefault: function() {
        return {
            items: [],
            address: null,
            delivery_date: null,
            promo_code: '',
            payment_card: null
        };
    },
    cartEmpty: function() {
        window.ss.cart = window.ss.cartDefault();
        window.ss.updateCartIcon();
        window.ss.cartSave();
    },
    cartLoad: function() {
        let cartObj = cookie.load('cart');
        if ( cartObj ) window.ss.cart = cartObj;
        window.ss.updateCartIcon();
    },
    cartSetProduct: function( productIdent, quantity, reset ) {
        let quantityInt = window.ss.num( quantity );
        let foundItem = false;
        if ( quantityInt < 1 ) {
            window.ss.cart.items.forEach( ( item, i ) => {
                if ( item.product_ident === productIdent ) {
                    foundItem = true;
                    window.ss.cart.items.splice( i, 1 );
                }
            });
        } else {
            window.ss.cart.items.forEach( ( item, i ) => {
                if ( item.product_ident === productIdent ) {
                    foundItem = true;
                    if ( reset ) {
                        item.quantity = quantityInt;
                    } else {
                        item.quantity += quantityInt;
                    }
                }
            });
            if ( !foundItem ) {
                window.ss.cart.items.push({ product_ident: productIdent, quantity: quantityInt });
            }
        }
        window.ss.updateCartIcon();
        window.ss.cartSave();
    },
    cartSet: function( key, value ) {
        window.ss.cart[ key ] = value;
        window.ss.cartSave();
    },
    updateCartIcon: function() {
        let carticon = document.getElementById('carticon');
        if ( !carticon ) return setTimeout( window.ss.updateCartIcon, 100 );
        let str = '';
        if ( window.ss.is_open_order && window.ss.me.order.delivery_date ) {
            str += '<a href="/cart/order" class="nav-openorder">';
            str += window.ss.smallDate( window.ss.strToDate( window.ss.me.order.delivery_date ).getTime() );
            str += ' Order';
            str += '</a>';
        } else if ( !window.ss.is_no_orders ) {
            str += '<a href="/cart" class="nav-cart">';
            if ( window.ss.cart.items && window.ss.cart.items.length > 0 ) {
                str += '<span id="cartdot">&bull;</span>';
            }
            str += '<img src="/img/shopping-cart.svg" alt="Cart" />';
            str += '</a>';
        }
        carticon.innerHTML = str;
    },
    cartSave: function() {
        let expiresAt = new Date( new Date().getTime() + ( 30 * 24 * 60 * 60 * 1000 ) );
        cookie.save('cart', window.ss.cart, { path: '/', expires: expiresAt });
    },
    addProduct: function( product, quantity ) {
        return new Promise( ( resolve, reject ) => {

            if ( product.is_instant_buy ) {
                window.ss.buyNow = window.ss.cartDefault();
                window.ss.buyNow.is_csa_agreed = false;
                window.ss.buyNow.items.push({ product_ident: product.ident, quantity: 1 });
                window.ss.productAdded = true;
                let nextStep = '/cart/address';
                if ( !window.ss.me.id ) {
                    window.ss.saveReturn( nextStep );
                    return resolve( '/account/register' );
                } else {
                    return resolve( nextStep );
                }

            } else if ( window.ss.is_open_order ) {
                let foundItem = false;
                window.ss.me.order.items.forEach( ( item, i ) => {
                    if ( !foundItem && item.product.ident === product.ident ) {
                        foundItem = true;
                        window.ss.put( '/items/' + item.id, {
                            quantity: item.quantity + quantity
                        }).then( json => {
                            if ( json && json.err && json.err.message ) {
                                alert( json.err.message );
                            } else if ( json.error ) {
                                alert( json.error );
                            }
                            window.ss.updateCartIcon();
                            return resolve( '/cart/order' );
                        });
                    }
                });
                if ( !foundItem ) {
                    window.ss.post( '/items', {
                        order: window.ss.me.order.id,
                        product_ident: product.ident,
                        quantity: quantity
                    }).then( json => {
                        if ( json && json.err && json.err.message ) {
                            alert( json.err.message );
                        } else if ( json.error ) {
                            alert( json.error );
                        }
                        window.ss.updateCartIcon();
                        return resolve( '/cart/order' );
                    });
                }
            } else {
                window.ss.cartSetProduct( product.ident, quantity );
                window.ss.productAdded = true;
                return resolve( '/cart' );
            }

        });
    },
    flash: function( msg, seconds ) {
        if ( !seconds ) seconds = 1;
        var el = document.getElementById('flash');
        el.innerHTML = msg;
        el.className = 'flash fadein';
        setTimeout( function() {
            el.className = 'flash';
        }, ( seconds * 1000 ) );
    },
    loadReturn: function() {
        let returnTo = cookie.load('returnto');
        if ( returnTo ) window.ss.returnTo = returnTo;
    },
    saveReturn: function( path ) {
        window.ss.returnTo = path;
        cookie.save('returnto', path, { path: '/' });
    },
    clearReturn: function() {
        window.ss.returnTo = null;
        cookie.remove('returnto', { path: '/' });
    },
    get: function( path ) {
        path += ( path.indexOf('?') > -1 ? '&' : '?' ) + 'ran=' + new Date().getTime();
        return fetch( window.ss.api + path, {
            credentials: 'include',
            headers: { 'content-type': 'application/json' }
        }).then( response => {
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    post: function( path, data ) {
        return fetch( window.ss.api + path, {
            method: 'POST',
            credentials: 'include',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify( data )
        }).then( response => {
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    put: function( path, data ) {
        return fetch( window.ss.api + path, {
            method: 'PUT',
            credentials: 'include',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify( data )
        }).then( response => {
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    delete: function( path, data ) {
        return fetch( window.ss.api + path, {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify( data )
        }).then( response => {
            return response.json().then( function( json ) {
                return json;
            });
        }).catch( error => {
            console.log( error );
        });
    },
    num: function( str ) {
        var n = parseInt( str, 10 );
        return ( n && !isNaN(n) ? n : 0 );
    },
    fullDate: function( dateStr ) {
        if ( !dateStr ) return '';
        let d = window.ss.strToDate( dateStr );
        return window.ss.dayOfWeek( d.getDay() )+', '+window.ss.month( d.getMonth()+1 )+' '+d.getDate()+', '+d.getFullYear();
    },
    smallDateText: function( d ) {
        let dow = ( window.ss.dayOfWeek( d.getDay() ) ).substr(0,3);
        return dow +', '+window.ss.month( d.getMonth()+1 )+' '+d.getDate();
    },
    strToDate: function( dateStr ) {
        if ( !dateStr ) return '';
        let arr = dateStr.split('-');
        return new Date( parseInt( arr[0], 10 ), ( parseInt( arr[1], 10 ) - 1 ), parseInt( arr[2], 10 ));
    },
    dateTime: function( milliseconds ) {
        if ( !milliseconds ) return '';
        var date = new Date( parseInt( milliseconds, 10 ) ),
            now = new Date(),
            then_day = date.getDate(),
            then_month = date.getMonth() + 1,
            now_day = now.getDate(),
            now_month = now.getMonth() + 1;
        if ( ( then_day === now_day) && ( then_month === now_month ) ) {
            return window.ss.smallTime(milliseconds);
        } else {
            return window.ss.smallDate(milliseconds) + ' ' + window.ss.smallTime(milliseconds);
        }
    },
    smallDate: function(milliseconds) {
        if ( !milliseconds ) return '';
        var date = new Date( parseInt( milliseconds, 10 ) ),
            day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear();
        return month + '/' + (day < 10 ? '0' : '') + day + ( year !== new Date().getFullYear() ? ('/' + year) : '' );
    },
    smallTime: function(milliseconds) {
        if ( !milliseconds ) return '';
        var date = new Date( parseInt( milliseconds, 10 ) ),
            pm = false,
            hours = date.getHours(),
            minutes = date.getMinutes();
        if ( hours > 12 ) {
            hours = hours - 12;
            pm = true;
        } else if ( hours === 0 ) {
            hours = 12;
        } else if ( hours === 12 ) {
            pm = true;
        }
        return hours + ':' + (minutes < 10 ? '0' : '') + minutes + ( pm ? 'pm' : 'am');
    },
    toTitleCase: function(str) {
        if ( !str ) return '';
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    },
    formatTel: function( phone_str ) {
        var num_only = (''+phone_str).replace(/[^\d]/g, '');
        if ( num_only.length !== 10 ) return phone_str;
        return '+1-' + num_only.substr(0,3) + '-' + num_only.substr(3,3) + '-' + num_only.substr(6);
    },
    formatPhone: function( phone_str ) {
        var num_only = (''+phone_str).replace(/[^\d]/g, '');
        if ( num_only.length !== 10 ) return phone_str;
        return '(' + num_only.substr(0,3) + ') ' + num_only.substr(3,3) + '-' + num_only.substr(6);
    },
    getNextDay: function( dayNum ) {
        // Get today
        let now = new Date();
        // Look for the next day of the week we want, or today if this is it.
        while ( (''+now.getDay()) !== (''+dayNum) ) {
            // Go to the next day.
            now.setDate( now.getDate() + 1 );
        }
        return now;
    },
    inputDate: function(milliseconds) {
        if ( !milliseconds ) return '';
        var date = new Date( parseInt( milliseconds, 10 ) ),
            day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear();
        return year + '-' + ( month < 10 ? '0' : '' ) + month + '-' + ( day < 10 ? '0' : '' ) + day;
    },
    dayOfWeek: function(i) {
        return [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ][i];
    },
    month: function(i) {
        return [
            '',
            'Jan.',
            'Feb.',
            'Mar.',
            'Apr.',
            'May',
            'June',
            'July',
            'Aug.',
            'Sept.',
            'Oct.',
            'Nov.',
            'Dec.'
        ][i];
    },
    states: [
        { "name": "Alabama", "abbr": "AL" },
        { "name": "Alaska", "abbr": "AK" },
        { "name": "Arizona", "abbr": "AZ" },
        { "name": "Arkansas", "abbr": "AR" },
        { "name": "California", "abbr": "CA" },
        { "name": "Colorado", "abbr": "CO" },
        { "name": "Connecticut", "abbr": "CT" },
        { "name": "Delaware", "abbr": "DE" },
        { "name": "District Of Columbia", "abbr": "DC" },
        { "name": "Florida", "abbr": "FL" },
        { "name": "Georgia", "abbr": "GA" },
        { "name": "Hawaii", "abbr": "HI" },
        { "name": "Idaho", "abbr": "ID" },
        { "name": "Illinois", "abbr": "IL" },
        { "name": "Indiana", "abbr": "IN" },
        { "name": "Iowa", "abbr": "IA" },
        { "name": "Kansas", "abbr": "KS" },
        { "name": "Kentucky", "abbr": "KY" },
        { "name": "Louisiana", "abbr": "LA" },
        { "name": "Maine", "abbr": "ME" },
        { "name": "Maryland", "abbr": "MD" },
        { "name": "Massachusetts", "abbr": "MA" },
        { "name": "Michigan", "abbr": "MI" },
        { "name": "Minnesota", "abbr": "MN" },
        { "name": "Mississippi", "abbr": "MS" },
        { "name": "Missouri", "abbr": "MO" },
        { "name": "Montana", "abbr": "MT" },
        { "name": "Nebraska", "abbr": "NE" },
        { "name": "Nevada", "abbr": "NV" },
        { "name": "New Hampshire", "abbr": "NH" },
        { "name": "New Jersey", "abbr": "NJ" },
        { "name": "New Mexico", "abbr": "NM" },
        { "name": "New York", "abbr": "NY" },
        { "name": "North Carolina", "abbr": "NC" },
        { "name": "North Dakota", "abbr": "ND" },
        { "name": "Ohio", "abbr": "OH" },
        { "name": "Oklahoma", "abbr": "OK" },
        { "name": "Oregon", "abbr": "OR" },
        { "name": "Pennsylvania", "abbr": "PA" },
        { "name": "Rhode Island", "abbr": "RI" },
        { "name": "South Carolina", "abbr": "SC" },
        { "name": "South Dakota", "abbr": "SD" },
        { "name": "Tennessee", "abbr": "TN" },
        { "name": "Texas", "abbr": "TX" },
        { "name": "Utah", "abbr": "UT" },
        { "name": "Vermont", "abbr": "VT" },
        { "name": "Virginia", "abbr": "VA" },
        { "name": "Washington", "abbr": "WA" },
        { "name": "West Virginia", "abbr": "WV" },
        { "name": "Wisconsin", "abbr": "WI" },
        { "name": "Wyoming", "abbr": "WY" }
    ],
    greenGateProducts: function() {
        return [
            'greengatefarms-10-week-winter-csa-full',
            'greengatefarms-10-week-winter-csa-individual',
            'greengatefarms-bi-weekly-winter-csa-a-week',
            'greengatefarms-bi-weekly-winter-csa-b-week',
            'greengatefarms-prepaid-winter-csa',
            'greengatefarms-10-week-spring-csa-full',
            'greengatefarms-10-week-spring-csa-individual',
            'greengatefarms-bi-weekly-spring-csa-a-week',
            'greengatefarms-bi-weekly-spring-csa-b-week',
            'greengatefarms-prepaid-spring-csa',
            'greengatefarms-10-week-summer-csa-full',
            'greengatefarms-10-week-summer-csa-individual',
            'greengatefarms-bi-weekly-summer-csa-a-week',
            'greengatefarms-bi-weekly-summer-csa-b-week',
            'greengatefarms-prepaid-summer-csa'
        ];
    },
    newLeafProducts: function() {
        return [
            'newleaf-8-week-spring-csa-full',
            'newleaf-8-week-spring-csa-individual',
            'newleaf-bi-weekly-spring-csa-a-week',
            'newleaf-bi-weekly-spring-csa-b-week',
            'newleaf-spring-csa-delivery',
            'newleaf-8-week-summer-csa-full',
            'newleaf-8-week-summer-csa-individual',
            'newleaf-bi-weekly-summer-csa-a-week',
            'newleaf-bi-weekly-summer-csa-b-week',
            'newleaf-prepaid-summer-csa',
            'newleaf-3-week-summer-extension'
        ];
    },
    csaProducts: function() {
        return [].concat( window.ss.greenGateProducts() ).concat( window.ss.newLeafProducts() );
    },
    formatCost: function( amount ) {
        if ( isNaN(amount) ) return '';
        return amount.toFixed(2);
    },
    fadeOut: function( element ) {
        var op = 1;  // initial opacity
        var timer = setInterval(function () {
            if (op <= 0.1){
                clearInterval(timer);
                element.parentNode.removeChild( element );
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op -= op * 0.1;
        }, 50);
    },
    applyMe: function( json ) {
        if ( json && json.id ) {
            window.ss.me = json;
            // If this user has an open order,
            if ( window.ss.me.id && window.ss.me.order.id ) {
                // Mark it in the object.
                window.ss.is_open_order = true;
                window.ss.updateCartIcon();
            } else {
                // Fetch cart object.
                window.ss.is_open_order = false;
                window.ss.cartLoad();
            }
        } else {
            window.ss.is_open_order = false;
            window.ss.cartLoad();
        }
    },
    orderQR: function( orderId ) {
        let url = window.location.protocol + '//' + window.location.hostname + ( window.location.port ? ':' + window.location.port : '' ) + '/order/' + orderId;
        return 'https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=' + encodeURIComponent( url );
    },
    initReact: function() {
        ReactDOM.render(
        <App />
          , document.getElementById('root'));
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    }
};

if ( window.location.hostname.indexOf('localhost') > -1 ) {
//    window.ss.api = 'https://api-staging.snackshare.com';
    window.ss.api = 'http://localhost:3001';
} else if ( window.location.hostname.indexOf('staging.snackshare.com') > -1 ) {
    window.ss.api = 'https://api-staging.snackshare.com';
} else if ( window.location.protocol !== 'https:' ) {
    window.location.href = 'https://' + window.location.hostname + window.location.pathname + window.location.search + window.location.hash;
}

window.ss.get( '/users/me' ).then( json => {
    window.ss.is_no_orders = true;
    window.ss.loadReturn();
    window.ss.cart = window.ss.cartDefault();
    window.ss.applyMe( json );
    window.ss.updateCartIcon();
    window.ss.initReact();
});
