import React, { Component } from 'react';
import OrderList from '../../orders/OrderList';

class AdminOrders extends Component {
    _isMounted = false;

    state = {
        ordersUpcomingDeliveries: {},
        ordersDelivered: {}
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadOrders();
        document.title = 'Orders | SnackShare';
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadOrders() {
        await this.setState({
            ordersUpcomingDeliveries: {},
            ordersDelivered: {}
        });
        await this.fetch( 'ordersUpcomingDeliveries', '/orders?user=all&status=pending&per_page=3' );
        await this.fetch( 'ordersDelivered', '/orders?user=all&status=delivered&sort=recent&per_page=3' );
        this.render();
    }

    fetch( key, url ) {
        let self = this;
        return window.ss.get( url ).then( response => {
            let obj = {};
            obj[ key ] = response;
            if ( self._isMounted ) {
                return self.setState( obj );
            } else {
                return Promise.resolve();
            }
        });
    }

    render() {
        return (
            <div className="home-orders">

                { this.state.ordersUpcomingDeliveries.total > 0 && (
                    <OrderList list_type="pending" groupDate="true" limit="3" orders={ this.state.ordersUpcomingDeliveries } />
                )}

                { this.state.ordersDelivered.total > 0 && (
                    <OrderList list_type="delivered" groupDate="true" limit="3" orders={ this.state.ordersDelivered } />
                )}

            </div>
        );
    }
}

export default AdminOrders;
