import React, { Component } from 'react';
import { Link } from "react-router-dom";

class OrderRow extends Component {

    state = {
        order: this.props.order,
        items_count: 0
    };

    componentWillMount() {
        let count = 0;
        this.state.order.items.forEach( item => {
            count += item.quantity;
        });
        this.setState({ items_count: count });
    }

    handleClick(e) {
        if ( ['select','input'].indexOf( e.target.tagName.toLowerCase() ) > -1 ) {
            e.preventDefault();
        };
        return true;
    }

    handlePositionChange(e) {
        let self = this;
        let order = { ...this.state.order };
        order.delivery_position = e.target.value;
        this.setState({ order });
        window.ss.put( '/orders/' + self.state.order.id, {
            delivery_position: e.target.value
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            }
        });
    }

    render() {
        let order = this.state.order;
        let self = this;
        let isGreenGateVendor = ( window.ss.me.is_vendor && window.ss.me.vendor.ident === 'greengatefarms' );
        let isNewLeafVendor = ( window.ss.me.is_vendor && window.ss.me.vendor.ident === 'newleaf' );

        function orderInfo() {
            return (
                <span>
                    { window.ss.me.is_admin && (
                        <img alt="QR Code" id="qrcode" src={ window.ss.orderQR( order.id ) } />
                    )}
                    <span className="item-info">
                        <span className="order-user-name">{ order.user && order.user.name }</span>
                        <span className="address-item-info">
                            <span className="address-item-line">{ order.address.address1 }</span>
                            { order.address.address2 && (
                                <span className="address-item-line">{ order.address.address2 }</span>
                            )}
                            <span className="address-item-line">
                                { order.address.city }, { order.address.state } { order.address.zipcode }
                            </span>
                            <span className="address-item-line address-item-type">{ order.address.location_type }</span>
                            { order.address.notes && (
                                <span className="address-item-line address-item-notes">{ order.address.notes }</span>
                            )}
                            { self.props.showContact && ( window.ss.me.is_admin || isGreenGateVendor || isNewLeafVendor ) && (
                                <span>
                                    <span className="address-item-line"><a href={ 'mailto:' + order.user.email }>{ order.user.email }</a></span>
                                    <span className="address-item-line"><a href={ 'tel:' + window.ss.formatTel( order.user.phone ) }>{ window.ss.formatPhone( order.user.phone ) }</a></span>
                                </span>
                            )}
                        </span>
                    </span>
                    <span className="item-actions">
                        <div className="item-status">
                            { self.state.items_count } item{ self.state.items_count === 1 ? '' : 's' }
                            <div className="item-status-at">{ window.ss.dateTime( order.created_int ) }</div>
                        </div>
                        { order.status === 'cancelled' && (
                            <div className="order-failed">CANCELLED</div>
                        )}
                        { order.is_paid && (
                            <div className="order-paid">PAID</div>
                        )}
                        { order.is_failed && (
                            <div className="order-failed">
                                FAILED
                                <span className="order-fail-reason">
                                    { ( ''+order.stripe_failure ).replace(/_/g, ' ') }
                                </span>
                            </div>
                        )}
                    </span>
                </span>
            );
        }

        if ( self.props.removeLink ) {
            return (
                <div className="order-list-row item-row">
                    { orderInfo() }
                </div>
            );
        } else {
            return (
                <Link onClick={ self.handleClick.bind(self) } to={ '/order/' + order.id } className="order-list-row item-row">
                    { orderInfo() }
                </Link>
            );
        }
    }
}

export default OrderRow;
