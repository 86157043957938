import React, { Component } from 'react';
import { Link } from "react-router-dom";

class ItemRow extends Component {

    state = {
        item: this.props.item
    };

    editPounds(e) {
        let self = this;
        e.preventDefault();
        let itemId = e.currentTarget.getAttribute('data-item');
        if ( !self.state.item.product.is_weight_based ) return;
        let pounds = prompt( 'How many pounds total?', ( self.state.item.total_pounds ? self.state.item.total_pounds : 1 ) );
        if ( pounds === null ) return;
        window.ss.put( '/items/' + itemId, {
            total_pounds: pounds
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else {
                self.setState({ item: json });
                if ( self.props.onChange ) {
                    self.props.onChange();
                }
            }
        });
    }

    confirmItem(e) {
        let self = this;
        e.preventDefault();
        let itemId = e.currentTarget.getAttribute('data-item');
        window.ss.put( '/items/' + itemId, {
            confirmed: '1'
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else {
                if ( self.props.onChange ) {
                    self.props.onChange();
                } else {
                    self.setState({ item: json });
                }
            }
        });
    }

    cancelItem(e) {
        e.preventDefault();
        let self = this;
        let reason = prompt( 'Why are you cancelling?', 'Item Unavailable' );
        if ( reason === null ) return;
        let itemId = e.currentTarget.getAttribute('data-item');
        window.ss.put( '/items/' + itemId, {
            cancelled_reason: reason,
            cancelled: '1'
        }).then( json => {
            if ( json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else {
                if ( self.props.onChange ) {
                    self.props.onChange();
                } else {
                    self.setState({ item: json });
                }
            }
        });
    }

    markStaged(e) {
        e.preventDefault();
        let self = this;
        let pounds = self.state.item.total_pounds;
        if ( self.state.item.product.is_weight_based && !self.state.item.total_pounds ) {
            pounds = prompt( 'How many pounds total?', 1 );
            if ( pounds === null ) return;
        }
        let itemId = e.currentTarget.getAttribute('data-item');
        window.ss.put( '/items/' + itemId, {
            staged: '1',
            total_pounds: pounds
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else {
                if ( self.props.onChange ) {
                    self.props.onChange();
                } else {
                    self.setState({ item: json });
                }
            }
        });
    }

    render() {
        let self = this;
        let item = self.state.item;
        let isVendor = ( window.ss.me.is_vendor && item.product && item.product.vendor && item.product.vendor.id === window.ss.me.vendor.id );
        return (
            <div className="item-row">
                <div className="item-quantity">{ item.quantity }</div>
                <div className="item-info">
                    <div className="item-name">{ item.product && item.product.name }</div>
                    <div className="item-unit">{ item.product && item.product.unit_name }</div>
                    { item.product.is_weight_based && ( item.total_pounds || window.ss.me.is_admin ) && (
                        <div className="item-pounds">
                            { ( item.total_pounds || ( window.ss.me.is_admin && item.status !== 'cancelled' ) ) && (
                                <span>{ item.total_pounds || '' } total pound{ item.total_pounds === 1 ? '' : 's' }</span>
                            )}
                            { window.ss.me.is_admin && item.status !== 'cancelled' && (
                                <a href="/" className="item-pounds-edit" data-item={ item.id } onClick={ self.editPounds.bind(self) }>edit</a>
                            )}
                        </div>
                    )}
                    { !isVendor && item.product.is_weight_based && item.total_pounds && item.pound_cost && (
                        <div className="item-costs"><b>${ window.ss.formatCost( item.cost ) } subtotal</b></div>
                    )}
                    { !isVendor && item.product.is_weight_based && !item.total_pounds && (
                        <div className="item-costs">x <b>{ item.product.pound_avg } lbs.</b> x <b>${ window.ss.formatCost( item.pound_cost ) }</b> = <b>${ window.ss.formatCost( item.quantity * item.product.pound_avg * item.pound_cost ) }</b></div>
                    )}
                    { !isVendor && !item.product.is_weight_based && !!item.cost && (
                        <div className="item-costs">x <b>${ window.ss.formatCost( item.cost ) }</b> = <b>${ window.ss.formatCost( item.quantity * item.cost ) }</b></div>
                    )}
                    { ( isVendor || window.ss.me.is_admin ) && item.status === 'unconfirmed' && (
                        <div className="item-due">{ item.staging_due_label.replace(', during business hours', '') }</div>
                    )}
                    { item.status === 'cancelled' && item.cancelled_reason && (
                        <div className="item-cancelled">{ item.cancelled_reason }</div>
                    )}
                    { !isVendor && (
                        <div className="item-vendor">{ item.product && item.product.vendor && item.product.vendor.name }</div>
                    )}
                    { isVendor && item.order_name && (
                        <div className="item-user">{ item.order_name }, { window.ss.dateTime( item.order_int ) }</div>
                    )}
                    { window.ss.csaProducts().indexOf( item.product.ident ) > -1 && isVendor && (
                        <div className="item-user">{ item.order_email }</div>
                    )}
                    { window.ss.me.is_admin && item.order_name && (
                        <Link to={ '/order/' + item.order } className="item-user">{ item.order_name }, { window.ss.dateTime( item.order_int ) }</Link>
                    )}
                </div>
                { !isVendor && !window.ss.me.is_admin && item.status === 'cancelled' && (
                    <div className="item-actions">
                        <div className="item-status item-status-cancelled">
                            { item.status }
                            <div className="item-status-at">{ window.ss.dateTime( item.cancelled_int ) }</div>
                        </div>
                    </div>
                )}

                { window.ss.csaProducts().indexOf( item.product.ident ) === -1 && ( isVendor || window.ss.me.is_admin ) && (
                    <div className="item-actions">
                        { window.ss.me.is_admin && ( item.product.warehouse_section || item.product.shelf ) && (
                            <div className="item-shelf">Warehouse:&nbsp;
                                { item.product.warehouse_section && !item.product.shelf && (
                                    <b>{ window.ss.toTitleCase( item.product.warehouse_section ) }</b>
                                )}
                                { item.product.warehouse_section && item.product.shelf && (
                                    <b>{ window.ss.toTitleCase( item.product.warehouse_section ) }, { item.product.shelf }</b>
                                )}
                                { !item.product.warehouse_section && item.product.shelf && (
                                    <b>{ item.product.shelf }</b>
                                )}
                            </div>
                        )}
                        { item.status === 'staged' && (
                            <div className="item-status">
                                { item.status }
                                <div className="item-status-at">{ window.ss.dateTime( item.staged_int ) }</div>
                            </div>
                        )}
                        { item.status === 'delivered' && (
                            <div className="item-status">
                                { item.status }
                                <div className="item-status-at">{ window.ss.dateTime( item.delivered_int ) }</div>
                            </div>
                        )}
                        { item.status === 'cancelled' && (
                            <div className="item-status item-status-cancelled">
                                { item.status }
                                <div className="item-status-at">{ window.ss.dateTime( item.cancelled_int ) }</div>
                            </div>
                        )}

                        { item.status === 'unconfirmed' && (
                            <span>
                                <a href="/home" data-item={ item.id } onClick={ self.confirmItem.bind(self) } className="item-confirm">Confirm</a>
                                <a href="/home" data-item={ item.id } onClick={ self.cancelItem.bind(self) } className="item-cancel">Cancel</a>
                            </span>
                        )}

                        { ( window.ss.me.is_admin && item.status === 'confirmed' ) && (
                            <a href="/home" data-item={ item.id } onClick={ self.markStaged.bind(self) } className="item-confirm">Received</a>
                        )}

                        { item.status === 'confirmed' && (
                            <a href="/home" data-item={ item.id } onClick={ self.cancelItem.bind(self) } className="item-cancel-link">Cancel</a>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default ItemRow;
