import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ItemList from '../items/ItemList';
import OrderRow from './OrderRow';
import OrderPayment from './OrderPayment';

class OrderDetails extends Component {

    state = {
        order: null,
        confirming: false,
        loading: true,
        paymentUpdated: false
    };

    componentDidMount() {
        const { order_id } = this.props.match.params;
        if ( !window.ss.me.id ) {
            window.ss.saveReturn( window.location.pathname );
            return this.props.history.push( '/account/register' );
        } else if ( window.ss.me.order && window.ss.me.order.id === order_id ) {
            return this.props.history.push( '/cart/order' );
        } else {
            this.loadOrder();
        }
    }

    loadOrder( skipReset ) {
        let self = this;
        if ( !skipReset ) self.setState({ order: null });
        const { order_id } = this.props.match.params;
        window.ss.get( '/orders/' + order_id ).then( order => {
            if ( !order ) return;
            if ( order.error ) {
                alert( order.error );
                return
            }
            document.title = 'Order Details | SnackShare';
            self.setState({ order, loading: false });
        });
    }

    handlePageSubmit(e) {
        e.preventDefault();
        let self = this;
        if ( window.confirm('Mark this order delivered?') ) {
            window.ss.put( '/orders/' + self.state.order.id, {
                delivered: '1'
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                } else if ( json.error ) {
                    alert( json.error );
                } else {
                    self.setState({ order: json });
                }
            });
        }
    }

    cancelOrder(e) {
        e.preventDefault();
        let self = this;
        if ( window.confirm('Are you sure you wish to cancel this?') ) {
            window.ss.put( '/orders/' + self.state.order.id, {
                cancelled: '1'
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                } else if ( json.error ) {
                    alert( json.error );
                } else {
                    self.setState({ order: json });
                }
            });
        }
    }

    confirmPayment(e) {
        e.preventDefault();
        let self = this;
        let box = document.getElementById('confirmBox');
        let link = e.target;
        if ( self.state.confirming ) return;
        self.setState({ confirming: true });
        link.innerHTML = 'Confirming...';
        let stripe = window.Stripe( this.state.order.stripe_key );
        stripe.confirmCardPayment( this.state.order.stripe_secret, {
            payment_method: this.state.order.stripe_payment
        }).then( function( result ) {
            if ( result.error ) {
                box.innerHTML = result.error.message;
            } else {
                if ( result.paymentIntent.status === 'succeeded' ) {
                    box.className = 'order-status';
                    box.innerHTML = 'Payment Confirmed!';
                    window.ss.post( '/payment_cards/confirm', {
                        order: self.state.order.id,
                        payment_intent: result.paymentIntent
                    });
                    setTimeout( function() {
                        self.loadOrder();
                    }, 3000 );
                } else {
                    link.innerHTML = 'Click Here to Confirm Payment';
                    self.setState({ confirming: false });
                }
            }
        });
        return false;
    }

    goBack(e) {
        if ( window.history.length && window.history.length > 1 ) {
            e.preventDefault();
            window.history.back();
        }
    }

    handlePrint(e) {
        e.preventDefault();
        window.print();
    }

    handlePaymentSelect( paymentCard ) {
        let self = this;
        if ( !paymentCard || self.state.order.payment_card.id === paymentCard ) return;
        window.ss.put( '/orders/' + self.state.order.id, {
            payment_card: paymentCard
        }).then( json2 => {
            if ( json2 && json2.err && json2.err.message ) {
                alert( json2.err.message );
            } else if ( json2 && json2.error ) {
                alert( json2.error );
            } else {
                self.loadOrder( true );
                self.setState({ paymentUpdated: true });
                setTimeout( function() {
                    self.setState({ paymentUpdated: false });
                }, 5000 );
            }
        });
    }

    render() {
        let self = this;
        let order = this.state.order;

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else if ( order ) {
            return (
                <div className="page order">

                    { self.state.paymentUpdated && (
                        <div className="order-updated">
                            Your payment method has been updated!
                        </div>
                    )}

                    <Link to="/home" className="backButton" onClick={ self.goBack.bind( this ) }><img alt="Back" src="/img/arrow-circle-left.svg" /></Link>

                    <h3 className="title">Order</h3>

                    <a href="/" className="printButton" onClick={ self.handlePrint.bind( this ) }><img alt="Back" src="/img/print.svg" /></a>

                    { order.is_need_confirm && window.ss.me.id === order.user.id && (
                        <div id="confirmBox" className="order-status-cancelled">
                            Action Required:<br />
                            <a href={ '/orders/' + order.id } onClick={ self.confirmPayment.bind( this ) }>Click Here to Confirm Payment</a>
                        </div>
                    )}

                    { order.status === 'delivered' && (
                        <div className="order-status">
                            Delivered<br />
                            { window.ss.dateTime( order.delivered_int ) }
                        </div>
                    )}

                    { order.status === 'cancelled' && (
                        <div className="order-status-cancelled">
                            Cancelled<br />
                            { window.ss.dateTime( order.cancelled_int ) }
                            { order.cancelled_reason && (
                                <div className="cancelled-reason">
                                    { order.cancelled_reason }
                                </div>
                            )}
                        </div>
                    )}

                    <div className="item-grouped">
                        <div className="item-date-section">

                            <div className="item-date">Delivery for <span>{ window.ss.fullDate( order.delivery_date ) }</span></div>

                            <div className="item-list">
                                <OrderRow removeLink="true" showContact="true" order={ order } />
                            </div>

                        </div>
                    </div>

                    { ( order.items.length > 0 || order.subscription.id ) && (
                        <ItemList items={ { total: order.items.length, results: order.items } } order={ order } onChange={ self.loadOrder.bind( self ) } />
                    )}

                    { order.cancelled_items && order.cancelled_items.length > 0 && (
                        <ItemList list_type="cancelled" items={ { total: order.cancelled_items.length, results: order.cancelled_items } } order={ order } onChange={ self.loadOrder.bind( self ) } />
                    )}

                    <h3 className="title">Summary</h3>

                    <div className="cart-summary">
                    <table><tbody>
                        { !!order.subtotal && (
                            <tr className="cart-subtotal">
                                <td className="cart-summary-label">Subtotal</td>
                                <td className="cart-summary-amount">${ window.ss.formatCost( order.subtotal ) }</td>
                            </tr>
                        )}
                        <tr className="cart-delivery">
                            <td className="cart-summary-label">Delivery Fee</td>
                            <td className="cart-summary-amount">${ window.ss.formatCost( order.delivery_fee ) }</td>
                        </tr>
                        { order.promo_code ? (
                            <tr className="cart-promo">
                                <td className="cart-summary-label">Promo: <b>{ order.promo_code.toUpperCase() }</b></td>
                                <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( order.promo_discount ) }</td>
                            </tr>
                        ) : null}
                        { order.gift_applied ? (
                            <tr className="cart-promo">
                                <td className="cart-summary-label">Gift Card:</td>
                                <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( order.gift_applied ) }</td>
                            </tr>
                        ) : null}
                        <tr className="cart-total">
                            <td className="cart-summary-label">Total</td>
                            <td className="cart-summary-amount">${ window.ss.formatCost( order.total ) }</td>
                        </tr>
                    </tbody></table>
                    </div>

                    { window.ss.me.id === order.user.id && !order.is_paid && order.status !== 'cancelled' && (
                        <div>
                            <h3 className="title">Payment</h3>

                            { !!( order.total === 0 && order.gift_applied ) && (
                                <div className="giftcardccexplain">
                                    <p>Even though your gift card covers the order, we still need to record your credit card info.</p>
                                    <p>You will be able to add more items to your order until <b>{ window.ss.fullDate( order.close_date ) }</b> at midnight, but your card will not be charged if you have enough balance to cover the full order.</p>
                                </div>
                            )}

                            <OrderPayment onSelect={ self.handlePaymentSelect.bind( self ) } payment_card={ order.payment_card ? order.payment_card.id : null } />
                        </div>
                    )}

                    { !order.is_subscription && ( !order.subscription || !order.subscription.id ) && ( window.ss.me.is_admin || ( window.ss.me.id === order.user.id && order.close_int > new Date().getTime() ) ) && order.status !== 'delivered' && order.status !== 'cancelled' && (
                        <div className="cancellink"><a href="/home" onClick={ self.cancelOrder.bind(self) }>Cancel Order</a></div>
                    )}

                    { !order.is_subscription && window.ss.me.is_admin && order.status !== 'delivered' && order.status !== 'cancelled' && (
                        <a href="/home" id="pagesubmit" onClick={ self.handlePageSubmit.bind(self) }>Mark Delivered</a>
                    )}

                </div>
            );
        } else {
            return (
                <div className="page home-orders">
                    <Link to="/home" className="backButton"><img alt="Back" src="/img/arrow-circle-left.svg" /></Link>
                    <h3 className="title">Order</h3>
                </div>
            );
        }
    }
}

export default OrderDetails;
