import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AddressPage extends Component {

    state = {
        addresses: { results: [] },
        showingForm: false,
        addressselection: null,
        address1: '',
        address2: '',
        city: '',
        state: 'TX',
        zipcode: '',
        location_type: '',
        notes: '',
        loading: true
    };

  componentWillMount() {
    if ( !window.ss.me.id ) {
        window.ss.saveReturn( '/cart/address' );
        return this.props.history.push( '/account/register' );
    } else {
      this.loadAddresses();
    }
    document.title = 'Address | SnackShare';
  }

    loadAddresses() {
        let self = this;
        window.ss.get( '/addresses' ).then( addresses => {
            let stateObj = {
                addresses: addresses,
                loading: false
            };
            if ( addresses.results.length === 0 ) {
                stateObj.showingForm = true;
            }
            self.setState( stateObj );
        });
    }

  render() {

    let self = this;

    function handleInputChange(e) {
      let obj = {};
      obj[ e.target.name ] = e.target.value;
      self.setState( obj );
    }

    function getStates() {
      let str = '';
      window.ss.states.forEach( st => {
        str += '<option value="' + st.abbr + '"' + ( self.state.state === st.abbr ? ' selected' : '' ) + '>' + st.name +'</option>';
      });
      return (
        <select id="address-state" name="state" onChange={ handleInputChange } dangerouslySetInnerHTML={{ __html: str }}></select>
      );
    }

    function getLocationTypes() {
      let str = '<option value=""' + ( !self.state.location_type || self.state.location_type === 'unknown' ? ' selected' : '' ) + '>Select Location Type</option>';
      [ 'apartment', 'house', 'business', 'other' ].forEach( lt => {
        str += '<option value="' + lt + '"' + ( self.state.location_type === lt ? ' selected' : '' ) + '>' + window.ss.toTitleCase( lt ) +'</option>';
      });
      return (
        <select id="address-locationtype" name="location_type" onChange={ handleInputChange } dangerouslySetInnerHTML={{ __html: str }}></select>
      );
    }

    function getPageSubmitClassName() {
      if (
        self.state.address1 &&
        self.state.city &&
        self.state.state &&
        self.state.zipcode &&
        self.state.location_type
      ) {
        return '';
      } else {
        return 'hideme';
      }
    }

    function getSelectSubmitClassName() {
      return ( self.state.addressselection ? '' : 'hideme' );
    }

    function handleAddressSubmit(e) {
      e.preventDefault();
      window.ss.post( '/addresses', {
          address1:       self.state.address1,
          address2:       self.state.address2,
          city:           self.state.city,
          state:          self.state.state,
          zipcode:        self.state.zipcode,
          location_type:  self.state.location_type,
          notes:          self.state.notes
      }).then( json => {
          if ( json && json.error ) {
              alert( json.error );
          } else if ( json.id ) {
              if ( window.ss.buyNow ) {
                  window.ss.buyNow.address = json.id;
                  self.props.history.push( '/cart/confirm' );
              } else if ( window.ss.is_open_order ) {
                  window.ss.put( '/orders/' + window.ss.me.order.id, {
                      address: json.id
                  }).then( json2 => {
                      if ( json2 && json2.err && json2.err.message ) {
                          alert( json2.err.message );
                      } else if ( json2.error ) {
                          alert( json2.error );
                      } else {
                          window.ss.get( '/users/me' ).then( json => {
                              window.ss.applyMe( json );
                              self.props.history.push( '/cart/order' );
                          });
                      }
                  });
              } else {
                  window.ss.cartSet( 'address', json.id );
                  if ( !window.ss.cart.delivery_date ) {
                    self.props.history.push( '/cart/delivery_date' );
                  } else {
                    self.props.history.push( '/cart/confirm' );
                  }
              }
          }
      });
    }

    function handleAddressSelect(e) {
      let obj = {};
      obj[ e.target.name ] = e.target.value;
      self.setState( obj );
      let els = document.getElementsByClassName('address-item-selected');
      for (var i=0,l=els.length;i<l;i++) {
        els[i].className = 'address-item';
      }
      e.target.parentNode.className = 'address-item address-item-selected';
    }

    function handleAddressSelectionSubmit(e) {
      e.preventDefault();
      if ( window.ss.buyNow ) {
          window.ss.buyNow.address = self.state.addressselection;
          self.props.history.push( '/cart/confirm' );
      } else if ( window.ss.is_open_order ) {
          window.ss.put( '/orders/' + window.ss.me.order.id, {
              address: self.state.addressselection
          }).then( json2 => {
              if ( json2 && json2.err && json2.err.message ) {
                  alert( json2.err.message );
              } else if ( json2.error ) {
                  alert( json2.error );
              } else {
                  self.props.history.push( '/cart/order' );
              }
          });
      } else {
          window.ss.cartSet( 'address', self.state.addressselection );
          if ( !window.ss.cart.delivery_date ) {
            self.props.history.push('/cart/delivery_date');
          } else {
            self.props.history.push( '/cart/confirm' );
          }
      }
    }

    function handleAddressRemove(e) {
      e.preventDefault();
      let link = e.currentTarget;
      let addressId = link.getAttribute('data-remove');
      if ( window.ss.is_open_order && window.ss.me.order.address.id === addressId ) {
          return true;
      }
      if ( window.confirm('Are you sure you wish to remove this address?') ) {
        window.ss.delete( '/addresses/' + addressId, {}).then( json => {
            if ( json.error ) {
                alert( json.error );
            } else {
                let pa = link.parentNode;
                if ( pa ) pa.parentNode.removeChild( pa );
                if ( self.state.addressselection === addressId ) {
                  self.setState({ addressselection: null });
                }
            }
        });
      }
    }

    function handleAddNew(e) {
      e.preventDefault();
      self.setState({ showingForm: true });
    }

    function backToAddresses(e) {
      e.preventDefault();
      self.setState({ showingForm: false });
    }

    function showCancel() {
      if ( self.state.addresses.results.length === 0 ) return '';
      return (
          <Link to="/cart/address" className="backButton" onClick={ backToAddresses }><img alt="Back" src="/img/arrow-circle-left.svg" /></Link>
      );
    }

    if ( this.state.loading ) {
      return (
        <div className="loading">Loading...</div>
      );
    } else if ( this.state.showingForm ) {
      return (
        <div className="page address">

          { showCancel() }

          <h3 className="title">Enter Address</h3>

          <form className="address-form">

            <div className="form-row">
              <label htmlFor="address-address1">Address Line 1</label>
              <input required type="text" id="address-address1" name="address1" placeholder="Street Address" onChange={ handleInputChange } />
            </div>
            <div className="form-row">
              <label htmlFor="address-address2">Address Line 2 (Optional)</label>
              <input type="text" id="address-address2" name="address2" placeholder="Unit #" onChange={ handleInputChange } />
            </div>
            <div className="form-row">
              <label htmlFor="address-city">City</label>
              <input required type="text" id="address-city" name="city" placeholder="City" onChange={ handleInputChange } />
            </div>
            <div className="form-row">
              <label htmlFor="address-state">State</label>
              { getStates() }
            </div>
            <div className="form-row">
              <label htmlFor="address-zip">Zipcode</label>
              <input required type="text" id="address-zip" name="zipcode" placeholder="Zipcode" onChange={ handleInputChange } />
            </div>
            <div className="form-row">
              <label htmlFor="address-locationtype">Location Type</label>
              { getLocationTypes() }
            </div>
            <div className="form-row">
              <label htmlFor="address-notes">Notes (Optional)</label>
              <textarea id="address-notes" name="notes" placeholder="Enter message: &quot;Knock Twice&quot;" onChange={ handleInputChange }></textarea>
            </div>

          </form>

          <a href="/cart/address" id="pagesubmit" className={ getPageSubmitClassName() } onClick={ handleAddressSubmit }>Save Address and Continue</a>
        </div>
      );
    } else {
      return (
        <div className="page address">
          <h3 className="title">Select Delivery Address</h3>

          <form className="address-select">
              { self.state.addresses.results.map( ( address, i ) =>
                <label key={i} className="address-item">
                  <input type="radio" name="addressselection" id={ 'address_' + address.id } value={ address.id } onChange={ handleAddressSelect } />

                  { ( !window.ss.is_open_order || window.ss.me.order.address.id !== address.id ) && (
                      <a className="address-item-remove" data-remove={ address.id } onClick={ handleAddressRemove } href="/cart/address"><img src="/img/trash-can.svg" alt="Remove" /></a>
                  )}
                  <span className="address-item-info">
                    <span className="address-item-line">{ address.address1 }</span>
                    { address.address2 && (
                      <span className="address-item-line">{ address.address2 }</span>
                    )}
                    <span className="address-item-line">
                      { address.city }, { address.state } { address.zipcode }
                    </span>
                    <span className="address-item-line address-item-type">{ address.location_type }</span>
                    { address.notes && (
                      <span className="address-item-line address-item-notes">{ address.notes }</span>
                    )}
                  </span>
                </label>
              )}

              <a href="/cart/address" className="bluebutton" onClick={ handleAddNew }>Add Another Address</a>
          </form>

          <a href="/cart/confirm" id="pagesubmit" className={ getSelectSubmitClassName() } onClick={ handleAddressSelectionSubmit }>Select Address and Continue</a>
        </div>
      );
    }
  }
}

export default AddressPage;
