import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderRow from './OrderRow';

class OrderList extends Component {

    state = {
        dates: []
    };

    getTitle() {
        let titleStr = 'Orders';
        let path = '';
        if ( this.props.list_type === 'mydeliveries' ) {
            titleStr = 'My '+this.props.orders.total + ' Deliver' + ( this.props.orders.total === 1 ? 'y' : 'ies' );
            path = '/orders/mydeliveries';
        } else if ( this.props.list_type === 'pending' ) {
            titleStr = ''+this.props.orders.total + ' Upcoming Deliver' + ( this.props.orders.total === 1 ? 'y' : 'ies' );
            path = '/orders/pending';
        } else if ( this.props.list_type === 'delivered' ) {
            titleStr = ''+this.props.orders.total + ' Delivered Order' + ( this.props.orders.total === 1 ? '' : 's' );
            path = '/orders/delivered';
        } else if ( this.props.list_type === 'current' ) {
            titleStr = ''+this.props.orders.total + ' Current Order' + ( this.props.orders.total === 1 ? '' : 's' );
            path = '/orders/current';
        } else if ( this.props.list_type === 'prior' ) {
            titleStr = ''+this.props.orders.total + ' Order' + ( this.props.orders.total === 1 ? '' : 's' );
            path = '/orders/prior';
        }
        document.title = titleStr;
        return (
            <h3 className="title">
                { titleStr }
                { this.props.limit && this.props.limit < this.props.orders.total && (
                    <Link to={ path } className="forwardButton"><img alt="See All" src="/img/arrow-circle-right.svg" /></Link>
                )}
            </h3>
        );
    }

    componentWillMount() {
        if ( this.props.groupDate ) {
            this.groupByDate();
        }
    }

    groupByDate() {
        let dateObjects = {};
        this.props.orders.results.forEach( order => {
            if ( !dateObjects[ order.delivery_date ] ) {
                dateObjects[ order.delivery_date ] = [];
            }
            dateObjects[ order.delivery_date ].push( order );
        });
        for ( var key in dateObjects ) {
            dateObjects[ key ].sort((a, b) => (a.user.name > b.user.name) ? 1 : -1);
        }
        this.setState({ dates: dateObjects });
    }

    render() {
        let self = this;
        return (
            <div className="home-orders-section">
                { self.getTitle() }

                { !self.props.groupDate && (
                    <div className="item-list">
                        { self.props.orders.results.map( ( order, i ) =>
                            <OrderRow key={i} order={ order } />
                        )}
                    </div>
                )}

                { self.props.groupDate && self.state.dates && (
                    <div className="item-grouped">
                        { Object.keys( self.state.dates ).map( ( key, i ) =>
                            <div key={i} className="item-date-section">

                                <div className="item-date">
                                    <span>{ window.ss.fullDate( key ) }</span>
                                    { window.ss.me.is_admin && self.props.list_type === 'pending' && (
                                        <Link to={ '/orders/print/' + key } className="printButton"><img alt="Print" src="/img/print.svg" /></Link>
                                    )}
                                </div>

                                <div className="item-list">
                                    { self.state.dates[ key ].map( ( order, j ) =>
                                        <OrderRow key={j} order={ order } />
                                    )}
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default OrderList;
