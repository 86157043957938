import React, { Component } from 'react';

class NormalSettings extends Component {

    state = {
        first_name: window.ss.me.first_name,
        last_name: window.ss.me.last_name,
        email: window.ss.me.email,
        phone: window.ss.me.phone,
        password: '',
        saved: false
    };

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleSubmit(e) {
        e.preventDefault();
        let self = this;

        window.ss.put( '/users/' + window.ss.me.id, {
            first_name: self.state.first_name,
            last_name: self.state.last_name,
            email: self.state.email,
            phone: self.state.phone,
            password: self.state.password
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.id ) {
                window.ss.applyMe( json );
                self.setState({ saved: true });
            }
        });
    }

    render() {
        return (
            <div className="page form">

                { this.state.saved && (
                    <div className="order-status">
                        Profile Saved!
                    </div>
                )}

                <form onSubmit={ this.handleSubmit.bind(this) }>

                    <div className="field">
                        <label htmlFor="first_name">First Name</label>
                        <input className="text" type="text" name="first_name" id="first_name" placeholder="Enter Your First Name" required value={ this.state.first_name } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="last_name">Last Name</label>
                        <input className="text" type="text" name="last_name" id="last_name" placeholder="Enter Your Last Name" required value={ this.state.last_name } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="phone">Phone</label>
                        <input className="text" type="phone" name="phone" id="phone" placeholder="123-456-7890" required value={ this.state.phone } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input className="text" type="email" name="email" id="email" placeholder="example@domain.com" required value={ this.state.email } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password (Leave blank for no change)</label>
                        <input className="text" type="password" name="password" id="password" onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field">
                        <input className="bluebutton" type="submit" value="Update" />
                    </div>
                </form>

            </div>

        );
    }
}

export default NormalSettings;
