import React, { Component } from 'react';
import { Link } from "react-router-dom";

class WelcomeMain extends Component {

    state = {
        showPopup: true
    };

    removePopup(e) {
        e.preventDefault();
        this.setState({ showPopup: false });
    }

    render() {

        return (
            <div className="welcome page">

                <section className="hero">
                    <h1>Hand Selected Local &amp; Farm-Fresh Goods Delivered to Your Door</h1>
                    <h2>You don&rsquo;t have to choose between going to the farmer&rsquo;s market or having groceries delivered.</h2>
                    <div className="cta">
                        <Link className="bluebutton welcome-marketlink" to="/market">Start Shopping!</Link>
                    </div>
                </section>

                <section className="intro">

                    <div className="blurbs">
                        <div className="blurb">
                            <img className="blurbicon" src="/img/welcome-van.svg" alt="Grocery Delivery" />
                            <h3>Easy to order delivery every Sunday and Monday</h3>
                            <h4>Save time with online ordering and have your goods delivered straight to your door.</h4>
                        </div>

                        <div className="blurb">
                            <img className="blurbicon" src="/img/welcome-produce.svg" alt="Fresh Produce" />
                            <h3>Clean, Fresh Products</h3>
                            <h4>Each product has a story telling you where it came from, giving you confidence in how it was grown.</h4>
                        </div>

                        <div className="blurb">
                            <img className="blurbicon" src="/img/welcome-marker.svg" alt="Local Farmers and Ranchers" />
                            <h3>Support Local Farmers and Ranchers</h3>
                            <h4>We source within our community to bring you the freshest products from their farms to your kitchen.</h4>
                        </div>
                    </div>

                    <div className="socialicons">
                        <div className="snacksharelogo">
                            <img src="/img/snackshare-black-square.svg" alt="SnackShare" />
                        </div>
                        <a href="https://www.facebook.com/eatSnackShare" target="_blank" rel="noopener noreferrer"><img src="/img/Facebook-01.svg" alt="SnackShare Facebook" /></a>
                        <a href="https://www.instagram.com/eatsnackshare/" target="_blank" rel="noopener noreferrer"><img src="/img/Instagram-01.svg" alt="SnackShare Instagram" /></a>
                    </div>

                </section>

                { this.state.showPopup && (
                    <div className="blanket" onClick={ this.removePopup.bind(this) }>
                        <div className="popup">
                            <p>Sorry, we're unable to accommodate more deliveries at this time. We apologize for the inconvenience.</p>
                            <a href="/" className="bluebutton">OK, thanks!</a>
                        </div>
                    </div>
                )}

            </div>
        );
    }
}

export default WelcomeMain;
