import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AccountReset extends Component {

    state = {
        email: '',
        sending: false
    };

    render() {
        let self = this;

        function handleSubmit(e) {
            e.preventDefault();
            if ( self.state.sending ) return;
            self.setState({ sending: true });

            window.ss.post( '/reset', {
                email: self.state.email
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                    self.setState({ sending: false });
                } else {
                    alert("Thanks! We'll send you an email with a link to reset your password.");
                    self.props.history.push( '/account/login' );
                }
            });
        }

        return (
            <div className="page form">

                <h3 className="title">Reset Password</h3>

                <form onSubmit={ handleSubmit }>

                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input className="text" type="email" name="email" id="email" placeholder="example@domain.com" required onChange={ e => this.setState({ email: e.target.value }) } />
                    </div>
                    <div className="field">
                        <input className="bluebutton" type="submit" value="Send Password Reset Request" />
                    </div>
                </form>

                <Link className="lightbutton" to="/account/login">&laquo; Cancel and return to login form.</Link>

            </div>
        );
    }
}

export default AccountReset;
