import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderList from './OrderList';

class OrderListings extends Component {

    state = {
        orders: {}
    };

    componentWillMount() {
        this.loadOrders();
    }

    loadOrders() {
        let self = this;
        let order_type = this.props.match.params.order_type;
        let url = '/orders?per_page=100&status=' + order_type;
        if ( order_type === 'delivered' || order_type === 'prior' ) {
            url += '&sort=recent';
        }
        if ( window.ss.me.is_admin ) {
            url += '&user=all';
        }
        window.ss.get( url ).then( response => {
            self.setState({ orders: response });
        });
    }

    goBack(e) {
        if ( window.history.length ) {
            e.preventDefault();
            window.history.back();
        } else {
            return true;
        }
    }

    render() {
        return (
            <div className="page orders">
                <Link to="/home" className="backButton" onClick={ this.goBack.bind( this ) }><img alt="Back" src="/img/arrow-circle-left.svg" /></Link>

                { ( this.props.match.params.order_type === 'pending' || this.props.match.params.order_type === 'delivered' ) && (
                    <a href={ window.ss.api + '/orders/' + this.props.match.params.order_type + '.csv' } className="spreadsheetButton"><img alt="Spreadsheet" src="/img/spreadsheet.svg" /></a>
                )}

                { this.state.orders.total > 0 && (
                    <OrderList groupDate="true" list_type={ this.props.match.params.order_type } orders={ this.state.orders } />
                )}
            </div>
        );
    }
}

export default OrderListings;
