import React, { Component } from 'react';

class ProductAdd extends Component {

    state = {
        name:               '',
        description:        '',
        unit_name:          '',
        cost:               '',
        wholesale_cost:     '',
        pound_cost:         '',
        wholesale_pound_cost: '',
        pound_avg:          '',
        position:           '',
        inventory:          '',
        warehouse_section:  '',
        shelf:              '',
        is_published:       false,
        is_unlimited:       false,
        is_weight_based:    false,
        is_instant_buy:     false,
        is_vendor_payable:  false,
        is_local:           false,
        vendor:             '',
        productTypeIdent:   'produce',
        tree: {},
        vendors: [],
        loading: true
    };

    async componentWillMount() {
        let tree = await window.ss.get( '/product_types/tree' );
        let vendors = await window.ss.get( '/vendors?per_page=500' );
        this.setState({
            loading: false,
            vendors: vendors.results,
            tree: tree
        });
    }

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleProductTypeChange(e) {
        this.setState({ productTypeIdent: e.target.value });
    }

    handleCheckboxChange(e) {
        let obj = {};
        obj[ e.target.name ] = ( e.target.value === '1' ? false : true );
        this.setState( obj );
    }

    handleSubmit(e) {
        e.preventDefault();
        let self = this;
        window.ss.post( '/products', {
            name: self.state.name,
            vendor: self.state.vendor,
            description: self.state.description,
            unit_name: self.state.unit_name,
            cost: self.state.cost,
            wholesale_cost: self.state.wholesale_cost,
            pound_cost: self.state.pound_cost,
            wholesale_pound_cost: self.state.wholesale_pound_cost,
            pound_avg: self.state.pound_avg,
            position: self.state.position,
            inventory: self.state.inventory,
            warehouse_section: self.state.warehouse_section,
            shelf: self.state.shelf,
            is_published: self.state.is_published,
            is_unlimited: self.state.is_unlimited,
            is_vendor_payable: self.state.is_vendor_payable,
            is_weight_based: self.state.is_weight_based,
            is_local: self.state.is_local,
            product_type: self.state.productTypeIdent
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.ident ) {
                self.props.history.push('/home/products/' + json.ident + '/edit');
            }
        });
    }

    render() {
        let self = this;

        function getWarehouseSections() {
            let str = '<option value=""' + ( !self.state.warehouse_section ? ' selected' : '' ) + '>Select Warehouse Section</option>';
            window.ss.warehouseSections.forEach( ws => {
                str += '<option value="' + ws + '"' + ( self.state.warehouse_section === ws ? ' selected' : '' ) + '>' + window.ss.toTitleCase( ws ) +'</option>';
            });
            return (
                <select id="warehouse_section" name="warehouse_section" onChange={ self.handleChange.bind(self) } dangerouslySetInnerHTML={{ __html: str }}></select>
            );
        }

        function getVendors() {
            let str = '';
            self.state.vendors.forEach( vendor => {
                str += '<option value="' + vendor.id + '"' + ( self.state.vendor === vendor.id ? ' selected' : '' ) + '>' + vendor.name +'</option>';
            });
            return (
                <select id="vendor" name="vendor" onChange={ self.handleChange.bind(self) } dangerouslySetInnerHTML={{ __html: str }}></select>
            );
        }

        function getProductTypes() {
            if ( !self.state.tree || !self.state.tree.product_types || self.state.tree.product_types.length === 0 ) {
                return '';
            }
            let str = '';
            self.state.tree.product_types.forEach( pt => {
                str += '<option value="' + pt.ident + '"' + ( self.state.productTypeIdent === pt.ident ? ' selected' : '' ) + '>' + pt.name +'</option>';
                if ( pt.children ) {
                    pt.children.forEach( child => {
                        str += '<option value="' + child.ident + '"' + ( self.state.productTypeIdent === child.ident ? ' selected' : '' ) + '>&nbsp;&nbsp;&nbsp;&nbsp;' + child.name +'</option>';
                    });
                }
            });
            return (
                <select id="product_type" name="product_type" onChange={ self.handleProductTypeChange.bind(self) } dangerouslySetInnerHTML={{ __html: str }}></select>
            );
        }

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="page form">

                    <h3 className="title">Add New Product</h3>

                    <form onSubmit={ this.handleSubmit.bind(this) }>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <input className="text" type="text" name="name" id="name" required value={ this.state.name } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field field-large">
                            <label htmlFor="description">Description</label>
                            <textarea name="description" id="description" onChange={ this.handleChange.bind(this) } value={ this.state.description } />
                        </div>
                        <div className="field">
                          <label htmlFor="product_type">Vendor</label>
                          { getVendors() }
                        </div>
                        <div className="field">
                            <label htmlFor="unit_name">Unit Name</label>
                            <input className="text" type="text" name="unit_name" id="unit_name" required value={ this.state.unit_name } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field">
                          <label htmlFor="product_type">Category</label>
                          { getProductTypes() }
                        </div>
                        <div className="field">
                            <label htmlFor="position">Position</label>
                            <input className="text" type="text" name="position" id="position" required value={ this.state.position } onChange={ this.handleChange.bind(this) } />
                        </div>
                        <div className="field">
                            <label htmlFor="warehouse_section">Warehouse Section</label>
                            { getWarehouseSections() }
                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_weight_based"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.is_weight_based ? 'checked' : '' }
                                    value={ this.state.is_weight_based ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Weight Based?
                            </label>

                            { !this.state.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="cost">Cost</label>
                                    <input className="text" name="cost" id="cost" value={ this.state.cost } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { !this.state.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="wholesale_cost">Wholesale Cost</label>
                                    <input className="text" name="wholesale_cost" id="wholesale_cost" value={ this.state.wholesale_cost } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { this.state.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="pound_cost">Cost per Pound</label>
                                    <input className="text" name="pound_cost" id="pound_cost" value={ this.state.pound_cost ? this.state.pound_cost : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { this.state.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="wholesale_pound_cost">Wholesale Cost per Pound</label>
                                    <input className="text" name="wholesale_pound_cost" id="wholesale_pound_cost" value={ this.state.wholesale_pound_cost ? this.state.wholesale_pound_cost : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                            { this.state.is_weight_based && (
                                <div className="subfield">
                                    <label htmlFor="pound_avg">Average Pounds</label>
                                    <input className="text" name="pound_avg" id="pound_avg" value={ this.state.pound_avg ? this.state.pound_avg : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}

                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_unlimited"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.is_unlimited ? 'checked' : '' }
                                    value={ this.state.is_unlimited ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Unlimited?
                            </label>
                            { !this.state.is_unlimited && (
                                <div className="subfield">
                                    <label htmlFor="inventory">Inventory</label>
                                    <input className="text" type="number" name="inventory" id="inventory" value={ this.state.inventory ? this.state.inventory : '' } onChange={ this.handleChange.bind(this) } />
                                </div>
                            )}
                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_published"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.is_published ? 'checked' : '' }
                                    value={ this.state.is_published ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Published?
                            </label>
                        </div>

                        <div className="field">
                            <label>
                                <input
                                    name="is_vendor_payable"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={ this.state.is_vendor_payable ? 'checked' : '' }
                                    value={ this.state.is_vendor_payable ? '1' : '0' }
                                    onChange={ this.handleCheckboxChange.bind(this) }
                                />
                                Payable to Vendor?
                                <i className="field-reminder">(Make sure to update Wholesale Cost)</i>
                            </label>
                        </div>

                        <div className="field">
                            <input id="pagesubmit" type="submit" value="Create" />
                        </div>
                    </form>

                </div>
            );
        }
    }
}

export default ProductAdd;
