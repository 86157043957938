import React from "react";
import { Link } from "react-router-dom";

class ProductTable extends React.Component {

    state = {
        response: {},
        loading: true
    };

    async componentWillMount() {
        let response = await window.ss.get( '/products?include=full&product_type=' + this.props.productType.ident + '&per_page=1000' );
        this.setState({
            loading: false,
            response: response
        });
    }

    render() {
        return (
            <div>
                <h3 className="admin-table-title">{ this.props.productType.name }</h3>

                { this.state.loading && (
                    <div className="loading">Loading...</div>
                )}

                { !this.state.loading && (
                    <table>
                        <thead>
                            <tr>
                                <th>Pos.</th>
                                <th></th>
                                <th>Name</th>
                                <th>Published</th>
                                <th>Warehouse</th>
                                <th>Inventory</th>
                                <th>Cost</th>
                                <th>Wholesale</th>
                                <th>Payable</th>
                                <th>Vendor</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.response.results.map( ( product, i ) =>
                                <tr key={i} className={ ( i % 2 === 1 ? 'odd' : 'even' ) + ( product.is_vendor_payable ? ' vendor-payable' : '' ) }>
                                    <td className="admin-position">{ product.is_published ? product.position : '' }</td>
                                    <td>
                                        <Link key={i} className="admin-thumb" to={ '/home/products/' + product.ident + '/edit' }>
                                            <img src={ product.assets[0].wide_url } alt={ product.name } />
                                        </Link>
                                    </td>
                                    <td>
                                        <Link key={i} to={ '/home/products/' + product.ident + '/edit' }>
                                            <b className={ product.is_published ? 'admin-product-name' : 'admin-product-name unpublished' }>{ product.name }</b>
                                            <i className={ product.is_published ? 'admin-product-unit' : 'admin-product-unit unpublished' }>{ product.unit_name }</i>
                                        </Link>
                                    </td>
                                    <td>
                                        { product.is_published ? 'Published' : '' }
                                    </td>
                                    <td>
                                        { window.ss.toTitleCase( product.warehouse_section ) }
                                        { product.warehouse_section && product.shelf && (
                                            <br />
                                        )}
                                        { product.shelf }
                                    </td>
                                    <td>
                                        { product.is_unlimited && (
                                            <span className="admin-infinite">∞</span>
                                        )}
                                        { !product.is_unlimited && (
                                            <span className="admin-inventory">{ product.inventory }</span>
                                        )}
                                    </td>
                                    <td>
                                        { product.is_weight_based && (
                                            <span>${ window.ss.formatCost( product.pound_cost ) }/lb.<br />({ product.pound_avg } lb. avg)</span>
                                        )}
                                        { !product.is_weight_based && (
                                            <span>${ window.ss.formatCost( product.cost ) }</span>
                                        )}
                                    </td>
                                    <td>
                                        { product.is_weight_based && product.is_vendor_payable && !product.wholesale_pound_cost && (
                                            <span className="vendor-missing">Missing</span>
                                        )}
                                        { product.is_weight_based && !!( !product.is_vendor_payable || product.wholesale_pound_cost ) && (
                                            <span>${ window.ss.formatCost( product.wholesale_pound_cost ) }/lb.</span>
                                        )}

                                        { !product.is_weight_based && product.is_vendor_payable && !product.wholesale_cost && (
                                            <span className="vendor-missing">Missing</span>
                                        )}
                                        { !product.is_weight_based && !!( !product.is_vendor_payable || product.wholesale_cost ) && (
                                            <span>${ window.ss.formatCost( product.wholesale_cost ) }</span>
                                        )}
                                    </td>
                                    <td className="vendor-highlight">
                                        { product.is_vendor_payable ? 'Payable' : '' }
                                    </td>
                                    <td className="vendor-highlight">
                                        { product.vendor.name }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}

export default ProductTable;
