import React, { Component } from 'react';
import AdminHome from './admin/AdminHome';
import VendorHome from './vendor/VendorHome';
import NormalHome from './normal/NormalHome';

class Home extends Component {

    componentWillMount() {
        if ( !window.ss.me.id ) {
            window.ss.saveReturn( window.location.pathname );
            return this.props.history.push('/account/register');
        }
    }

    render() {
        let home_tab = this.props.match.params.home_tab;
        if ( !home_tab ) home_tab = 'dashboard';
        if ( window.ss.me.is_admin ) return <AdminHome home_tab={ home_tab } />;
        if ( window.ss.me.is_vendor ) return <VendorHome home_tab={ home_tab } />;
        return <NormalHome home_tab={ home_tab } />;
    }
}

export default Home;
