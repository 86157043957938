import React, { Component } from 'react';

class ProductUpload extends Component {

    state = {
        loading: true,
        product: null,
        uploading: false,
        returnTo: ''
    };

    async componentWillMount() {
        const { ident } = this.props.match.params;
        let product = await window.ss.get( '/products/' + ident + '?include=full' );
        let url = window.location.protocol + '//' + window.location.hostname + ( window.location.port ? ':' + window.location.port : '' ) + '/home/products/' + product.ident + '/edit';
        this.setState({
            loading: false,
            product: product,
            returnTo: url
        });
    }

    handleSelectImage(e) {
        e.preventDefault();
        document.getElementById('fileselect').click();
    }

    onFileChange(e) {
        let file = e.currentTarget.files[0];
        if ( file ) {
            let img = document.createElement("img");
            img.file = file;
            let imagePreview = document.getElementById('imagepreview');
            while ( imagePreview.firstChild ) {
              imagePreview.removeChild( imagePreview.lastChild );
            }
            imagePreview.appendChild( img );
            // Using FileReader to display the image content
            var reader = new FileReader();
            reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(img);
            reader.readAsDataURL( file );
            document.getElementById('pagesubmit').className = '';
        } else {
            document.getElementById('pagesubmit').className = 'hideme';
        }
    };

    handleSubmit(e) {
        if ( this.state.uploading ) {
            e.preventDefault();
            return false;
        } else {
            this.setState({ uploading: true });
        }
    }

    render() {
        let self = this;

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="page form">
                    <h3 className="title">{ this.state.product.name }</h3>

                    <form onSubmit={ this.handleSubmit.bind(this) } action={ window.ss.api + '/assets/products/' + this.state.product.ident } method="post" encType="multipart/form-data">
                        <input type="hidden" name="return_to" value={ this.state.returnTo } />

                        <div className="field">
                            <div id="imagepreview"></div>
                            <a href="/" className="bluebutton" onClick={ this.handleSelectImage.bind(this) }>Select Image</a>
                            <input type="file" accept="image/*" name="file" id="fileselect" className="hideme" onChange={ this.onFileChange.bind(this) } />
                        </div>

                        <div className="field">
                            <input id="pagesubmit" type="submit" value={ self.state.uploading ? 'Uploading...' : 'Upload' } className="hideme" />
                        </div>
                    </form>

                </div>
            );
        }
    }
}

export default ProductUpload;
