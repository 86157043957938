import React, { Component } from 'react';
import ProductTable from './ProductTable';
import { Link } from "react-router-dom";

class AdminProducts extends Component {

    state = {
        productTypes: {},
        loading: true
    };

    async componentWillMount() {
        let productTypes = await window.ss.get( '/product_types?per_page=500' );
        this.setState({
            productTypes: productTypes,
            loading: false
        });
        document.title = 'Products | SnackShare';
    }

    render() {
        return (
            <div className="admin-list">
                <Link className="admin-add bluebutton" to="/home/products/new">+ Add</Link>
                <h3 className="title">Products</h3>

                { this.state.loading && (
                    <div className="loading">Loading...</div>
                )}

                { !this.state.loading && (
                    <div className="admin-producttype">
                        { this.state.productTypes.results.map( ( productType, i ) =>
                            <ProductTable key={ i } productType={ productType } />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default AdminProducts;
