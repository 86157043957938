import React, { Component } from 'react';

class OrderPayment extends Component {

    state = {
        publicKey: null,
        stripe: null,
        card: null,
        loading: true,
        paymentCards: [],
        name: 'My card from ' + window.ss.smallDateText( new Date() ),
        payment_card: this.props.payment_card,
        verifying: false
    };

    componentDidMount() {
        this.loadPublicKey();
    }

    loadPublicKey() {
        let self = this;
        window.ss.get( '/payment_cards/public_key' ).then( publicKey => {
            if ( publicKey ) {
                self.setState( publicKey );
                self.loadPaymentCards( true );
            }
        });
    }

    loadPaymentCards( loadForm ) {
        let self = this;
        window.ss.get( '/payment_cards' ).then( paymentCardResponse => {
            let stateObj = { paymentCards: paymentCardResponse.results, loading: false };

            if ( ( !self.state.payment_card || self.state.payment_card === 'payment_new' ) && paymentCardResponse.results.length > 0 ) {
                stateObj.payment_card = paymentCardResponse.results[0].id;
            } else if ( self.state.payment_card === 'payment_new' || paymentCardResponse.results.length === 0 ) {
                stateObj.payment_card = null
            }
            self.setState( stateObj, () => {
                self.fireSelected();
                if ( loadForm ) self.initPaymentForm();
            });
        });
    }

    initPaymentForm() {
        let self = this;
        let stripe = window.Stripe( this.state.publicKey );
        let elements = stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        let style = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        // Create an instance of the card Element.
        let card = elements.create('card', { style: style });

        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });
        self.setState({ card: card, stripe: stripe });
    }

    verifyCard(e) {
        e.preventDefault();
        let self = this;
        if ( self.state.verifying ) return;
        self.setState({ verifying: true });
        let link = e.target;
        link.innerHTML = 'Verifying...';
        // Fetch SetupIntent from server.
        window.ss.post( '/payment_cards/intent', {} ).then( json => {
            if ( json && json.client_secret ) {
                self.state.stripe.confirmCardSetup( json.client_secret,
                    {
                        payment_method: {
                            card: self.state.card,
                            billing_details: {
                                name: window.ss.me.name
                            }
                        }
                    }
                ).then( result => {
                    if ( result.error ) {
                        self.showError( result.error.message );
                    } else {
                        // Send the token to your server.
                        self.stripeTokenHandler( result.setupIntent.payment_method );
                    }
                });

            } else {
                self.showError( 'Error sending payment info.' );
            }
        });
    }

    // Submit the form with the token ID.
    stripeTokenHandler( paymentMethod ) {
        let self = this;
        window.ss.post( '/payment_cards', {
            name: self.state.name,
            stripe_payment: paymentMethod
        }).then( json => {
            if ( json && json.error ) {
                self.showError( json.error );
            } else {
                self.loadPaymentCards();
                self.enableVerifyButton();
            }
        });
    }

    goBack(e) {
        if ( window.history.length && window.history.length > 1 ) {
            e.preventDefault();
            window.history.back();
        }
    }

    showError( msg ) {
        let errorElement = document.getElementById('card-errors');
        errorElement.textContent = msg;
        this.enableVerifyButton();
    }

    enableVerifyButton() {
        this.setState({ verifying: false });
        let link = document.getElementById('verifybutton');
        link.innerHTML = 'Verify and Save Card';
    }

    handleCardSelect(e) {
        this.setState( { payment_card: e.target.value }, this.fireSelected );
    }

    fireSelected() {
        if ( this.state.payment_card && this.state.payment_card !== 'payment_new' ) {
            this.props.onSelect( this.state.payment_card );
        } else {
            this.props.onSelect( '' );
        }
    }

    handlePaymentRemove(e) {
        let self = this;
        e.preventDefault();
        let link = e.currentTarget;
        let paymentId = link.getAttribute('data-remove');
        if ( window.ss.is_open_order && ( window.ss.me.order.payment_card.id === paymentId || self.state.payment_card === paymentId ) ) {
            return true;
        }
        if ( window.confirm('Are you sure you wish to remove this card?') ) {
            window.ss.delete( '/payment_cards/' + paymentId, {}).then( json => {
                if ( json.error ) {
                    alert( json.error );
                } else {
                    self.loadPaymentCards();
                }
            });
        }
    }

    render() {
        let self = this;

        if ( self.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
                <div className="payment">

                    { self.state.paymentCards.length > 0 && (
                        <div id="payment-saved-cards" className="payment-list">
                            { self.state.paymentCards.map( ( paymentCard, i ) =>
                                <label key={i} className={ self.state.payment_card === paymentCard.id ? 'payment-item payment-item-selected' : 'payment-item' }>
                                    <input checked={ self.state.payment_card === paymentCard.id } type="radio" name="paymentselection" id={ 'payment_' + paymentCard.id } value={ paymentCard.id } onChange={ self.handleCardSelect.bind( self ) } />
                                    { ( !window.ss.is_open_order || ( window.ss.me.order.payment_card.id !== paymentCard.id && self.state.payment_card !== paymentCard.id ) ) && (
                                        <a className="payment-item-remove" data-remove={ paymentCard.id } onClick={ self.handlePaymentRemove.bind( self ) } href="/"><img src="/img/trash-can.svg" alt="Remove" /></a>
                                    )}
                                    <span className="payment-item-info">
                                        <b>{ paymentCard.name }</b>
                                        { paymentCard.last_used_int && (
                                            <i>Last { paymentCard.last_used_status } { window.ss.dateTime( paymentCard.last_used_int ) }</i>
                                        )}
                                    </span>
                                </label>
                            )}
                            <label id="paymentnewcard" className={ self.state.payment_card === 'payment_new' ? 'payment-item payment-item-selected' : 'payment-item' }>
                                <input type="radio" name="paymentselection" id={ 'payment_new' } value="payment_new" onChange={ self.handleCardSelect.bind( self ) } />
                                <span className="payment-item-info">
                                    <b>Add New Card...</b>
                                </span>
                            </label>
                        </div>
                    )}

                    <div id="payment-new-form" className={ self.state.paymentCards.length > 0 && self.state.payment_card !== 'payment_new' ? 'form hideme' : ( self.state.paymentCards.length > 0 ? 'form showingform' : 'form' ) }>
                        <div className="field">
                            <label htmlFor="name">Card Description (for later use)</label>
                            <input className="text" type="text" name="name" id="name" required value={ self.state.name } onChange={ e => this.setState({ name: e.target.value }) } />
                        </div>

                        <div className="field">
                            <label>Card Information</label>
                            <div id="card-element"></div>
                        </div>

                        <div className="payment-disclaimer">
                            <p>Your payment is securely processed by Stripe. SnackShare does not store your credit/debit card information. Your card will be charged on the day of delivery.</p>
                            <p>By submitting, you authorize SnackShare to send instructions to the financial institution that issued your card to take payments from your card account.</p>
                        </div>

                        <a href="/" className="bluebutton" id="verifybutton" onClick={ self.verifyCard.bind( this ) }>Verify and Save Card</a>
                        <div id="card-errors" role="alert"></div>
                    </div>
                </div>
            );
        }
    }
}

export default OrderPayment;
