import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ItemList from './ItemList';

class ItemListings extends Component {

    state = {
        items: {},
        item_type: this.props.match.params.item_type,
        groupDate: ( this.props.match.params.item_type === 'confirmed' )
    };

    componentWillMount() {
        this.loadOrders();
    }

    loadOrders() {
        let self = this;
        let url = '/items?per_page=500';
        let status = ( this.state.item_type === 'delivered' ? 'staged|delivered' : this.state.item_type );
        url += '&status=' + status;
        if ( window.ss.me.is_admin ) {
            url += '&user=all';
        }
        window.ss.get( url ).then( response => {
            self.setState({ items: response });
        });
    }

    render() {
        return (
            <div className="page items">
                <Link to="/home" className="backButton"><img alt="Back" src="/img/arrow-circle-left.svg" /></Link>
                { this.state.items.total > 0 && (
                    <ItemList list_type={ this.state.item_type } groupDate={ this.state.groupDate } limit="500" items={ this.state.items } />
                )}
                { this.state.items.total === 0 && (
                    <div className="cart-empty">
                      <p>No items here.</p>
                    </div>
                )}
            </div>
        );
    }
}

export default ItemListings;
