import React, { Component } from 'react';
import { Link } from "react-router-dom";
import VendorDashboard from './VendorDashboard';
import VendorOrders from './VendorOrders';
import VendorSettings from './VendorSettings';
import VendorStripeCallback from './VendorStripeCallback';

class VendorHome extends Component {

    render() {
        return (
            <div className="page home">

                <div className="home-top">
                    <div className="home-name">
                        { window.ss.me.vendor.name }
                    </div>
                    { window.ss.me.balance && (
                        <div className="home-giftbalance">
                            ${ window.ss.formatCost( window.ss.me.balance ) } Pending Payout
                        </div>
                    )}
                    <ul className="home-tabs home-tabs3">
                        <li><Link to="/home" className={ this.props.home_tab === 'dashboard' ? 'selected' : '' }>Dashboard</Link></li>
                        <li><Link to="/home/orders" className={ this.props.home_tab === 'orders' ? 'selected' : '' }>History</Link></li>
                        <li><Link to="/home/settings" className={ this.props.home_tab === 'settings' ? 'selected' : '' }>Settings</Link></li>
                    </ul>
                </div>

                { this.props.home_tab === 'dashboard' && (
                    <VendorDashboard />
                )}

                { this.props.home_tab === 'orders' && (
                    <VendorOrders />
                )}

                { this.props.home_tab === 'settings' && (
                    <VendorSettings />
                )}

                { this.props.home_tab === 'stripe_callback' && (
                    <VendorStripeCallback />
                )}

            </div>
        );
    }
}

export default VendorHome;
