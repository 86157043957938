import React, { Component } from 'react';

class CategoryEdit extends Component {

    render() {
        return (
            <div className="welcome-hero">
                <h1 className="account-head">CategoryEdit</h1>
            </div>
        );
    }
}

export default CategoryEdit;
