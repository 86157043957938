import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AccountLogin extends Component {

    state = {
        email: '',
        password: ''
    };

    render() {
        let self = this;

        function handleSubmit(e) {
            e.preventDefault();

            window.ss.post( '/users/login', {
                email: self.state.email,
                password: self.state.password
            }).then( json => {
                if ( json && json.error ) {
                    alert( json.error );
                } else if ( json && json.id ) {
                    window.ss.applyMe( json );
                    if ( window.ss.returnTo ) {
                        self.props.history.push( window.ss.returnTo );
                        window.ss.clearReturn();
                    } else {
                        self.props.history.push( '/home' );
                    }
                } else {
                    alert('Incorrect email or password.');
                }
            });
        }

        return (
            <div className="page form">

                <h3 className="title">Log In</h3>

                <form onSubmit={ handleSubmit }>

                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input className="text" type="email" name="email" id="email" placeholder="example@domain.com" required onChange={ e => this.setState({ email: e.target.value }) } />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <input className="text" type="password" name="password" id="password" required onChange={ e => this.setState({ password: e.target.value }) } />
                    </div>
                    <div className="field">
                        <input className="bluebutton" type="submit" value="Log In" />
                    </div>
                </form>

                <Link className="lightbutton" to="/account/register">I need to create an account.</Link>

                <Link className="lightbutton" to="/account/reset">I forgot my password.</Link>

            </div>
        );
    }
}

export default AccountLogin;
