import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AdminDashboard from './AdminDashboard';
import AdminOrders from './AdminOrders';
import AdminItemGrid from './AdminItemGrid';
import AdminProducts from './AdminProducts';
import AdminVendors from './AdminVendors';
import AdminSettings from './AdminSettings';

class AdminHome extends Component {

    render() {
        return (
            <div className="page home home-admin">

                <div className="home-top">
                    <div className="home-name">
                        { window.ss.me.name }
                    </div>
                    <ul className="home-tabs home-tabs3">
                        <li><Link to="/home" className={ this.props.home_tab === 'dashboard' ? 'selected' : '' }>Dashboard</Link></li>
                        <li><Link to="/home/orders" className={ this.props.home_tab === 'orders' ? 'selected' : '' }>Orders</Link></li>
                        <li><Link to="/home/item_grid" className={ this.props.home_tab === 'item_grid' ? 'selected' : '' }>Pending</Link></li>
                        <li><Link to="/home/settings" className={ this.props.home_tab === 'settings' ? 'selected' : '' }>Settings</Link></li>
                        <li><Link to="/home/products" className={ this.props.home_tab === 'products' ? 'selected' : '' }>Products</Link></li>
                        <li><Link to="/home/vendors" className={ this.props.home_tab === 'vendors' ? 'selected' : '' }>Vendors</Link></li>
                    </ul>
                </div>

                { this.props.home_tab === 'dashboard' && (
                    <AdminDashboard />
                )}

                { this.props.home_tab === 'orders' && (
                    <AdminOrders />
                )}

                { this.props.home_tab === 'item_grid' && (
                    <AdminItemGrid />
                )}

                { this.props.home_tab === 'products' && (
                    <AdminProducts />
                )}

                { this.props.home_tab === 'vendors' && (
                    <AdminVendors />
                )}

                { this.props.home_tab === 'settings' && (
                    <AdminSettings />
                )}

            </div>
        );
    }
}

export default AdminHome;
