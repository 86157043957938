import React, { Component } from 'react';
import { Link } from "react-router-dom";

class CsaAgreementNewLeaf extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    goBack(e) {
        if ( window.history.length && window.history.length > 1 ) {
            e.preventDefault();
            window.history.back();
        }
    }

    render() {
        return (
            <div className="page terms">

                <Link to="/cart/confirm" className="backButton" onClick={ this.goBack.bind( this ) }><img alt="Back" src="/img/arrow-circle-left.svg" /></Link>

                <h3 className="title">CSA Agreement - New Leaf</h3>
                <div className="terms-updated">Updated March 17, 2020</div>

                <p>I appreciate local, sustainably grown produce and want to ensure that it remains available by participating in the New Leaf Agriculture Community Supported Agriculture (CSA) Program. I understand that the New Leaf Staff pledge to do their best to provide the vegetables they propose at the times they estimate. However, as a shareholder in both the bounty and the risk, I understand that nature ultimately decides what I will receive and when I will receive it. I have read the refund policy below and understand there are no refunds after the CSA begins.</p>

                <h4>REFUND POLICY</h4>

                <p>We can not offer refunds for shares. In the unlikely event of dissolution of the CSA by the operators, refunds would be issued on a pro rata basis within 60 days of date of dissolution.</p>

                <h4>MISSED PICKUPS</h4>

                <p>We harvest much of your share the day before your designated delivery day. If you plan on being out of town, you must provide notice one week in advance of your delivery (by Sunday night) to “bump” your share to the following week. If you are a weekly member, you will receive two shares that week. If you do not provide one week notice, we will forfeit your share. Please e-mail newleafcsa@mrcaustin.org as well as support@snackshare.com with any requests to bump your share.</p>

            </div>
        );
    }
}

export default CsaAgreementNewLeaf;
