import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ItemRow from './ItemRow';

class ItemList extends Component {

    state = {
        dates: []
    };

    getTitle() {
        let titleStr = 'Items';
        let path = '';
        if ( this.props.list_type === 'unconfirmed' ) {
            titleStr = ''+this.props.items.total + ' Need Confirmation';
            path = '/items/unconfirmed';
        } else if ( this.props.list_type === 'confirmed' ) {
            titleStr = ''+this.props.items.total + ' Due to Warehouse';
            path = '/items/confirmed';
        } else if ( this.props.list_type === 'delivered' ) {
            titleStr = ''+this.props.items.total + ' Delivered Item' + ( this.props.items.total === 1 ? '' : 's' );
            path = '/items/delivered';
        } else if ( this.props.list_type === 'cancelled' ) {
            titleStr = 'Cancelled Item' + ( this.props.items.total === 1 ? '' : 's' );
            path = '/items/cancelled';
        } else if ( this.props.list_type === 'open' ) {
            titleStr = 'Current Order';
            path = '/items/open';
        }
        return (
            <h3 className="title">
                { titleStr }
                { this.props.limit && this.props.limit < this.props.items.total && (
                    <Link to={ path } className="forwardButton"><img alt="See All" src="/img/arrow-circle-right.svg" /></Link>
                )}
            </h3>
        );
    }

    componentWillMount() {
        if ( this.props.groupDate ) {
            this.groupByDate();
        }
/*
        if ( this.props.groupWarehouseSection ) {
            this.groupByWarehouseSection();
        }
*/
    }

    groupByDate() {
        let dateObjects = {};
        this.props.items.results.forEach( item => {
            if ( !dateObjects[ item.staging_due_label ] ) {
                dateObjects[ item.staging_due_label ] = [];
            }
            dateObjects[ item.staging_due_label ].push( item );
        });
        this.setState({ dates: dateObjects });
    }

/*
    groupByWarehouseSection() {
        let dateObjects = {};
        this.props.items.results.forEach( item => {
            if ( !dateObjects[ item.staging_due_label ] ) {
                dateObjects[ item.staging_due_label ] = [];
            }
            dateObjects[ item.staging_due_label ].push( item );
        });
        this.setState({ dates: dateObjects });
    }
*/

    render() {
        let self = this;
        return (
            <div className="home-orders-section">
                { self.getTitle() }

                { !self.props.groupDate && (
                    <div className="item-list">
                        { self.props.items.results.map( ( item, i ) =>
                            <ItemRow key={i} item={ item } onChange={ this.props.onChange } />
                        )}
                        { this.props.order && this.props.list_type !== 'cancelled' && this.props.order.subscription && this.props.order.subscription.product && (
                            <div className="item-row">
                                <div className="item-quantity">{ self.props.order.subscription.id === '5e7a50fcc9a6e13e06c7a968' ? 2 : 1 }</div>
                                <div className="item-info">
                                    <div className="item-name">{ this.props.order.subscription.product.name }</div>
                                    <div className="item-unit">{ this.props.order.subscription.product.unit_name }</div>
                                    <div className="item-vendor">{ this.props.order.subscription.product.vendor && this.props.order.subscription.product.vendor.name }</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                { self.props.groupDate && self.state.dates && (
                    <div className="item-grouped">
                        { Object.keys( self.state.dates ).map( ( key, i ) =>
                            <div key={i} className="item-date-section">

                                <div className="item-date"><span>{ key }</span></div>

                                <div className="item-list">
                                    { self.state.dates[ key ].map( ( item, j ) =>
                                        <ItemRow key={j} item={ item } onChange={ this.props.onChange } />
                                    )}
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default ItemList;
