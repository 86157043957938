import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderPayment from '../orders/OrderPayment';

class ConfirmPage extends Component {

    state = {
        order: {},
        loading: true,
        sending: false,
        showingPromo: false,
        showingGiftPopup: false,
        promoCode: '',
        giftCode: '',
        is_csa_agreed: ( window.ss.buyNow && window.ss.buyNow.is_csa_agreed )
    };

    componentWillMount() {
        if ( !window.ss.buyNow && window.ss.is_open_order ) {
            return this.props.history.push('/cart/order');
        }
        if ( window.ss.buyNow && !window.ss.buyNow.address ) {
            return this.props.history.push('/cart/address');
        }
        if ( !window.ss.buyNow && !window.ss.cart.address ) {
            return this.props.history.push('/cart/address');
        }
        if ( !window.ss.buyNow && !window.ss.cart.delivery_date ) {
            return this.props.history.push('/cart/delivery_date');
        }
        this.loadOrderSummary();
        document.title = 'Confirm | SnackShare';
    }

    loadOrderSummary() {
        let self = this;
        let cart = JSON.parse( JSON.stringify( window.ss.buyNow ? window.ss.buyNow : window.ss.cart ) );
        window.ss.post( '/orders/summary', cart ).then( order => {
            if ( order.items.length === 0 ) {
                return this.props.history.push('/cart');
            } else if ( !order.is_subscription && !order.delivery_date ) {
                return this.props.history.push('/cart/delivery_date');
            } else {
                let stateObj = { order, loading: false };
                if ( order.promo_code ) {
                    stateObj.showingPromo = true;
                    stateObj.promoCode = order.promo_code;
                } else {
                    stateObj.showingPromo = false;
                    stateObj.promoCode = '';
                }
                self.setState( stateObj );
            }
        });
    }

    disableSubmitButton() {
        let button = document.getElementById('pagesubmit');
        this.setState({ sending: true });
        button.className = 'disabled';
        button.innerHTML = 'Sending...';
    }

    enableSubmitButton() {
        let button = document.getElementById('pagesubmit');
        this.setState({ sending: false });
        button.className = '';
        button.innerHTML = 'Complete Purchase';
    }

    showPromoField(e) {
        e.preventDefault();
        this.setState({ showingPromo: true });
    }

    closePromoField(e) {
        e.preventDefault();
        this.setState({ showingPromo: false });
    }

    removePromo(e) {
        e.preventDefault();
        if ( window.ss.buyNow ) {
            window.ss.buyNow.promo_code = '';
        } else {
            window.ss.cartSet( 'promo_code', '' );
        }
        this.loadOrderSummary();
    }

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleCsaAgreement(e) {
        window.ss.buyNow.is_csa_agreed = !window.ss.buyNow.is_csa_agreed;
        this.setState({ is_csa_agreed: window.ss.buyNow.is_csa_agreed });
    }

    handlePromoSubmit(e) {
        e.preventDefault();
        let self = this;
        window.ss.post( '/promos/verify', {
            promo_code: self.state.promoCode
        }).then( json => {
            if ( !json ) {
                alert( 'Error validating promo code.' );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.success ) {
                if ( window.ss.buyNow ) {
                    window.ss.buyNow.promo_code = self.state.promoCode;
                } else {
                    window.ss.cartSet( 'promo_code', self.state.promoCode );
                }
                self.loadOrderSummary();
            }
        });
    }

    handlePaymentSelect( paymentCard ) {
        if ( window.ss.buyNow ) {
            window.ss.buyNow.payment_card = paymentCard;
        } else {
            window.ss.cartSet( 'payment_card', paymentCard );
        }
        this.loadOrderSummary();
    }

    handleFormSubmit( e ) {
        e.preventDefault();
        let self = this;
        if ( window.ss.buyNow && !self.state.is_csa_agreed ) {
            return alert( 'Please agree to the CSA Agreement.' );
        }
        if ( self.state.sending ) return;
        self.disableSubmitButton();
        let cart = JSON.parse( JSON.stringify( window.ss.buyNow ? window.ss.buyNow : window.ss.cart ) );
        window.ss.post( '/orders', cart ).then( json => {
            if ( json && json.error ) {
                alert( json.error );
                self.enableSubmitButton();
            } else {
                window.ss.buyNow = null;
                window.ss.cartEmpty();
                window.ss.get( '/users/me' ).then( json => {
                    window.ss.applyMe( json );
                });
                return self.props.history.push('/cart/success');
            }
        });
    }

    showGiftPopup(e) {
        e.preventDefault();
        this.setState({ showingGiftPopup: true });
    }

    removeGiftPopup(e) {
        e.preventDefault();
        this.setState({ showingGiftPopup: false });
    }

    submitGiftCard(e) {
        e.preventDefault();
        e.stopPropagation();
        let self = this;
        window.ss.post( '/gift_cards/claim', {
            code: self.state.giftCode
        }).then( json => {
            if ( !json ) {
                alert( 'Error validating code.' );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.id ) {
                window.ss.applyMe( json );
                this.loadOrderSummary();
                this.setState({ showingGiftPopup: false });
            }
        });
    }

    render() {

        let self = this;

        function getPageSubmitClassName() {
            let cart = JSON.parse( JSON.stringify( window.ss.buyNow ? window.ss.buyNow : window.ss.cart ) );
            if ( !cart.payment_card ) return 'hideme';
            return ( !self.state.order.is_subscription && !self.state.order.subscription && self.state.order.subtotal < 25 ? 'hideme' : '' );
        }

        function showCartError() {
            if ( self.state.order.is_subscription || self.state.order.subscription || self.state.order.subtotal >= 25 ) return '';
            return (
                <div className="cart-error">
                    You must have at least $25 worth of goods (before delivery fee) to continue.
                </div>
            );
        }

        if ( self.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            let order = self.state.order;

            return (
                <div className="page cart">
                    <h3 className="title">Checkout</h3>

                    <div className="cart-section-items">
                        { order.items.map( ( item, j ) =>
                            <div key={j} className="cart-item">
                                <div className="cart-item-info">
                                    <div className="cart-item-name">{ item.name }</div>
                                    <div className="cart-item-unit">{ item.unit_name }</div>
                                    <div className="cart-item-vendor">{ item.vendor.name }</div>
                                </div>
                                { !!item.cost && (
                                    <div className="cart-item-costs">
                                        { item.is_weight_based && (
                                            <div className="cart-item-unitcost">x <b>${ window.ss.formatCost( item.pound_cost * item.pound_avg ) }</b></div>
                                        )}
                                        { !item.is_weight_based && (
                                            <div className="cart-item-unitcost">x <b>${ window.ss.formatCost( item.cost ) }</b></div>
                                        )}
                                        { item.is_weight_based && (
                                            <div className="cart-item-subtotal">= <b>${ window.ss.formatCost( item.quantity *  item.pound_cost * item.pound_avg ) }</b></div>
                                        )}
                                        { !item.is_weight_based && (
                                            <div className="cart-item-subtotal">= <b>${ window.ss.formatCost( item.quantity * item.cost ) }</b></div>
                                        )}
                                    </div>
                                )}
                                { !!item.cost && (
                                    <div className="cart-item-quantity">
                                        <span className="cart-item-quantity-amount">{ item.quantity }</span>
                                    </div>
                                )}
                                { item.is_weight_based && (
                                    <div className="cart-item-weightnote">(price based on weight)</div>
                                )}
                            </div>
                        )}
                    </div>

                    <h3 className="title">Address</h3>

                    <div className="cart-address">
                        <Link className="cart-address-edit" to="/cart/address"><img src="/img/edit.svg" alt="Edit" /></Link>
                        <span className="address-item-info">
                        <span className="address-item-line">{ order.address.address1 }</span>
                        { order.address.address2 && (
                            <span className="address-item-line">{ order.address.address2 }</span>
                        )}
                        <span className="address-item-line">
                            { order.address.city }, { order.address.state } { order.address.zipcode }
                        </span>
                        <span className="address-item-line address-item-type">{ order.address.location_type }</span>
                        { order.address.notes && (
                            <span className="address-item-line address-item-notes">{ order.address.notes }</span>
                        )}
                        </span>
                    </div>

                    { !window.ss.buyNow && (
                        <h3 className="title">Delivery Date</h3>
                    )}

                    { !window.ss.buyNow && (
                        <div className="cart-summary cart-delivery_date">
                            { order.available_delivery_dates.length > 1 && (
                                <Link className="cart-address-edit" to="/cart/delivery_date"><img src="/img/edit.svg" alt="Remove" /></Link>
                            )}
                            { window.ss.fullDate( order.delivery_date ) } 3-8pm
                        </div>
                    )}

                    <h3 className="title">Summary</h3>

                    <div className="cart-summary">
                    <table><tbody>
                        { !!order.subtotal ? (
                            <tr className="cart-subtotal">
                                <td className="cart-summary-label">Subtotal</td>
                                <td className="cart-summary-amount">${ window.ss.formatCost( order.subtotal ) }</td>
                            </tr>
                        ) : null}
                        { !window.ss.buyNow ? (
                            <tr className="cart-delivery">
                                <td className="cart-summary-label">Delivery Fee</td>
                                <td className="cart-summary-amount">${ window.ss.formatCost( order.delivery_fee ) }</td>
                            </tr>
                        ) : null }
                        { self.state.showingPromo && !order.promo_code ? (
                            <tr className="cart-promo">
                                <td colSpan="2" className="cart-summary-label">
                                    <form onSubmit={ self.handlePromoSubmit.bind(self) }>
                                        <input className="text" type="text" placeholder="Enter code" name="promoCode" value={ self.state.promoCode } onChange={ self.handleChange.bind(self) } />
                                        <input className="bluebutton" type="submit" value="Apply" />
                                        <a href="/" onClick={ self.closePromoField.bind(self) }>Cancel</a>
                                    </form>
                                </td>
                            </tr>
                        ) : null}
                        { self.state.showingPromo && order.promo_code ? (
                            <tr className="cart-promo">
                                <td className="cart-summary-label">Promo: <b>{ order.promo_code.toUpperCase() }</b> <a href="/" className="remove" onClick={ self.removePromo.bind(self) }>X</a></td>
                                <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( order.promo_discount ) }</td>
                            </tr>
                        ) : null}
                        { ( order.subtotal + order.delivery_fee ) > 0 && !self.state.showingPromo ? (
                            <tr className="cart-promo">
                                <td colSpan="2" className="cart-summary-label">
                                    <a href="/" onClick={ self.showPromoField.bind(self) }>Promo Code</a>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="cart-promo">
                            <td colSpan="2" className="cart-summary-label">
                                <a href="/" onClick={ self.showGiftPopup.bind(self) }>Gift Card</a>
                            </td>
                        </tr>
                        { ( window.ss.me.gift_balance || order.gift_applied ) ? (
                            <tr className="cart-promo">
                                <td className="cart-summary-label">Gift Card <b>(${ window.ss.formatCost( window.ss.me.gift_balance ) } available)</b>:</td>
                                <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( order.gift_applied ) }</td>
                            </tr>
                        ) : null}
                        <tr className="cart-total">
                            <td className="cart-summary-label">Total</td>
                            <td className="cart-summary-amount">${ window.ss.formatCost( order.total ) }</td>
                        </tr>
                    </tbody></table>
                    </div>

                    { order.items[0] && window.ss.greenGateProducts().indexOf( order.items[0].ident ) > -1 && (
                        <div id="csa_agreement" className="cart-message">
                            <label>
                                <input type="checkbox" value="1" name="csaagree" checked={ self.state.is_csa_agreed ? 'checked' : '' } onChange={ self.handleCsaAgreement.bind(self) } />
                                I have read and agree to the<br /><Link to="/info/csa_agreement">CSA Agreement</Link>.
                            </label>
                        </div>
                    )}

                    { order.items[0] && window.ss.newLeafProducts().indexOf( order.items[0].ident ) > -1 && (
                        <div id="csa_agreement" className="cart-message">
                            <label>
                                <input type="checkbox" value="1" name="csaagree" checked={ self.state.is_csa_agreed ? 'checked' : '' } onChange={ self.handleCsaAgreement.bind(self) } />
                                I have read and agree to the<br /><Link to="/info/csa_agreement_newleaf">CSA Agreement</Link>.
                            </label>
                        </div>
                    )}

                    { showCartError() }

                    <h3 className="title">Payment</h3>

                    { !!( order.total === 0 && order.gift_applied ) && (
                        <div className="giftcardccexplain">
                            <p>Even though your gift card covers the order, we still need to record your credit card info.</p>
                            <p>You will be able to add more items to your order until <b>{ window.ss.fullDate( order.close_date ) }</b> at midnight, but your card will not be charged if you have enough balance to cover the full order.</p>
                        </div>
                    )}

                    <OrderPayment onSelect={ self.handlePaymentSelect.bind( self ) } payment_card={ order.payment_card ? order.payment_card.id : null } />

                    { !window.ss.buyNow && (
                        <div className="cart-message">
                            You can change your order through
                            <b>{ window.ss.fullDate( order.close_date ) }</b>
                            So come back to add more later!
                        </div>
                    )}

                    { window.ss.buyNow && (
                        <div className="cart-message">
                            { ( order.total > 0 ) && (
                                <div>
                                    Your card will be charged ${ window.ss.formatCost( order.total ) } today for the CSA, with your first delivery on
                                    { order.items[0] && [
                                        'newleaf-3-week-summer-extension'
                                    ].indexOf( order.items[0].ident ) > -1 && (
                                        <span> July 19th.</span>
                                    )}
                                </div>
                            )}
                            { order.items[0] && [
                                'greengatefarms-prepaid-spring-csa',
                                'newleaf-spring-csa-delivery'
                            ].indexOf( order.items[0].ident ) > -1 && (
                                <span>You have pre-paid for your CSA, so your card will not be charged today at checkout.</span>
                            )}
                            <br />A $6.99 fee will be added to your cart on Monday of each delivery week, at which point your cart will be open!
                        </div>
                    )}

                    <a href="/" id="pagesubmit" className={ getPageSubmitClassName() } onClick={ self.handleFormSubmit.bind(self) }>Complete Purchase</a>

                    { self.state.showingGiftPopup && (
                        <div className="blanket">
                            <div className="popup">
                                <div className="form">
                                    <div className="field">
                                        <label>Enter gift code here:</label>
                                        <input type="text" className="text" placeholder="eg: A1B2C3E4" onChange={ e => this.setState({ giftCode: e.target.value }) } />
                                        <a href="/" className="bluebutton giftsubmitbutton" onClick={ this.submitGiftCard.bind(this) }>Submit</a>
                                        <a href="/" className="giftcancelbutton" onClick={ this.removeGiftPopup.bind(this) }>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            );
        }
    }
}

export default ConfirmPage;
