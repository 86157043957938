import React, { Component } from 'react';

class Terms extends Component {

    render() {
        return (
            <div className="page terms">
                <h3 className="title">Terms of Use</h3>
                <div className="terms-updated">Updated November 21, 2019</div>

                <h4>Introduction</h4>

                <p>Welcome to SnackShare.com (the “Site”) and our online marketplace (the “Marketplace”) where we provide selling, and reservation services (the “Services”).  The Site and Marketplace is owned and operated by Tableshare, LLC, a United States based limited liability corporation (“SnackShare”, “we”, “us” or “our”).</p>

                <h4>Terms and Changes to Terms</h4>

                <p>SnackShare provides the Services to you subject to the following terms and conditions (the “User Agreement”).  Accessing, browsing, posting or transacting using SnackShare.com indicates that you accept these terms and conditions.  Please read these terms and conditions carefully before proceeding.</p>

                <p>SnackShare reserves the right to change any of the terms and conditions in the User Agreement, including all Policies and Guidelines referenced therein, at any time and in its sole discretion.  Changes to the User Agreement, including all Policies and Guidelines referenced therein, will be posted on the Site and may be done without individual notice to you.</p>

                <p>Your continued use of the Site and Services following SnackShare posting of any changes to the User Agreement, including all Policies and Guidelines referenced therein, will constitute your acceptance of such changes.  If you do not agree to any changes, then you should not continue to use the Site and Services.</p>

                <h4>SnackShare’s Role and Services</h4>

                <p>SnackShare acts as a venue to allow producers who comply with their respective state’s Cottage Food Laws, and buyers who comply with SnackShare’s policies to list, offer, reserve, and buy certain food and related products (the “products”) within a fixed price format.  SnackShare creates and maintains the Marketplace for registered sellers (“Producers”) to list and offer products to registered users (“Users”, “you” or “your”). SnackShare does not make any of the products offered for reservation on the Site. As a result, SnackShare has no control over: the quality or safety of any product listed; the truth or accuracy of the product listings; and the ability of Producers to sell products.</p>

                <p>You agree that SnackShare is a venue.  As such, SnackShare is not responsible or liable to you, or any other party, for any: products or their descriptive content, for example, data, text, information, usernames, graphics, images, photographs, profiles, audio, video, and links posted on the Site; deficiencies in the product you receive; issues or disputes you may have with a Producer; order cancellations; and product return.</p>

                <p>SnackShare is not responsible for ensuring that Producers are, or remain, legally qualified to sell their products.  You use the Site and Services at your own risk.</p>

                <h4>Privacy</h4>

                <p>Please see our Privacy Policy to understand our practices.</p>

                <h4>Using SnackShare</h4>

                <p>The Site is available only to and may only be used by Users who are 18 years and older who can form legally binding contracts under applicable law.  Users under the age of 18 can use the Site only in conjunction with and under the supervision of a parent or legal guardian (the “adult”).  In this case, the adult is the User and is responsible for any and all activities.  You represent and warrant that you are at least 18 years old or visiting the Site with permission by and under the supervision of an adult.</p>

                <p>SnackShare may assign you a customer profile and password (“account”) to enable you to use and access certain portions of this Site.  You agree that you will provide us with accurate account information (such as your lawful name, valid e-mail address, home and delivery addresses) and that you will update your account information once it becomes outdated.  We may terminate your account if we are unable to verify or authenticate the information you provide to us.  You are solely responsible for maintaining the confidentiality of your account and for restricting access to it.  You further agree to:</p>

                <ul>
                    <li>keep your account password confidential and secured;</li>
                    <li>change your account password as soon as possible if you are suspicious that your password may have been stolen or lost;</li>
                    <li>accept full responsibility for all activities that occur within your account or within the SnackShare community; and</li>
                    <li>notify SnackShare immediately if you believe your account has been used without your authorization.</li>
                </ul>

                <p>You will be solely responsible for activities by anyone using the customer profile or password assigned to you, including without limitation, all communications and transmissions and all financial obligations incurred through the use of our Site. SnackShare reserves the right to refuse service, terminate profiles and passwords, remove or edit content, or cancel orders in its sole and absolute discretion.</p>

                <p>In addition, you agree that you will not:</p>

                <ol>
                    <li>interfere with a Producer’s or another User’s use and enjoyment of the Site;</li>
                    <li>interfere with or disrupt SnackShare security measures;</li>
                    <li>interfere with or disrupt networks connected to SnackShare, and will comply with all regulations, policies and procedures of such networks; and</li>
                    <li>use any robot, spider, other automatic device, or any manual process to monitor, copy, modify, distribute, or publish any web pages within the Site or within or adjacent to any other Site material (referred to as “Material” or “Materials” and defined as any and all product postings, product reviews, product ratings, messages, opinions, texts, files, links, images, photos or photographs, video, sounds, works of authorship, feedback, information, data, software, graphs, typefaces, graphics, music, or any other materials) without our prior written consent.  You also agree that you will not frame any portion of the Site without our prior written consent.</li>
                </ol>

                <p>All Users are prohibited from posting or transmitting through the Site any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit, profane, hateful, racially, ethnically, or otherwise objectionable material of any kind, including but not limited to any material that encourages conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable law.  The Site offers dedicated areas that allow for interactive activities that enable Users to communicate with each other directly, or which enable Users to post information and Material that will be visible to other Users (“Community”).  You are using the Community if you view or participate in a SnackShare Forum or Blog, post a review, create a list, create a profile, submit any of your own content or participate in any interactive feature.</p>

                <p>The Community may be used only for lawful purposes in accordance with this User Agreement.  If you are using the Community, and if you decide to post, publish or communicate any Material in the Community sections, then you acknowledge that you are bound by this User Agreement.  We reserve the right, in our sole discretion, to reject, refuse to post or remove any posting or other content (including private messages) by you, or to deny, restrict, suspend, or terminate access to all or any part of the Community at any time, for any or no reason, with or without prior notice or explanation, and without liability.  We reserve the right, in our sole discretion, to remove your profile and/or deny, restrict, suspend, or terminate your access to all or any part of the Community for any reason.</p>

                <p>If you become aware of misuse of the Community services, including any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit, profane, hateful, racially, ethnically, or otherwise objectionable Material of any kind, Material that encourages conduct that would constitute a criminal offence, give rise to civil liability, or violates any applicable law, then you are encouraged to notify us immediately of the location and details of this Material either by e-mail or phone or any other communication method available to you.</p>

                <p>We assume no responsibility for ongoing monitoring of the Site or for removal or editing of any Material, even after receiving notice.  SnackShare assumes no liability for any action or inaction with respect to conduct, communication, or content within the Community.</p>

                <p>You may not upload, embed, post, e-mail, transmit or otherwise make available any Material that infringes any copyright, patent, trademark, trade secret or other proprietary rights of any person or entity.  Any copyrighted or other proprietary User created content distributed with the consent of a copyright owner should contain a phrase such as “Copyright, owned by [name of owner]; used by permission.”  SnackShare is entitled to presume that all Material conforms to the foregoing requirements.</p>

                <p>The unauthorized submission of copyrighted or other proprietary content is illegal and could subject the User to personal liability for damages in a civil suit as well as criminal prosecution.  Community Users assume all liability for any damage resulting from any infringement of copyrights or proprietary rights, or from any other harm arising from an unauthorized submission or submission of Material.  SnackShare assumes no liability for any damage resulting from any infringement of copyrights or proprietary rights, or from any other harm arising from any Material.</p>

                <h4>Electronic Communications</h4>

                <p>When you visit the Site, or send e-mails to us, you are communicating with us electronically.  You consent to receive communications from us electronically.  We will communicate with you by e-mail or by posting notices on this Site.  You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>

                <h4>License and Site Access</h4>

                <p>Subject to the terms and conditions of this User Agreement, SnackShare grants you a limited, revocable, non-transferable and non-exclusive license to access and use the Site by displaying it on your internet browser only for the purpose of browsing content and/or shopping/posting for items on the Site.  Users may not use the Site for any commercial use or use on behalf of any third party, unless explicitly permitted to do so by SnackShare.</p>

                <p>Except as permitted in the paragraph above, you may not reproduce, distribute, display, sell, lease, transmit, create derivative works from, translate, modify, reverse-engineer, disassemble, decompile or otherwise exploit this Site or any portion of it unless expressly permitted by SnackShare.</p>

                <p>You shall not upload to, distribute, or otherwise publish on our Site any content, information, or other material that: (1) violates or infringes the copyrights, patents, trademarks, service marks or other proprietary rights of any person or organization; (2) is libelous, threatening, defamatory, obscene, indecent, pornographic, or could give rise to any civil or criminal liability under United States or international law; or (3) includes any bugs, viruses, worms, trap doors, Trojan horses or other harmful code or properties.</p>

                <p>Any breach of this User Agreement shall result in the immediate revocation of the license granted in this paragraph without notice to you.</p>

                <h4>Links and Third Party Content</h4>

                <p>SnackShare may provide links to the Web sites (“sites”) of affiliated companies, individuals and other businesses.  Submissions by Users on the SnackShare Site may also contain links to the third party sites.  We are not responsible for examining or evaluating, and we do not warrant the offerings of, any of these individuals and other businesses or the content of their sites.  SnackShare does not assume any responsibility or liability for the actions, products, and content of any such parties or any other third parties.</p>

                <p>Inclusion of any linked site on the SnackShare Site does not imply approval or endorsement of such linked site by SnackShare.  When you access these third party sites, you do so at your own risk. SnackShare takes no responsibility for third party advertisements or third party applications that are posted on or through the SnackShare Site or for the goods or services provided on the third party sites.</p>

                <p>Content from other Users, advertisers, and other third parties may be made available to you through the Site.  Because we do not control such Material, you agree that:</p>

                <ul>
                    <li>we are not responsible for any Material from other third parties;</li>
                    <li>we make no guarantees about the accuracy, currency, suitability, or quality of the information in such Material; and</li>
                    <li>we assume no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful Material made available by other Users, advertisers, and other third parties.</li>
                </ul>

                <p>Your interactions with other Users on the Site or with any Producers or advertisers, including, without limitation, payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and the other User, Producer or advertiser.</p>

                <p>You agree that we will not be responsible for any loss or damage incurred as the result of any such dealings or with respect to any Producer’s or other User’s use or disclosure of your personal information.  If there is a dispute between you and any third party (including, without limitation, any Producer or other User of the Services), we are under no obligation to become involved; we do reserve the right without any obligation to monitor disputes between you and any Producer or other Users.</p>

                <p>SnackShare is not obligated to review any Material.  Under no circumstance shall SnackShare be liable to you, or any third party, for any loss, damage or harm caused by any representation, warranty or guarantee of any third party.  It is your responsibility to evaluate the accuracy, completeness or usefulness of the information, opinion, advice, representation, warranty, guarantee or Material made available through the SnackShare Site.</p>

                <h4>Intellectual Property</h4>

                <p>Users shall not use our graphics, logos, content, appearance, design, functionality and all other aspects of the Site in any manner that could cause confusion or discredits SnackShare.</p>

                <p>All content on SnackShare, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of SnackShare or its content suppliers and protected by United States copyright laws.  The compilation of all content on this site is the exclusive property of SnackShare and is protected by United States copyright laws.</p>

                <p>The Site also contains material provided by us and our licensors (“SnackShare Material”).  We and our licensors (which includes Producers and other Users of the Services) own and retain all proprietary rights to the SnackShare Material. All Material and SnackShare Material is the exclusive property of SnackShare or its third party licensors. You may not alter or remove any trademark, copyright, patent or other notice from copies of Material or SnackShare Material.</p>

                <h4>Reviews and Comments</h4>

                <p>Anything that you submit or post to the Site and/or provide SnackShare (the “Submissions”), is and will be treated as nonconfidential and nonproprietary, and you grant SnackShare a royalty-free, worldwide, perpetual, irrevocable and transferable right to use, copy, distribute, display, publish, perform, sell, lease, transmit, adapt, create derivative works from such Submissions by any means and in any form, and to translate, modify, reverse-engineer, disassemble, or decompile such Submissions.  No compensation will be paid with respect to the Submissions that you submit, upload, post, transmit or otherwise make available through the Site.</p>

                <p>You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead SnackShare, our affiliates or third parties as to the origin of any Submissions.  SnackShare has the right, but shall not be obligated to, remove or edit any Submissions for any reason in its sole and absolute discretion.  You understand and agree that SnackShare is under no obligation to display, reproduce, or distribute the Submissions that you post.</p>

                <p>The following items are expressly prohibited from appearing on the Site (“Prohibited Submissions”).  No User or Producer will post any Prohibited Submissions that:</p>

                <ul>
                    <li>are offensive or promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
                    <li>bullies, harasses or advocates stalking, bullying, or harassment of another person;</li>
                    <li>uses any information obtained from the SnackShare Site in an attempt to harass, abuse, or harm another person or entity;</li>
                    <li>modifies, downloads (other than page caching), reproduces, copies, or resells the SnackShare Site, the Submissions or any portion thereof;</li>
                    <li>compiles, repackages, disseminates or otherwise uses data extracted from the SnackShare Site;</li>
                    <li>frames or utilizes framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of SnackShare;</li>
                    <li>provides instructional information about illegal activities such as, without limitation, making or buying illegal weapons or substances, violating someone´s privacy, or providing or creating computer viruses and other harmful code;</li>
                    <li>is false or misleading or promotes, endorses or furthers illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
                    <li>constitutes, promotes, or is used primarily for the purpose of dealing in: counterfeit goods; illegal drugs and paraphernalia; and stolen products;</li>
                    <li>professional services regulated by provincial licensing regimes;</li>
                    <li>involves the transmission of “junk mail”, “chain letters”, or unsolicited mass mailing, or “spamming”, instant messaging; contains restricted or password only access to pages or hidden pages or images (those not linked to or from another accessible page);</li>
                    <li>includes a photograph or video of another person that you have posted without that person’s prior consent;</li>
                    <li>infringes another person’s Submissions without a license to do so, whether or not the intellectual property is registered (or filed for registration) in any country;</li>
                    <li>promotes, copies, performs or distributes an illegal or unauthorized copy of another person’s work, whether it is protected by copyright or trade secret law, or not, such as, without limitation, presenting the work of a third party as your own (plagiarism), providing stolen or illegally obtained Submissions, providing pirated computer programs or links to them, providing information to circumvent manufacturer-installed copy-protection devices, or providing pirated music, videos, or movies, or links to such pirated music, videos, or movies;</li>
                    <li>circumvents or modifies, or attempts to circumvent or modify, or encourages or assists any other person in circumventing or modifying any security technology or software that is part of the SnackShare Site, or attempts to bypass or encourages any other person to bypass, or bypasses any SnackShare measures used to prevent or restrict access to any portion of the SnackShare Site;</li>
                    <li>interferes with, disrupts, or creates an undue burden on the SnackShare Site or the networks or services connected to the Community;</li>
                    <li>contains or involves the use of viruses, Trojan horses, worms, time bombs, corrupted files, bots, worms, data miner, scraper or any other computer code, files or programs that interrupt, destroy or limit the functionality of any computer software or hardware, or otherwise permit the unauthorized use of or access to a computer or a computer network;</li>
                    <li>solicits passwords or personally identifying information for commercial or unlawful purposes from other Users of the Services;</li>
                    <li>uses the account, username, or password of another Community User at any time or discloses your password to any third party or permits any third party to access your account;</li>
                    <li>involves commercial activities and/or promotions such as contests, sweepstakes, barter, advertising, or pyramid schemes;</li>
                    <li>involves any automated use of the SnackShare Site, such as, but not limited to, using scripts to add friends or send comments or messages;</li>
                    <li>enables high volume, automated, electronic processes that apply to the SnackShare Site or its systems;</li>
                    <li>impersonates or attempts to impersonate another Community User, person or entity;</li>
                    <li>sells or otherwise transfers your profile; and</li>
                    <li>violates this User Agreement or creates liability for SnackShare in any manner.</li>
                </ul>

                <h4>Pricing</h4>

                <p>SnackShare makes no warranty about the accuracy or reasonableness of prices set by Producers.</p>

                <p>If a product is listed at an incorrect price or with incorrect information due to typographical error or error in pricing or product information, SnackShare has the right to cancel any orders placed for products listed at the incorrect price. SnackShare will issue a full refund for the transaction amount to the credit card charged.</p>

                <h4>Coupons and Referral Program</h4>

                <p>From time to time SnackShare may issue coupons which can be applied to the product value in your purchase and not against, gift card, any taxes and any other transaction costs. Coupons are for one-time use, are non-refundable and may not be used on purchases with other coupons (only one coupon can be used per order), in conjunction with any other discount program or gift card offered by SnackShare. Any unapplied coupon value in a purchase expires and is not returned or refunded to you. In the event you purchase a Qualifying Product with a coupon and it is returned, you will receive a replacement product or be refunded for the amount that you paid in cash after applying the value of the coupon to the price of the Qualifying Product.</p>

                <p>Coupons do not constitute an account, a payment instrument or other property owned by you. Coupons do not have a cash value, are not transferable or sellable, are not gift cards, and cannot be used to purchase gift cards.</p>

                <p>Coupons issued to a single SnackShare account cannot be used for purchases on another account. SnackShare does not impose any fee on your use of coupons. To see your coupons check your SnackShare account.</p>

                <p>SnackShare reserves the right to revoke your coupons if SnackShare determines that you have engaged in fraudulent referral activity (such as inviting fictitious people to join SnackShare) or otherwise violated this Agreement or if we terminate your membership or account registration.</p>

                <p>SnackShare reserves the right at any time, without notice to you to change the terms applicable to the issuance of coupons, including expiration periods, requiring minimum purchase amounts for use of coupons, instituting maximums on the amount of coupons that you may earn or coupons that you may use in one transaction, and discontinuing the coupon program entirely.  We will post an updated version of these terms in the Agreement and/or FAQ’s from time to time, and you will be deemed to have accepted such changes by continuing to use the Site after the date such changes are made.</p>

                <p>From time to time, SnackShare may provide coupons to friends referred by members, and to members when their referred friends purchase Products. Participation in the referral program may require you to submit personal information about you and your friends, such as name and email address. You agree to receive communications from us as a result of your participation in the referral program, and to allow us to communicate with your friends about your participation (for example, by disclosing to your friends that an invite was sent by you). Any information collected from you or your friends as part of the referral program will be subject to our privacy policy.</p>

                <h4>Disclaimer and Limitation of Liability</h4>

                <p>The Site, the Services and the products purchased by you are provided on an “as is” and “as available” basis. SnackShare makes no representations or warranties of any kind, express, implied or statutory. SnackShare and its subsidiaries, affiliates, officers, directors, employees, shareholders, agents and suppliers specifically disclaim any implied warranties of title, merchantability, performance, fitness for a particular purpose and non-infringement.  In addition, no advice or information (oral or written) obtained by you from SnackShare shall create any warranty.</p>

                <p>SnackShare make no representation or warranty of any kind, express or implied as to the accuracy, reliability, or currency of any Materials or products and their description provided on or through the Site.</p>

                <p>In addition, to the maximum extent allowable by applicable law, SnackShare and its subsidiaries, affiliates, officers, directors, employees, shareholders and agents specifically disclaim all liability resulting from personal injury and/or death resulting from, or arising out of, your use or consumption of products obtained from Producers through the Site or in connection with the Services provided.  Such disclaimer shall include, without limitation, incorrect labeling of products and/or ingredients. In any such situation, your sole recourse shall be against the Producer that made the product.</p>

                <p>In no event shall SnackShare and its subsidiaries, affiliates, officers, directors, employees, and agents be liable to any User or any other person or entity for any direct, indirect, special, incidental, punitive, or consequential damages, arising out of or in connection with the User Agreement, the Services, the use or the inability to use the Site, or those resulting from any products purchased or obtained or consumed or transactions entered into in connection with the Services, including but not limited to reliance by you on any information obtained from the Site, any mistakes, omissions, interruptions, deletion of files or e-mail, errors, defects, viruses, delays in operation or transmission, or any failure of performance, whether or not resulting from acts of god, communications failure, theft, destruction, or unauthorized access to SnackShare’s records, programs, or services,  even if SnackSHare or any SnackShare supplier or Producer has been advised of the possibility of such damages.</p>

                <p>You agree to indemnify and hold SnackShare and its subsidiaries, affiliates, officers, directors, employees,and agents, harmless from any loss, liability, damages, expenses, claim or demand, including reasonable lawyer’s fees, made by any party due to, resulting from, or arising out of your breach of this User Agreement (and the documents it incorporates by reference), your own use of the Services or the Site, your purchase of products using the Site and their consumption, or your violation of any law, or the rights of a third party.</p>

                <h4>User Warranties</h4>

                <p>You warrant to SnackShare that you will not purchase any products through the Site that may be harmful based on your or the intended consumers allergies (including, without limitation, allergies to peanuts) and/or other medical conditions.</p>

                <p>You warrant to SnackShare that you will properly investigate whether the ingredients of a particular product are suitable for you or the intended consumer directly with the Producer.</p>

                <h4>No Guarantee of Website Access</h4>

                <p>SnackShare does not guarantee continuous, uninterrupted access to the Site, and operation of the Site may be interfered with by numerous factors outside SnackShare’s control.</p>

                <p>SnackShare, its suppliers and Producers make no representation or warranty of any kind, express or implied that the SnackShare Site or e-mails from the Site will be uninterrupted or error-free, or that they will be free of viruses, scripts, Trojan horses, worms or other harmful components or that there will be continuous uninterrupted and secure access to the SnackShare Site.  Under no circumstances shall SnackShare or any SnackShare supplier be liable for any damages that result from your inability to use the Site.</p>

                <h4>Termination</h4>

                <p>SnackShare has the right to immediately terminate this User Agreement and may, without prior notice to you, immediately revoke any or all of your rights granted under this User Agreement, all without liability to SnackShare. Upon any termination of this User Agreement, you shall immediately cease all access to and use of the Site and SnackShare shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and customer profile identification issued to you and deny your access to and use of the Site in whole or in part.  Any termination of this User Agreement shall not affect the respective rights and obligations (including without limitation, delivery and payment obligations) of the parties arising before the date of termination.</p>

                <h4>Applicable Law</h4>

                <p>The laws of the United States of America govern this User Agreement, without giving effect to any principles of conflicts of laws or the Convention on Contracts for the International Sale of Goods.</p>

                <h4>Copyright Complaints</h4>

                <p>If you feel that any content on the Site may infringe on any copyright laws, please provide SnackShare with the written information specified below:</p>

                <ul>
                    <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
                    <li>a description of the copyrighted work that you claim has been infringed upon;</li>
                    <li>a description of where the material that you claim is infringing is located on the Site, including the item name, if applicable;</li>
                    <li>your address, telephone number, and e-mail address;</li>
                    <li>a statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</li>
                    <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
                </ul>

                <p>Please send notices to support@snackshare.com</p>

                <h4>Severability</h4>

                <p>If any provision of this User Agreement is held unenforceable, then such provision will be modified to reflect the parties’ intention.  All remaining provisions of this User Agreement shall remain in full force and effect.  The failure of SnackShare to exercise or enforce any right or provision of this User Agreement shall not operate as a waiver of such right or provision.</p>

                <h4>No Agency</h4>

                <p>You and SnackShare are independent contractors, and no agency, partnership, joint venture, employee-employer or franchisor-franchisee relationship is intended or created by this User Agreement.</p>

                <h4>Entire Agreement</h4>

                <p>This User Agreement, including any terms and conditions incorporated herein by reference, and the general terms and conditions of the Site, including but not limited to the Privacy Policy and Terms of Use constitute the entire agreement of the parties with respect to the subject matter hereof, and supersedes and cancels all prior and contemporaneous agreements, claims, representations, and understandings of the parties in connection with the subject matter hereof.</p>

                <h4>Contact Information</h4>

                <p>You can contact SnackShare at <a href="mailto:support@snackshare.com">support@snackshare.com</a></p>

                <p>Our mailing address is:</p>
                <p>SnackShare<br />
                1616 W. 6th St. #424<br />
                Austin, TX 78703</p>
            </div>
        );
    }
}

export default Terms;
