import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AdminItemTable extends Component {

    markStaged(e) {
        e.preventDefault();
        let self = this;
        let itemId = e.currentTarget.getAttribute('data-item');
        // Find that item in the array.
        let foundItem = null;
        let foundProduct = null;
        self.props.productArray.forEach( product => {
            product.items.forEach( item => {
                if ( item.id === itemId ) {
                    foundItem = item;
                    foundProduct = product;
                }
            });
        });
        if ( foundItem ) {
            let pounds = foundItem.total_pounds;
            if ( foundProduct.is_weight_based && !foundItem.total_pounds ) {
                pounds = prompt( 'How many pounds total?', 1 );
                if ( pounds === null ) return;
            }
            window.ss.put( '/items/' + foundItem.id, {
                staged: '1',
                total_pounds: pounds
            }).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                } else if ( json.error ) {
                    alert( json.error );
                } else {
                    document.getElementById('item_' + foundItem.id).innerHTML = 'Received'
                }
            });
        } else {
            alert('Something broke. Tell Micah');
        }
    }

    render() {
        let self = this;

        return (
            <div>
                <h3 className="admin-table-title">{ self.props.title }</h3>

                <div className="admin-producttype">
                    <table>
                        <thead>
                            <tr>
                                <th>Vendor</th>
                                <th>Total</th>
                                <th>Product</th>
                                <th>Warehouse</th>
                                <th>Delivery</th>
                                <th>Qty.</th>
                                <th>Customer</th>
                                <th>Received</th>
                            </tr>
                        </thead>
                        <tbody>
                            { self.props.productArray.map( ( product, i ) => {

                                return ( product.items.map( ( item, j ) => {
                                    if ( j === 0 ) {
                                        return (
                                            <tr key={ j } className={ i % 2 === 1 ? 'odd' : 'even' }>
                                                <td rowSpan={ product.items.length }>{ product.vendor.name }</td>
                                                <td rowSpan={ product.items.length }><b>{ product.quantity }</b></td>
                                                <td rowSpan={ product.items.length }>{ product.name }</td>
                                                <td rowSpan={ product.items.length }>
                                                    { window.ss.toTitleCase( product.warehouse_section ) }
                                                    { product.warehouse_section && product.shelf && (
                                                        <br />
                                                    )}
                                                    { product.shelf }
                                                </td>
                                                <td>{ window.ss.fullDate( item.order.delivery_date ) }</td>
                                                <td>{ item.quantity }</td>
                                                <td>
                                                    <Link to={ '/order/' + item.order.id }>
                                                        { item.order.user.name }
                                                    </Link>
                                                </td>
                                                <td id={ 'item_' + item.id }>
                                                    { item.class_name === 'Item' && item.status === 'confirmed' && (
                                                        <a href="/home" data-item={ item.id } onClick={ self.markStaged.bind(self) } className="item-grid-received">Received</a>
                                                    )}
                                                    { item.class_name === 'Item' && item.status === 'cancelled' && (
                                                        <div>Cancelled</div>
                                                    )}
                                                    { item.class_name === 'Item' && item.status === 'staged' && (
                                                        <div>Received</div>
                                                    )}
                                                    { item.class_name === 'Item' && item.status === 'delivered' && (
                                                        <div>Delivered</div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        return (
                                            <tr key={ j } className={ i % 2 === 1 ? 'odd' : 'even' }>
                                                <td>{ window.ss.fullDate( item.order.delivery_date ) }</td>
                                                <td>{ item.quantity }</td>
                                                <td>
                                                    <Link to={ '/order/' + item.order.id }>
                                                        { item.order.user.name }
                                                    </Link>
                                                </td>
                                                <td id={ 'item_' + item.id }>
                                                    { item.class_name === 'Item' && item.status === 'confirmed' && (
                                                        <a href="/home" data-item={ item.id } onClick={ self.markStaged.bind(self) } className="item-grid-received">Received</a>
                                                    )}
                                                    { item.class_name === 'Item' && item.status === 'cancelled' && (
                                                        <div>Cancelled</div>
                                                    )}
                                                    { item.class_name === 'Item' && item.status === 'staged' && (
                                                        <div>Received</div>
                                                    )}
                                                    { item.class_name === 'Item' && item.status === 'delivered' && (
                                                        <div>Delivered</div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }
                                }))
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default AdminItemTable;
