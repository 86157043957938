import React from "react";

function App() {

    return (
        <div className="blog page">
            <iframe title="welcome" src="https://snackshare.squarespace.com/signup"></iframe>
        </div>
    );
}

export default App;
