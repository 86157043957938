import React from "react";
import { Link } from "react-router-dom";

class ProductImages extends React.Component {

    state = {
        product: null
    };

    constructor( props ) {
        super( props );
        this.state.product = this.props.product;
    }

    removeImage(e) {
        e.preventDefault();
        let self = this;
        let assetId = e.currentTarget.getAttribute('data-removeimage');
        if ( window.confirm('Are you sure you want to remove this image?') ) {
            window.ss.delete( '/assets/' + assetId ).then( json => {
                if ( json && json.err && json.err.message ) {
                    alert( json.err.message );
                } else if ( json.error ) {
                    alert( json.error );
                } else {
                    let prod = JSON.parse( JSON.stringify( self.state.product ) );
                    prod.assets.forEach( ( asset, i ) => {
                        if ( asset.id === assetId ) {
                            prod.assets.splice( i, 1 );
                        }
                    });
                    self.setState({ product: prod });
                }
            });
        }
    }

    render() {
        let self = this;

        return (
            <div className="product-admin-images">
                { self.state.product.assets.map( ( asset, i ) =>
                    <div key={ i }>
                    { asset.id && (
                        <div className="product-admin-image">
                            <img alt={ asset.id } src={ asset.wide_url } />
                            <a className="product-admin-image-remove" href="/" data-removeimage={ asset.id } onClick={ self.removeImage.bind(self) }>Remove</a>
                        </div>
                    )}
                    </div>
                )}
                <div className="product-admin-image product-admin-image-add">
                    <Link to={ '/home/products/' + self.state.product.ident + '/upload' }>Add New Image</Link>
                </div>
            </div>
        );
    }
}

export default ProductImages;
