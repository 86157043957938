import React, { Component } from 'react';

class GreenGateSpring extends Component {

    componentWillMount() {
        this.props.history.push( '/vendors/greengatefarms/products/greengatefarms-prepaid-spring-csa' );
    }

    render() {
        return (
            <div className="page greengate"></div>
        );
    }
}

export default GreenGateSpring;
