import React from "react";
import { Link } from "react-router-dom";

class Carousel extends React.Component {

    state = {
        products: [],
        loading: false,
        totalPages: 1,
        page: 0
    };

    constructor( props ) {
        super( props );
        this.container = React.createRef();
    }

    handleScroll( event ) {
        this.checkLoad();
    }

    componentWillMount() {
        this.loadProducts( 1 );
    }

    async checkLoad() {
        let container = this.container.current;
        if ( !container ) return;
        if ( container.offsetWidth + container.scrollLeft + 300 < container.scrollWidth ) return;
        let newPage = this.state.page + 1;
        if ( this.state.loading || newPage > this.state.totalPages ) return;
        this.loadProducts( newPage );
    }

    async loadProducts( page ) {
        this.setState({ loading: true });
        let url = '/products?product_type=' + this.props.productType.ident + '&per_page=5&page=' + page;
        let response = await window.ss.get( url );
        this.setState({
            loading: false,
            page: page,
            totalPages: response.total_pages,
            products: this.state.products.concat( response.results )
        });
        this.checkLoad();
    }

    handleAddToCart( e ) {
        e.preventDefault();
        e.stopPropagation();
        let self = this;
        let link = e.target;
        let productIdent = link.getAttribute('data-addtocart');
        link.className = 'carousel-addtocart carousel-added';
        link.innerHTML = 'Added!';
        window.ss.get( '/products/' + productIdent ).then( product => {
            if ( window.ss.is_open_order ) {
                window.ss.flash( product.name + ' added to your current order.<br />We&rsquo;ll email you when you&rsquo;re done making changes.', 3 );
            } else {
                window.ss.flash( product.name + ' added to cart.', 1 );
            }
            setTimeout( function() {
                link.innerHTML = self.getActionName( product );
                link.className = 'carousel-addtocart';
            }, 1000 );
            window.ss.addProduct( product, 1 );
        });
    }

    getActionName( product ) {
        if ( product.is_instant_buy && product.cost > 0 ) {
            return 'Buy Now';
        } else if ( product.is_instant_buy ) {
            return 'Continue';
        } else if ( window.ss.is_open_order ) {
            return 'Add to Order';
        } else {
            return 'Add to Cart';
        }
    }

    render() {
        let self = this;

        if ( this.state.products.length > 0 ) {
            return (
                <div className="market-category">
                    <h3>{ this.props.productType.name }</h3>
                    <section className="carousel" onScroll={ self.handleScroll.bind( self ) } ref={ this.container }>
                        <div className="carousel-content">
                            { this.state.products.map( ( product, i ) =>
                                <Link key={i} className="carousel-card" to={ product.path }>
                                    <img src={ product.assets[0].wide_url } alt={ product.name } />
                                    { product.is_local && (
                                        <span class="ribbon"><span>LOCAL</span></span>
                                    )}
                                    <div className="carousel-card-info">
                                        <h4>{ product.name }</h4>
                                        <h5>{ product.vendor.name }</h5>
                                        { !!product.cost && !product.is_weight_based && (
                                          <span className="price">${ window.ss.formatCost( product.cost ) }</span>
                                        )}
                                        { !!product.pound_cost && product.is_weight_based && (
                                          <span className="price">${ window.ss.formatCost( product.pound_cost ) } per pound</span>
                                        )}
                                        <span className="weight">{ product.unit_name }</span>
                                    </div>
                                    { false && ( window.ss.is_open_order || !window.ss.is_no_orders ) && (
                                        <span className="carousel-addtocart" href="#" data-addtocart={ product.ident } onClick={ self.handleAddToCart.bind(self) }>{ self.getActionName( product ) }</span>
                                    )}
                                </Link>
                            )}
                            { this.state.page < this.state.totalPages && (
                                <div className="carousel-loading">...</div>
                            )}
                        </div>
                    </section>
                </div>
            );
        } else {
            return '';
        }
    }
}

export default Carousel;
