import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderList from '../../orders/OrderList';

class NormalOrders extends Component {

    state = {
        ordersPrior: {}
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadOrders();
        document.title = 'Orders | SnackShare';
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadOrders() {
        await this.setState({
            ordersPrior: {}
        });
        await this.fetch( 'ordersPrior', '/orders?per_page=500&sort=recent' );
        this.render();
    }

    fetch( key, url ) {
        let self = this;
        return window.ss.get( url ).then( response => {
            let obj = {};
            obj[ key ] = response;
            if ( self._isMounted ) {
                return self.setState( obj );
            } else {
                return Promise.resolve();
            }
        });
    }

    render() {
        return (
            <div className="page orders">
                { this.state.ordersPrior.total === 0 && (
                    <div className="currentorder">
                        <h3 className="title">No prior orders.</h3>
                        <Link className="bluebutton welcome-marketlink" to="/market">Browse the Market</Link>
                    </div>
                )}
                { this.state.ordersPrior.total > 0 && (
                    <OrderList list_type="prior" groupDate="true" limit="500" orders={ this.state.ordersPrior } />
                )}
            </div>
        );
    }
}

export default NormalOrders;
