import React, { Component } from 'react';
import { Link } from "react-router-dom";

class CartPage extends Component {

    state = {
        order: {},
        productAdded: false,
        loading: true
    };

    componentWillMount() {
        if ( window.ss.is_open_order ) {
            return this.props.history.push('/cart/order');
        }
        if ( window.ss.is_no_orders ) {
            return this.props.history.push('/');
        }
        if ( window.ss.productAdded ) {
            this.setState({ productAdded: true });
            window.ss.productAdded = false;
        }
        this.loadOrderSummary();
        document.title = 'Cart | SnackShare';
    }

    loadOrderSummary() {
        let self = this;
        let cart = JSON.parse( JSON.stringify( window.ss.buyNow ? window.ss.buyNow : window.ss.cart ) );
        window.ss.post( '/orders/summary',cart ).then( order => {
            self.setState({ order });
            if ( order.delivery_date ) {
                window.ss.cartSet( 'delivery_date', order.delivery_date );
            }
            self.setState({ loading: false });
        });
    }

    removePromo(e) {
        e.preventDefault();
        window.ss.cartSet( 'promo_code', '' );
        this.loadOrderSummary();
    }

    render() {

        let self = this;

        function handleQuantityChange(e) {
            let productIdent = e.target.getAttribute( 'data-product_ident' );
            let quantity = e.target.value;
            window.ss.cartSetProduct( productIdent, quantity, true );
            self.loadOrderSummary();
        }

        function getQuantityOptions( item ) {
            let str = '';
            let amount = ( item.is_unlimited ? 100 : item.inventory );
            for (var i=0;i<=amount;i++) {
                str += '<option value="' + i + '"' + ( item.quantity === i ? ' selected' : '' ) + '>' + i +'</option>';
            }
            return (
                <select data-product_ident={ item.ident } onChange={ handleQuantityChange } dangerouslySetInnerHTML={{ __html: str }}></select>
            );
        }

        function handleItemRemove(e) {
            e.preventDefault();
            let productIdent = e.currentTarget.getAttribute( 'data-product_ident' );
            window.ss.cartSetProduct( productIdent, 0, true );
            self.loadOrderSummary();
        }

        function getPageSubmitClassName() {
            return ( self.state.order.subtotal < 25 && !self.state.order.subscription ? 'hideme' : '' );
        }

        function showProductAdded() {
            if ( !self.state.productAdded ) return '';
            return (
                <div className="cart-productadded">Product Added!</div>
            );
        }

        function showCartError() {
            if ( self.state.order.subtotal >= 25 || self.state.order.subscription ) return '';
            return (
                <div className="cart-error">
                    You must have at least $25 worth of goods (before delivery fee) to continue.
                </div>
            );
        }

        function handlePageSubmit(e) {
            e.preventDefault();
            let nextStep = '/cart/confirm';
            if ( !self.state.order.address ) {
                nextStep = '/cart/address';
            } else if ( !self.state.order.delivery_date ) {
                nextStep = '/cart/delivery_date';
            }
            if ( !window.ss.me.id ) {
                window.ss.saveReturn( nextStep );
                return self.props.history.push( '/account/register' );
            } else {
                return self.props.history.push( nextStep );
            }
        }

        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else if ( this.state.order.items.length === 0 ) {
            return (
                <div className="page cart">
                    <div className="cart-empty">
                        <p>You have no items in your cart.</p>
                        <Link className="bluebutton" to="/market">Browse the Market</Link>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="page cart">
                    { showProductAdded() }
                    <h3 className="title">Shopping Cart</h3>
                    <div className="cart-section-items">
                    { this.state.order.items.map( ( item, j ) =>
                        <div key={j} className="cart-item">
                            <div className="cart-item-info">
                                <div className="cart-item-name"><Link to={ item.path }>{ item.name }</Link></div>
                                <div className="cart-item-unit">{ item.unit_name }</div>
                                <div className="cart-item-vendor">{ item.vendor.name }</div>
                            </div>
                            { !!item.cost && !item.is_instant_buy && (
                                <div className="cart-item-costs">
                                    { item.is_weight_based && (
                                        <div className="cart-item-unitcost">x <b>${ window.ss.formatCost( item.pound_cost * item.pound_avg ) }</b></div>
                                    )}
                                    { !item.is_weight_based && (
                                        <div className="cart-item-unitcost">x <b>${ window.ss.formatCost( item.cost ) }</b></div>
                                    )}
                                    { item.is_weight_based && (
                                        <div className="cart-item-subtotal">= <b>${ window.ss.formatCost( item.quantity *  item.pound_cost * item.pound_avg ) }</b></div>
                                    )}
                                    { !item.is_weight_based && (
                                        <div className="cart-item-subtotal">= <b>${ window.ss.formatCost( item.quantity * item.cost ) }</b></div>
                                    )}
                                </div>
                            )}
                            { !!item.cost && !item.is_instant_buy && (
                                <div className="cart-item-quantity">
                                    { getQuantityOptions( item ) }
                                </div>
                            )}
                            { item.is_weight_based && (
                                <div className="cart-item-weightnote">(price based on weight)</div>
                            )}
                            { item.is_instant_buy && (
                                <div className="cart-item-costs">
                                    <a className="cart-item-remove" data-product_ident={ item.ident } onClick={ handleItemRemove } href="/"><img src="/img/trash-can.svg" alt="Remove" /></a>
                                </div>
                            )}
                        </div>
                    )}
                    </div>
                    <Link className="bluebutton" to="/market">Add More Items</Link>

                    <h3 className="title">Summary</h3>

                    <div className="cart-summary">
                        <table><tbody>
                        { !!this.state.order.subtotal ? (
                            <tr className="cart-subtotal">
                                <td className="cart-summary-label">Subtotal</td>
                                <td className="cart-summary-amount">${ window.ss.formatCost( this.state.order.subtotal ) }</td>
                            </tr>
                        ) : null}
                        <tr className="cart-delivery">
                            <td className="cart-summary-label">Delivery Fee</td>
                            <td className="cart-summary-amount">${ window.ss.formatCost( this.state.order.delivery_fee ) }</td>
                        </tr>
                        { self.state.order.promo_code ? (
                            <tr className="cart-promo">
                                <td className="cart-summary-label">Promo: <b>{ self.state.order.promo_code.toUpperCase() }</b> <a href="/" className="remove" onClick={ self.removePromo.bind(self) }>X</a></td>
                                <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( this.state.order.promo_discount ) }</td>
                            </tr>
                        ) : null}
                        { ( window.ss.me.gift_balance || this.state.order.gift_applied ) ? (
                            <tr className="cart-promo">
                                <td className="cart-summary-label">Gift Card <b>(${ window.ss.formatCost( window.ss.me.gift_balance ) } available)</b>:</td>
                                <td className="cart-summary-amount">&ndash; ${ window.ss.formatCost( this.state.order.gift_applied ) }</td>
                            </tr>
                        ) : null}
                        <tr className="cart-total">
                            <td className="cart-summary-label">Total</td>
                            <td className="cart-summary-amount">${ window.ss.formatCost( this.state.order.total ) }</td>
                        </tr>
                        </tbody></table>
                    </div>

                    { showCartError() }

                    <a href="/cart/address" id="pagesubmit" className={ getPageSubmitClassName() } onClick={ handlePageSubmit }>Begin Checkout</a>
                </div>
            );
        }
    }
}

export default CartPage;
