import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Markdown from 'react-markdown';

class VendorBubble extends Component {

  showAvatar() {
    if ( this.props.link === 'show' && this.props.vendor.path ) {
      return (
        <Link to={ this.props.vendor.path }>
          <img className="avatar" src={ this.props.vendor.assets[0].square_url } alt={ this.props.vendor.name } />
        </Link>
      );
    } else {
      return (
        <img className="avatar" src={ this.props.vendor.assets[0].square_url } alt={ this.props.vendor.name } />
      );
    }
  }

  showProductsLink() {
    if ( this.props.link !== 'show' || !this.props.vendor.path ) return '';
    return (
      <Link className="product-vendor-link" to={ this.props.vendor.path }>
        See All Products by { this.props.vendor.name }
      </Link>
    );
  }

  render() {
    return (
      <div className="product-vendor">
        { this.showAvatar() }
        <div className="product-vendor-name">{ this.props.vendor.name }</div>
        <div className="product-vendor-location">{ this.props.vendor.location }</div>
        <div className="product-vendor-description">
            <Markdown source={ this.props.vendor.description } />
        </div>
        { this.showProductsLink() }
      </div>
    );
  }
}

export default VendorBubble;
