import React, { Component } from 'react';
import ItemList from '../../items/ItemList';
import OrderList from '../../orders/OrderList';
import { Link } from "react-router-dom";

class AdminDashboard extends Component {
    _isMounted = false;

    state = {
        itemsPending: {},
        ordersUpcomingDeliveries: {},
        itemsCancelled: {},
        subscriptions: {}
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadOrders();
        document.title = 'Dashboard | SnackShare';
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadOrders() {
        await this.setState({
            itemsPending: {},
            ordersUpcomingDeliveries: {},
            itemsCancelled: {},
            subscriptions: {}
        });
        let now = new Date();
        let today = new Date( now.getFullYear(), now.getMonth(), now.getDate() );
        await this.fetch( 'itemsPending', '/items?user=all&status=pending&per_page=3&include_order=1' );
        await this.fetch( 'ordersUpcomingDeliveries', '/orders?user=all&status=pending&per_page=3' );
        await this.fetch( 'itemsCancelled', '/items?user=all&status=cancelled&delivery_after=' + today.getTime() + '&per_page=3&include_order=1' );
        await this.fetch( 'subscriptions', '/subscriptions?user=all&per_page=500' );
        this.render();
    }

    fetch( key, url ) {
        let self = this;
        return window.ss.get( url ).then( response => {
            let obj = {};
            obj[ key ] = response;
            if ( self._isMounted ) {
                return self.setState( obj );
            } else {
                return Promise.resolve();
            }
        });
    }

    render() {
        return (
            <div className="page orders">

                { this.state.itemsPending.total > 0 && (
                    <h3 className="title">
                        { this.state.itemsPending.total } Pending Item{ this.state.itemsPending.total === 1 ? '' : 's' }
                        <Link to="/home/item_grid" className="forwardButton"><img alt="See All" src="/img/arrow-circle-right.svg" /></Link>
                    </h3>
                )}

                { this.state.ordersUpcomingDeliveries.total > 0 && (
                    <OrderList list_type="pending" groupDate="true" limit="3" orders={ this.state.ordersUpcomingDeliveries } />
                )}

                { this.state.itemsCancelled.total > 0 && (
                    <ItemList list_type="cancelled" limit="3" items={ this.state.itemsCancelled } onChange={ this.loadOrders.bind( this ) } />
                )}

                { this.state.subscriptions && this.state.subscriptions.total > 0 && (
                    <div className="home-subs">
                        <h3 className="title">Subscription{ this.state.subscriptions.total === 1 ? '' : 's' }</h3>

                        <div className="item-list">
                            { this.state.subscriptions.results.map( ( sub, i ) =>
                                <div key={i} className="item-row">
                                    <div className="item-info">
                                        <div className="item-name">{ sub.product && sub.product.name }</div>
                                        <div className="item-unit">{ sub.product && sub.product.unit_name }</div>
                                        <div className="order-user-name">{ sub.user.name }</div>
                                        <span className="address-item-info">
                                            <span className="address-item-line">{ sub.address.address1 }</span>
                                            { sub.address.address2 && (
                                                <span className="address-item-line">{ sub.address.address2 }</span>
                                            )}
                                            <span className="address-item-line">
                                                { sub.address.city }, { sub.address.state } { sub.address.zipcode }
                                            </span>
                                            <span className="address-item-line address-item-type">{ sub.address.location_type }</span>
                                            { sub.address.notes && (
                                                <span className="address-item-line address-item-notes">{ sub.address.notes }</span>
                                            )}
                                            <span>
                                                <span className="address-item-line"><a href={ 'mailto:' + sub.user.email }>{ sub.user.email }</a></span>
                                                <span className="address-item-line"><a href={ 'tel:' + window.ss.formatTel( sub.user.phone ) }>{ window.ss.formatPhone( sub.user.phone ) }</a></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

            </div>
        );
    }
}

export default AdminDashboard;
