import React, { Component } from 'react';
import { Link } from "react-router-dom";
import VendorBubble from '../vendor/VendorBubble';
import Markdown from 'react-markdown';
import ImageGallery from 'react-image-gallery';

class ProductPage extends Component {

    state = {
        product: { vendor: {}, assets: [] },
        images: [],
        quantity: 1,
        loading: true
    };

    componentWillMount() {
        let self = this;
        // The Product URLs will have this format:
        // http://snackshare.com/vendors/:vendor_ident/products/:product_ident
        // Example:
        // http://snackshare.com/vendors/pepper-farmer/products/jalapenos
        // vendor_ident == pepper-farmer
        // product_ident == jalapenos
        const { product_ident } = this.props.match.params;
        window.ss.get( '/products/' + product_ident ).then( product => {
            if ( product.is_published ) {
                document.title = product.name + ' | SnackShare';
                let images = product.assets.map( asset => {
                    return {
                        original: asset.wide_url,
                        thumbnail: asset.wide_url
                    }
                });
                self.setState({ loading: false, product: product, images: images });
            } else {
                self.props.history.push( '/market' );
            }
        });
    }

    handleQuantityChange(e) {
        this.setState({ quantity: parseInt( e.target.value, 10 ) })
    }

    getQuantityOptions() {
        let product = this.state.product;
        if ( !this.state.product.cost ) return '';
        let str = '';
        let amount = ( product.is_unlimited ? 100 : product.inventory );
        for (var i=0;i<=amount;i++) {
            str += '<option value="' + i + '"' + ( this.state.quantity === i ? ' selected' : '' ) + '>' + i +'</option>';
        }
        return (
            <select className="product-addtocart-number" onChange={ this.handleQuantityChange.bind(this) } dangerouslySetInnerHTML={{ __html: str }}></select>
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        window.ss.addProduct( this.state.product, this.state.quantity ).then( returnPath => {
            this.props.history.push( returnPath );
        });
    }

    showCartButton() {
        return '';
/*
        // If we haven't got the product data yet, don't show this.
        if ( !this.state.product ) return '';
        // If the item isn't sold out, show the info and placeholder.
        if ( this.state.product.is_unlimited || this.state.product.inventory ) {
            let inventoryString = ( !this.state.product.is_unlimited ? ''+this.state.product.inventory + ' available' : '' );
            return (
                <div className="product-addtocart">
                    <div className="product-addtocart-info">
                        <b>Order Now</b>
                        <span className="product-inventory">{ inventoryString }</span>
                    </div>
                    <form autoComplete="off" className="product-addtocart-form" data-product_ident={ this.state.product.ident } onSubmit={ this.handleSubmit.bind(this) }>
                        { !this.state.product.is_instant_buy && this.getQuantityOptions() }
                        { this.state.product.is_instant_buy && (
                            <input type="submit" className="product-addtocart-submit product-addtocart-buynow" value={ this.state.product.cost > 0 ? 'Buy Now' : 'Continue' } />
                        )}
                        { !this.state.product.is_instant_buy && window.ss.is_open_order && (
                            <input type="submit" className="product-addtocart-submit" value="Add to Order" />
                        )}
                        { !this.state.product.is_instant_buy && !window.ss.is_open_order && (
                            <input type="submit" className="product-addtocart-submit" value="Add to Cart" />
                        )}
                    </form>
                </div>
            );
        // Not unlimited, and nothing in inventory
        } else {
            return (
                <div className="product-addtocart">
                    <div className="product-outofstock">Currently Out of Stock</div>
                </div>
            );
        }
*/
    }

    render() {
        if ( this.state.loading ) {
            return (
                <div className="loading">Loading...</div>
            );
        } else {
            return (
              <div className="page product">
                { this.state.images.length < 2 && (
                    <div className="product-gallery product-gallery-single">
                        { this.state.product.is_local && (
                            <span class="ribbon"><span>LOCAL</span></span>
                        )}
                        <img src={ this.state.product.assets[0].wide_url } alt={ this.state.product.name } />
                    </div>
                )}
                { this.state.images.length > 1 && (
                    <div className="product-gallery">
                        { this.state.product.is_local && (
                            <span class="ribbon"><span>LOCAL</span></span>
                        )}
                        { this.state.images.length > 1 && (
                            <ImageGallery
                              items={ this.state.images }
                              showBullets={ false }
                              showFullscreenButton={ false }
                              showPlayButton={ false }
                              showThumbnails={ true }
                              showIndex={ true }
                              showNav={ true }
                            />
                        )}
                    </div>
                )}
                <div className="product-info">
                  <div className="product-info-left">
                    <h2 className="product-name">
                      { this.state.product.name }
                    </h2>
                    <Link className="product-vendor-link" to={ this.state.product.vendor.path ? this.state.product.vendor.path : '' }>
                        { this.state.product.vendor.name }
                    </Link>
                  </div>
                  <div className="product-info-right">
                    { !!this.state.product.cost && !this.state.product.is_weight_based && (
                        <p className="product-cost">
                            ${ window.ss.formatCost( this.state.product.cost ) }
                        </p>
                    )}
                    { !!this.state.product.pound_cost && this.state.product.is_weight_based && (
                        <p className="product-cost">
                            ${ window.ss.formatCost( this.state.product.pound_cost ) } per pound
                        </p>
                    )}
                    <p className="product-unitname">
                        { this.state.product.unit_name }
                    </p>
                  </div>
                </div>
                <div className="product-description">
                    <Markdown source={ this.state.product.description } />
                </div>
                <VendorBubble vendor={ this.state.product.vendor } link="show" />
                { this.showCartButton() }
              </div>
            );
        }
    }
}

export default ProductPage;
