import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderList from '../../orders/OrderList';

class NormalDashboard extends Component {
    _isMounted = false;

    state = {
        ordersCurrent: {},
        subscriptions: {}
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadOrders();
        document.title = 'Dashboard | SnackShare';
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadOrders() {
        await this.setState({
            ordersCurrent: {},
            subscriptions: {}
        });
        await this.fetch( 'ordersCurrent', '/orders?per_page=500&status=pending' );
        await this.fetch( 'subscriptions', '/subscriptions?per_page=500' );
        this.render();
    }

    fetch( key, url ) {
        let self = this;
        return window.ss.get( url ).then( response => {
            let obj = {};
            obj[ key ] = response;
            if ( self._isMounted ) {
                return self.setState( obj );
            } else {
                return Promise.resolve();
            }
        });
    }

    render() {
        return (
            <div className="page orders">

                { this.state.ordersCurrent.total > 0 && (
                    <div className="currentorder">
                        <OrderList list_type="current" groupDate="true" limit="500" orders={ this.state.ordersCurrent } />
                    </div>
                )}

                { this.state.subscriptions && this.state.subscriptions.total > 0 && (
                    <div className="home-subs">
                        <h3 className="title">Subscription{ this.state.subscriptions.total === 1 ? '' : 's' }</h3>

                        <div className="item-list">
                            { this.state.subscriptions.results.map( ( sub, i ) =>
                                <div className="item-row">
                                    <div className="item-info">
                                        <div className="item-name">{ sub.product && sub.product.name }</div>
                                        <div className="item-unit">{ sub.product && sub.product.unit_name }</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                { this.state.ordersCurrent.total === 0 && (
                    <div className="currentorder">
                        <h3 className="title">No active orders.</h3>
                        <Link className="bluebutton welcome-marketlink" to="/market">Browse the Market</Link>
                    </div>
                )}

            </div>
        );
    }
}

export default NormalDashboard;
