import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

class Analytics extends React.Component {

    constructor( props ) {
        super(props);
        if ( window.location.hostname === 'www.snackshare.com' ){
            ReactGA.initialize( 'UA-153071569-1' );
        } else {
            ReactGA.initialize( 'UA-153071569-2' );
        }
        ReactGA.pageview( window.location.pathname + window.location.search );

        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init( '2166340346784243', {}, options );
    }

    componentWillUpdate({ location, history }) {
        if ( location.pathname === this.props.location.pathname ) {
            // don't log identical link clicks (nav links likely)
            return;
        }
        // When navigating forward, or hitting the back button.
        if ( history.action === 'PUSH' ||  history.action === 'POP' ){
            ReactGA.pageview( window.location.pathname + window.location.search );
            ReactPixel.pageView();
        }
    }
    render() {
        return null;
    }
}

export default withRouter(Analytics);
