import React, { Component } from 'react';

class NewLeafSummerExtension extends Component {

    componentWillMount() {
        this.props.history.push( '/vendors/newleaf/products/newleaf-3-week-summer-extension' );
    }

    render() {
        return (
            <div className="page newleaf"></div>
        );
    }
}

export default NewLeafSummerExtension;
