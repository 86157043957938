import React, { Component } from 'react';

class VendorAdd extends Component {

    state = {
        name:               '',
        description:        '',
        location:           ''
    };

    handleChange(e) {
        let obj = {};
        obj[ e.target.name ] = e.target.value;
        this.setState( obj );
    }

    handleSubmit(e) {
        e.preventDefault();
        let self = this;
        window.ss.post( '/vendors', {
            name: self.state.name,
            description: self.state.description,
            location: self.state.location
        }).then( json => {
            if ( json && json.err && json.err.message ) {
                alert( json.err.message );
            } else if ( json.error ) {
                alert( json.error );
            } else if ( json.ident ) {
                self.props.history.push('/home/vendors/' + json.ident + '/edit');
            }
        });
    }

    render() {
        return (
            <div className="page form">

                <h3 className="title">Add New Vendor</h3>

                <form onSubmit={ this.handleSubmit.bind(this) }>

                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <input className="text" type="text" name="name" id="name" required value={ this.state.name } onChange={ this.handleChange.bind(this) } />
                    </div>
                    <div className="field field-large">
                        <label htmlFor="description">Description</label>
                        <textarea name="description" id="description" onChange={ this.handleChange.bind(this) } value={ this.state.description } />
                    </div>
                    <div className="field">
                        <label htmlFor="location">Location</label>
                        <input className="text" type="text" name="location" id="location" required value={ this.state.location } onChange={ this.handleChange.bind(this) } />
                    </div>

                    <div className="field">
                        <input id="pagesubmit" type="submit" value="Create" />
                    </div>
                </form>

            </div>
        );
    }
}

export default VendorAdd;
