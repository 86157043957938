import React, { Component } from 'react';

class GreenGate extends Component {

    componentWillMount() {
        this.props.history.push( '/vendors/greengatefarms' );
    }

    render() {
        return (
            <div className="page greengate"></div>
        );
    }
}

export default GreenGate;
