import React, { Component } from 'react';

class CategoryShow extends Component {

    render() {
        return (
            <div className="welcome-hero">
                <h1 className="account-head">CategoryShow</h1>
            </div>
        );
    }
}

export default CategoryShow;
